import React from 'react';
import classnames from 'classnames';

function Row({ className, children }, ref) {
  return (
    <div className={classnames('flex flex-wrap -mx-4', className)} ref={ref}>
      {children}
    </div>
  );
}

export default React.forwardRef(Row);
