import React from 'react';
import { StyledBody, StyledCheckbox, StyledInput, StyledLabel } from './RadioOption.styled';

export default function RadioOption({
  name,
  value,
  checked,
  disabled,
  testid,
  onChange,
  children,
}) {
  const id = `${name}-${value}`;

  return (
    <StyledLabel htmlFor={id} data-testid={testid || id}>
      <StyledInput
        type="radio"
        name={name}
        value={value}
        id={id}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        role="radio"
      />
      <StyledBody>
        <StyledCheckbox />
        <span className="w-full">{children}</span>
      </StyledBody>
    </StyledLabel>
  );
}
