import React from 'react';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';

export default function useSendLinkForm(defaultValues) {
  const { t } = useTranslation();

  const schema = React.useMemo(() => {
    return z
      .object({
        method: z.string().min(1, { message: t('common.requiredFieldSendLinkMethod') }),
        email: z
          .string()
          .email({ message: t('common.invalidFormatEmail') })
          .optional()
          .or(z.literal('')),
        phone: z
          .string()
          .min(10, { message: t('common.requiredFieldPhone') })
          .optional()
          .or(z.literal('')),
      })
      .refine((data) => data.method !== 'SMS' || data.phone, {
        message: t('common.requiredFieldPhone'),
        path: ['phone'],
      })
      .refine((data) => data.method !== 'EMAIL' || data.email, {
        message: t('common.requiredFieldEmail'),
        path: ['email'],
      });
  }, [t]);

  return useForm({
    resolver: zodResolver(schema),
    defaultValues,
    shouldUnregister: true,
  });
}
