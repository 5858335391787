import React from 'react';
import { useTranslation } from 'react-i18next';
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import Page from 'Core/components/Page';
import { Column, Row, Modal } from 'Core/components/Layout';
import { PrimaryButton } from 'Core/components/Button';
import PaymentMethod from 'Modules/deposit/components/PaymentMethod';
import Loader from 'Core/components/Loader';
import useCreatePaymentMethod from 'Core/hooks/queries/stripe/useCreatePaymentMethod';
import AddCardForm from 'Modules/deposit/components/AddCardForm';
import { useGetSavedCards } from 'Core/hooks/platform/useGetSavedCards';
import usePostAddCard from 'Core/hooks/platform/usePostAddCard';
import { useGetPaymentInfo } from 'Core/hooks/platform/useGetPaymentInfo';
import usePutUpdateCard from 'Core/hooks/platform/usePutUpdateCard';

export const CardPreferenceCenter = () => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const stripeElements = useElements();
  const { data: paymentInfo, isLoading: isPaymentInfoLoading } = useGetPaymentInfo();
  const { data: savedCards, isSuccess: isSavedCardsLoaded } = useGetSavedCards();
  const { mutateAsync: createPaymentMethod, isLoading: isLoadingCreatePaymentMethod } =
    useCreatePaymentMethod();
  const { mutateAsync: addCard, isLoading: isLoadingAddCard } = usePostAddCard();
  const { mutateAsync: updateCard } = usePutUpdateCard();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [cardError, setCardError] = React.useState('');

  const isLoading = isLoadingCreatePaymentMethod || isLoadingAddCard;
  const visibilityState = isLoading ? `hidden` : `block`;

  const showAddCardForm = () => {
    setCardError('');
    setIsOpen(!modalIsOpen);
  };

  const onSubmit = async ({ firstName, lastName, postalCode, preferred }) => {
    let paymentMethod;

    try {
      paymentMethod = await createPaymentMethod({
        stripe,
        stripeCardElement: stripeElements.getElement(CardNumberElement),
        name: firstName + ' ' + lastName,
        postalCode,
      });
    } catch (err) {
      setCardError(t('status.error.failedToSaveCardOnStripe'));
      return;
    }

    try {
      await addCard({
        customerId: paymentInfo.customer_id,
        externalReferenceId: paymentInfo.external_reference_id,
        paymentMethodId: paymentMethod.id,
        preferred,
        brand: paymentMethod.card.brand,
        last4: paymentMethod.card.last4,
      });
      toast.success(t('status.success.cardAdded'));
      setIsOpen(false);
    } catch (err) {
      setCardError(t('status.error.failedToAddCard'));
    }
  };

  const handleUpdateCard = async ({ paymentMethodId, preferred }) => {
    try {
      await updateCard({
        externalReferenceId: paymentInfo.external_reference_id,
        paymentMethodId,
        preferred,
      });
      toast.success(t('status.success.cardUpdated'));
    } catch (err) {
      toast.error(t('status.error.failedToUpdateCard'));
    }
  };

  if (!isSavedCardsLoaded || isPaymentInfoLoading) {
    return <Loader type="overlay" message={t('status.loading.cardPreferenceCenter')} />;
  }

  return (
    <>
      <Page title={t('common.manageCards')} withStepProgress={false}>
        <Row>
          <Column className="md:flex-col-9 flex items-center">
            <h3 className="mb-0">{t('common.savedCards')}</h3>
          </Column>
          <Column className="md:flex-col-3 flex  justify-end">
            <PrimaryButton data-testid="add-card" onClick={showAddCardForm}>
              {t('common.addCard')}
            </PrimaryButton>
          </Column>
        </Row>

        {savedCards.map((paymentMethod) => {
          return (
            <PaymentMethod
              key={paymentMethod.paymentMethodId}
              paymentMethod={paymentMethod}
              onMarkPreferred={handleUpdateCard}
            />
          );
        })}

        {!savedCards.length && (
          <div className="bg-white shadow rounded mb-4 p-3 items-center text-center">
            <p>{t('common.cardPreferenceCenter.getStarted')}</p>
            <PrimaryButton data-testid="add-card" size="small" onClick={showAddCardForm}>
              {t('common.addCard')}
            </PrimaryButton>
          </div>
        )}
      </Page>

      <Modal className="w-10/12" isOpen={modalIsOpen}>
        <AddCardForm
          onSubmit={onSubmit}
          className={visibilityState}
          onCancel={() => setIsOpen(false)}
          cardError={cardError}
        />
        {isLoading && (
          <Loader
            data-testid="add-card-loader"
            className="p-8"
            message={t('status.loading.savingCard')}
            type="inline"
          />
        )}
      </Modal>
    </>
  );
};
