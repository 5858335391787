import React from 'react';
import { Deposit } from 'Modules/cardPayment/routes/Deposit';
import { CardPreferenceCenter } from 'Modules/cardPayment/routes/CardPreferenceCenter';
import { Payment } from 'Modules/cardPayment/routes/Payment';

export const depositPath = '/card-payment/deposit';
export const cardPreferenceCenterPath = '/card-payment/cards';
export const paymentPath = '/card-payment/payment';

export const routes = [
  {
    path: depositPath,
    element: <Deposit />,
  },
  {
    path: cardPreferenceCenterPath,
    element: <CardPreferenceCenter />,
  },
  {
    path: paymentPath,
    element: <Payment paymentType="payment" />,
  },
];
