import React from 'react';
import classnames from 'classnames';

const styles = {
  warning: {
    header: 'bg-yellow-300',
    paragraph: 'bg-yellow-100',
  },
  message: {
    header: 'bg-gray-300',
    paragraph: 'bg-gray-100',
  },
};

const InfoBox = ({ showBorder = false, title, children, type = 'warning' }) => {
  return (
    <div className="my-2">
      {title && (
        <h5
          className={classnames(
            'p-2 -mb-1 text-gray-800 text-sm font-bold rounded-t',
            styles[type].header
          )}
        >
          {title}
        </h5>
      )}

      {children && (
        <p
          className={classnames('p-2 bg-yellow-100 text-sm rounded-b', styles[type].paragraph, {
            'border-l-4 border-l-amber-300 pl-4': showBorder,
          })}
        >
          {children}
        </p>
      )}
    </div>
  );
};

export default InfoBox;
