import React from 'react';
import { useGetDeposit } from 'Core/hooks/queries/deposit/useGetDeposit';
import contstants from 'Core/config/constants';
import { useNavigate } from 'react-location';
import { depositSuccessPath } from 'Modules/deposit/routes';

export default function DepositData({ children }) {
  const { isLoading, isError, data: deposit, error } = useGetDeposit();
  const navigate = useNavigate();
  const depositStatus = deposit?.status;

  React.useEffect(() => {
    if (depositStatus === contstants.depositStatus.SUCCEEDED) {
      return navigate({ to: depositSuccessPath, search: true });
    }
  }, [depositStatus, navigate]);

  // ugly hack because of API's status code / error handling
  if (isLoading || (isError && error)) {
    return null;
  }

  return children;
}
