import React from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { isValid, parseISO } from 'date-fns';
import { useForm } from 'react-hook-form';
import { relationTypes } from 'Modules/financing/config/relationTypes';
export const getSocialSecurityNumberRegex = () => /^\d{3}-?\d{2}-?\d{4}$/;

export const validateISODateFormat = (val) => {
  return val ? isValid(parseISO(val)) : true;
};

const relationValues = relationTypes.map((item) => item.value);
const useCreateApplicationForm = () => {
  const { t } = useTranslation();

  const schema = React.useMemo(() => {
    return z.object({
      // salutation: z.string().min(1,{ message: t('common.requiredSalutation') }),
      firstName: z.string().min(1, { message: t('common.requiredFieldFirstName') }),
      lastName: z.string().min(1, { message: t('common.requiredFieldLastName') }),
      address: z.string().min(1, { message: t('common.requiredFieldStreet') }),
      city: z.string().min(1, { message: t('common.requiredFieldCity') }),
      state: z.string().min(1, { message: t('common.requiredFieldState') }),
      zip: z.string().min(1, { message: t('common.requiredFieldPostalCode') }),
      relationship: z.enum(relationValues, {
        errorMap: () => {
          return { message: 'relationship is required' };
        },
      }),
      // country: z.string().min(1,{ message: t('common.requiredFieldCountry') }),
      email: z
        .string()
        .min(1, { message: t('common.requiredFieldEmail') })
        .email({ message: t('common.invalidFormatEmail') })
        .refine((val) => !val.endsWith('@hear.com'), {
          // Custom domain validation
          message: t('common.invalidEmailDomain'), // Custom error message
        }),
      socialSecurityNumber: z
        .string()
        .min(1, { message: t('common.requiredFieldSSN') })
        .refine(
          (val) => {
            const regex = getSocialSecurityNumberRegex();
            return regex.test(val);
          },
          { message: t('common.invalidFormatSSN') }
        ),
      phone: z.string().min(1, { message: t('common.requiredFieldPhone') }),
      dateOfBirth: z
        .any()
        .refine(validateISODateFormat, { message: t('common.invalidDateFormat') }),
      annualIncome: z
        .string()
        .transform(parseInt)
        .refine((val) => val >= 1, { message: t('common.requiredFieldAnnualIncome') }),
      terms: z.boolean().refine((val) => val, { message: t('common.requiredFieldTerms') }),
    });
  }, [t]);

  return useForm({
    resolver: zodResolver(schema),
  });
};

export default useCreateApplicationForm;
