import { getAppConfig } from 'Core/config/appConfig';

export const features = {
  depositCheckout: 'depositCheckout',
  creditDebitCheckout: 'creditDebitCheckout',
  financingCheckout: 'financingCheckout',
  mixedPaymentCheckout: 'mixedPaymentCheckout',
  createAllegroApp: 'createAllegroApp',
  sendPaymentLink: 'sendPaymentLink',
  sendContractLink: 'sendContractLink',
  createPaymentRequest: 'createPaymentRequest',
  paymentRequestCardCheckout: 'paymentRequestCardCheckout',
  paymentRequestSepaCheckout: 'paymentRequestSepaCheckout',
  cardPreferenceAddCard: 'cardPreferenceAddCard',
  directSales: 'directSales',
  platformAddCard: 'platformAddCard',
  platformDeposit: 'platformDeposit',
  platformPayment: 'platformPayment',
  financingSignContract: 'financingSignContract',
};

export const actions = {
  signContractButtonClicked: 'sign_contract_button_clicked',
  financingSuccessScreenDisplayed: 'financing_success_screen_displayed',
  createApplicationFormError: 'create_application_form_error',
  createApplicationTimeout: 'create_application_timeout',
};

export const postLogMessage = ({ feature, ...payload }) => {
  try {
    const { currency, locale } = getAppConfig();
    newrelic.addPageAction(feature, {
      env: process.env.NODE_ENV,
      locale,
      currency,
      amount: 0,
      delta: performance.now(),
      ...payload,
    });
    // eslint-disable-next-line no-empty
  } catch (e) {}
};
