import React from 'react';
import PropTypes from 'prop-types';
import { StyledIndicator } from 'Modules/creditDebit/components/Checklist/Checklist.styled';

const IndicatorWarn = ({ color }) => {
  return (
    <StyledIndicator color={color}>
      <div className="inline-block w-7 h-7 relative [top:5px] [left:-3px]">
        <div className="bg-yellow-500 absolute [top:0] [left:14px] [width:2px] [height:10px]" />
        <div className="bg-yellow-500 absolute [top:12px] [left:14px] [width:2px] [height:2px]" />
      </div>
    </StyledIndicator>
  );
};

IndicatorWarn.propTypes = {
  color: PropTypes.oneOf(['gray', 'green', 'orange', 'red']),
};

export default IndicatorWarn;
