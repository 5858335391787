import create from 'zustand';

export default create((set) => {
  return {
    selectedOption: '',
    paymentStarted: false,
    stripePromise: null,
    setPaymentState: (payment) => set(payment),
    updateSelectedPaymentOption: (selectedOption) => set({ selectedOption }),
  };
});

export const stripePromiseSelector = (state) => state.stripePromise;
export const setPaymentStateSelector = (state) => state.setPaymentState;
export const selectedOptionSelector = (state) => state.selectedOption;
export const paymentOptionSelector = ({ selectedOption, updateSelectedPaymentOption }) => ({
  selectedPaymentOption: selectedOption,
  updateSelectedPaymentOption,
});
