import React from 'react';
import { Column, Row } from 'Core/components/Layout';
import Dropdown from 'Core/components/Dropdown/Dropdown';
import Icon from 'Core/components/Icon/Icon';

import { formatCurrency } from 'Core/utils/numberFormat';
import { generateURL } from 'Core/utils/utils';

import { useGetTransactionHistory } from 'Core/hooks/platform/useGetTransactionHistory';

import cacheKeys from 'Core/hooks/queries/cacheKeys';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-location';
import { TextButton } from 'Core/components/Button';
import { useTranslation } from 'react-i18next';

export default function FinancingSummary({ routes, watch, application, setRefetchInterval }) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [isCollectPaymentLoading, setIsCollectPaymentLoading] = React.useState(false);
  const { data: transactions } = useGetTransactionHistory();

  const [receiptList, setReceiptList] = React.useState([]);

  const {
    next_action,
    payload: { already_paid_amount = 0 },
  } = application;
  const priceInfo = application?.payload?.offer_term?.price;

  const { financed, monthly_payment = '' } = priceInfo || {};
  const availableCredit = watch('downPayment') - already_paid_amount;
  const handleUnload = (e) => {
    const message = '';

    (e || window.event).returnValue = message;
    return message;
  };
  React.useEffect(() => {
    if (transactions && transactions.length > 0) {
      setReceiptList(
        transactions.map((item, idx, arr) => {
          const receiptNum = arr.length === 1 ? '' : idx + 1;
          return { label: 'Receipt ' + receiptNum, key: item?.payment_id, url: item?.receipt?.url };
        })
      );
    }
  }, [transactions]);

  React.useEffect(() => {
    if (next_action === 'SIGN_CONTRACT') {
      setIsCollectPaymentLoading(false);
    } else if (next_action === 'DONE' || next_action === 'PENDING_DOWN_PAYMENT') {
      navigate({ to: routes.financing, search: true });
    }
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, [next_action, navigate, routes.financing]);

  const onCollectPayment = () => {
    const url = generateURL({ url: routes.mixed });
    setIsCollectPaymentLoading(true);
    setRefetchInterval(5000);
    queryClient.invalidateQueries(cacheKeys.financingNextStep);

    setTimeout(() => setIsCollectPaymentLoading(false), 5 * 60 * 1000);
    window.open(url, '_blank')?.focus();
  };

  return (
    <>
      <h3 className="text-center mb-4">{t('common.summary')}</h3>
      <Row className="mb-1 mx-0">
        <Column className="!flex-none lg:!flex-col-3 px-2 "></Column>
        <Column
          className="!flex-col-7 lg:!flex-col-4 px-2 text-left"
          data-testid="label-funding-by-allegro"
        >
          {t('financing.fundingByAllegro')}
        </Column>
        <Column className="!flex-col-2 text-right px-2" data-testid="value-funding-by-allegro">
          {isNaN(financed)
            ? '-'
            : formatCurrency(financed % 1 > 0 ? financed.toFixed(2) : financed)}
        </Column>
      </Row>

      <Row className="mb-1 mx-0">
        <Column className="!flex-none lg:!flex-col-3 px-2"></Column>
        <Column
          className="!flex-col-7 lg:!flex-col-4 px-2 text-left"
          data-testid="label-monthly-payment"
        >
          {t('financing.monthlyPayment')}
        </Column>
        <Column className="!flex-col-2 text-right px-2 " data-testid="value-monthly-payment">
          {formatCurrency(monthly_payment, { withDecimals: true })}
        </Column>
      </Row>

      <Row className="mb-1 mx-0">
        <Column className="!flex-none lg:!flex-col-3 px-2 "></Column>
        <Column
          className="!flex-col-7 lg:!flex-col-4 px-2 text-left"
          data-testid="label-available-credit"
        >
          {t('common.availableCredit')}
        </Column>
        <Column className="!flex-col-2 text-right px-2 " data-testid="value-available-credit">
          {formatCurrency(already_paid_amount)}
        </Column>
      </Row>
      {next_action === 'SIGN_CONTRACT' && availableCredit > 0 && (
        <Row className="items-center mx-0 flex-nowrap">
          <Column className="!flex-none lg:!flex-col-3 px-2 "></Column>
          <Column
            className="!flex-col-7 lg:!flex-col-4 px-2 text-left"
            data-testid="label-additional-collected-amount"
          >
            {t('financing.additionalCollectedAmount')}
          </Column>
          <Column
            className="!flex-col-2 flex justify-end items-center px-2"
            data-testid="value-additional-collected-amount"
          >
            <Icon type="success" />
            {formatCurrency(availableCredit)}
          </Column>
          <Column className="!flex-col-3 text-left">
            <>
              {transactions &&
                transactions.length === 1 &&
                receiptList.map(({ url, key }) => {
                  return (
                    <a
                      className="bg-transparent text-primary hover:bg-gray-100 p-2 rounded text-sm"
                      target="_blank"
                      rel="noreferrer"
                      data-testid="receipt-item"
                      href={url}
                      key={key}
                    >
                      {t('common.viewReceipt')}
                    </a>
                  );
                })}

              {receiptList && receiptList.length > 1 && (
                <Dropdown label={t('common.viewReceipt')} list={receiptList}></Dropdown>
              )}
            </>
          </Column>
        </Row>
      )}
      {next_action !== 'SIGN_CONTRACT' && availableCredit > 0 && (
        <Row className="items-center mx-0  flex-nowrap">
          <Column className="!flex-none lg:!flex-col-3 px-2 "></Column>
          <Column
            className="!flex-col-7 lg:!flex-col-4 px-2 text-left text-amber-700"
            data-testid="label-amount-to-collect"
          >
            {t('financing.amountToBeCollected')}
          </Column>
          <Column className="!flex-col-2 text-right flex justify-end items-center px-2">
            <span className="!hidden lg:!block">
              <Icon type="warning" className="mr-3" />
            </span>
            <span className="text-amber-700" data-testid="value-amount-to-collect">
              {formatCurrency(availableCredit)}
            </span>
          </Column>
          <Column className="!flex-col-3 text-left">
            <TextButton
              disabled={isCollectPaymentLoading}
              onClick={onCollectPayment}
              data-testid="btn-collect-payment"
            >
              <div className="flex">
                {isCollectPaymentLoading && (
                  <span className="inline-block mr-3">
                    <Icon type="spinner"></Icon>
                  </span>
                )}
                <span className="!hidden lg:!block">{t('financing.collectPayment')}</span>
                <span className="!block lg:!hidden">{'Collect'}</span>
              </div>
            </TextButton>
          </Column>
        </Row>
      )}

      <Row className="mb-4 mx-0">
        <Column className="!flex-none lg:!flex-col-3 px-2 "></Column>
        <Column
          className="!flex-col-7 lg:!flex-col-4 px-2 text-left"
          data-testid="label-total-down-payment"
        >
          {t('financing.totalDownPayment')}
        </Column>
        <Column className="!flex-col-2 text-right px-2" data-testid="value-total-down-payment">
          {formatCurrency(watch('downPayment') || 0)}
        </Column>
      </Row>
    </>
  );
}
