import React from 'react';
import { useTranslation } from 'react-i18next';
import SavedCard from 'Core/components/SavedCard';
import NewCard from 'Core/components/NewCard';
import { AlertError } from 'Core/components/Alert';
import usePaymentStore, { stripePromiseSelector } from 'Core/stores/payment';
import { Elements } from '@stripe/react-stripe-js';
import { Column, Row } from 'Core/components/Layout';
import { StyledFormElement } from 'Core/components/FormElements/formElements.styled';
import { getCard } from 'Core/components/CreditCardLogos';
import { DestructiveButton, OutlineButton } from 'Core/components/Button/Button';
import { findByPropertyValue, getFormPrefix } from 'Core/utils/utils';
import { getAppConfig } from 'Core/config/appConfig';

export default function CardSelector({
  defaultValues = {},
  cardCount = 1,
  index = 0,
  form,
  paymentMethods = [],
  onRemove,
  children,
  namePrefix = '',
  cardError,
  update,
}) {
  const { t } = useTranslation();
  const prefix = getFormPrefix(namePrefix, index);
  const selectedPaymentMethodId = form.watch(`${prefix}paymentMethodId`);
  const selectedPaymentMethod = findByPropertyValue(
    paymentMethods,
    'paymentMethodId',
    selectedPaymentMethodId
  );

  const setCardToNewCard = (e) => {
    if (form.getValues().cards) {
      const cardIndex = e.currentTarget.dataset.index;
      form.setValue(`cards[${cardIndex}]paymentMethodId`, '');
      const formVals = form.getValues();
      formVals.cards[cardIndex].paymentMethodId = '';
      update(cardIndex, formVals.cards[cardIndex]);
    } else {
      form.setValue(`paymentMethodId`, '');
    }
  };

  return (
    <Elements
      stripe={usePaymentStore(stripePromiseSelector)}
      options={{ locale: getAppConfig().language }}
    >
      {cardError && <AlertError>{cardError}</AlertError>}
      <Row>
        <Column noFlex className="flex-shrink font-semibold self-center">
          {t('common.selectExistingCard')}
        </Column>
        <Column noFlex className="flex-grow">
          <StyledFormElement>
            <select
              defaultValue={defaultValues.paymentMethodId}
              {...form.register(`${prefix}paymentMethodId`)}
              data-testid={`card-select-${index}`}
            >
              {paymentMethods.map(({ paymentMethodId, preferred, card }, i) => {
                const { name } = getCard(card.brand);
                return (
                  <option key={paymentMethodId + i} value={paymentMethodId}>
                    {name} **** {card.last4}
                    {preferred ? ` (${t('common.preferred')})` : ''}
                  </option>
                );
              })}
              <option value="">{t('common.newCard')}</option>
            </select>
          </StyledFormElement>
        </Column>
        {cardCount !== 1 && (
          <Column noFlex className="flex-grow-0">
            <DestructiveButton
              data-testid="card-remove-btn"
              onClick={onRemove}
              disabled={cardCount === 1}
            >
              {t('common.removeCard')}
            </DestructiveButton>
          </Column>
        )}
      </Row>
      <Row>
        {selectedPaymentMethodId && (
          <Row className="flex flex-wrap justify-between w-full mt-4 mb-4 items-end">
            <Column className="!lg-0 sm:flex-col-6 !pl-7 !mb-0">
              <SavedCard
                index={index}
                form={form}
                paymentMethod={selectedPaymentMethod}
                paymentMethodId={selectedPaymentMethodId}
                namePrefix={namePrefix}
              />
            </Column>
            <Column className="!mb-0 !px-0 sm:flex-col-6">{children}</Column>
          </Row>
        )}
      </Row>

      <div className="relative">
        <hr />
        <div className="absolute top-[-1.25rem] left-[50%] -translate-x-2/4 bg-white  px-7 py-2">
          OR
        </div>
      </div>

      {selectedPaymentMethodId ? (
        <div className="flex pt-5 m-auto w-1/3">
          <OutlineButton
            block
            onClick={setCardToNewCard}
            data-testid="card-select-new"
            data-index={index}
          >
            {t('common.enterNewCardDetails')}
          </OutlineButton>
        </div>
      ) : (
        <div>
          <NewCard
            defaultValues={defaultValues}
            index={index}
            form={form}
            namePrefix={namePrefix}
          />
          {children}
        </div>
      )}
    </Elements>
  );
}
