import React from 'react';
import Checkout from 'Modules/deposit/routes/Checkout';
import Success from 'Modules/deposit/routes/Success';
import CardPreferenceCenter from 'Modules/deposit/routes/CardPreferenceCenter';
import DepositData from 'Modules/deposit/components/DepositData';

export const depositCheckoutPath = '/office/deposit';
export const depositSuccessPath = '/office/deposit/success';
export const cardPreferenceCenterPath = '/office/cards';

export const routes = [
  {
    path: depositSuccessPath,
    element: (
      <DepositData>
        <Success />
      </DepositData>
    ),
  },
  {
    path: depositCheckoutPath,
    element: (
      <DepositData>
        <Checkout />
      </DepositData>
    ),
  },
  {
    path: cardPreferenceCenterPath,
    element: <CardPreferenceCenter />,
  },
];
