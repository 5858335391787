import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { Column, Row } from 'Core/components/Layout';
import InputField from 'Core/components/FormElements/InputField';
import { StyledFormElement } from 'Core/components/FormElements/formElements.styled';
import stripeCardElementConfig from 'Core/config/stripeCardElementConfig';
import Error from 'Core/components/FormElements/Error';
import QuestionMark from 'Core/components/QuestionMark';
import { AlertError } from 'Core/components/Alert';
import { getFormPrefix } from 'Core/utils/utils';

const NewCard = ({
  index = 0,
  form,
  namePrefix = '',
  children = null,
  cardError = '',
  defaultValues = {},
  shouldShowHeader = false,
}) => {
  const { t } = useTranslation();
  const stripeElements = useElements();
  const stripe = useStripe();
  const {
    register,
    unregister,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = form;

  //Need to construct prefix when used inside a multicardform
  const prefix = getFormPrefix(namePrefix, index);

  //default values need to be manually set for multicardform
  const { firstName, lastName, postalCode } = defaultValues;
  const validationErrors = namePrefix ? errors?.[namePrefix]?.[index] : errors;
  const handleStripeElementChange = (event, name) => {
    const { error: stripeError } = event;
    if (stripeError) {
      setError(name, { type: 'manual', message: t(`stripe.${stripeError.code}`) });
      return;
    }
    setValue(name, '1');
    clearErrors(name);
  };

  React.useEffect(() => {
    //register stripe element on mount
    if (stripe && stripeElements) {
      setValue(`${prefix}stripeElement`, stripeElements.getElement(CardNumberElement));
      setValue(`${prefix}stripe`, stripe);
    }

    //unregister stripe elements on unmount
    return () => unregister([`${prefix}cardNumber`, `${prefix}cardExpiry`, `${prefix}cardCvc`]);
  }, [setValue, unregister, prefix, stripe, stripeElements]);

  return (
    <>
      {shouldShowHeader && (
        <h3 data-testid={`card-information-headline-${index}`}>{t('common.cardInformation')}</h3>
      )}
      {cardError && <AlertError>{cardError}</AlertError>}
      <Row>
        <Column className="md:flex-col-12 font-semibold pb-1">
          {t('common.enterNewCardDetails')}
        </Column>
      </Row>
      <Row>
        <Column className="md:flex-col-6">
          <InputField
            data-testid="firstName"
            defaultValue={firstName}
            register={register}
            label={t('common.cardHolderFirstName')}
            name={`${prefix}firstName`}
            error={validationErrors?.firstName?.message}
          />
        </Column>
        <Column className="md:flex-col-6">
          <InputField
            data-testid="lastName"
            defaultValue={lastName}
            register={register}
            label={t('common.cardHolderLastName')}
            name={`${prefix}lastName`}
            error={validationErrors?.lastName?.message}
          />
        </Column>
      </Row>
      <Row>
        <Column className="md:flex-col-4">
          <StyledFormElement>
            <label htmlFor={`cardNumber${index}`}>{t('common.cardNumber')}</label>
            <CardNumberElement
              id={`cardNumber${index}`}
              options={stripeCardElementConfig}
              onChange={(e) => handleStripeElementChange(e, `${prefix}cardNumber`)}
            />
          </StyledFormElement>
          {validationErrors?.cardNumber?.message ? (
            <Error message={t(validationErrors?.cardNumber?.message)} show={true} />
          ) : null}
        </Column>
        <Column className="md:flex-col-2">
          <StyledFormElement>
            <label htmlFor={`expiryDate${index}`}>{t('common.cardExpiry')}</label>
            <CardExpiryElement
              id={`expiryDate${index}`}
              options={stripeCardElementConfig}
              onChange={(e) => handleStripeElementChange(e, `${prefix}cardExpiry`)}
            />
          </StyledFormElement>
          {validationErrors?.cardExpiry?.message ? (
            <Error message={t(validationErrors?.cardExpiry?.message)} show={true} />
          ) : null}
        </Column>
        <Column className="md:flex-col-3">
          <StyledFormElement>
            <label htmlFor={`cvc${index}`} style={{ whiteSpace: 'nowrap' }}>
              {t('common.cardCode')} <QuestionMark text={t('common.cardCodeInfo')} />
            </label>
            <CardCvcElement
              id={`cvc${index}`}
              data-testid={`cvc`}
              options={stripeCardElementConfig}
              onChange={(e) => handleStripeElementChange(e, `${prefix}cardCvc`)}
            />
          </StyledFormElement>
          {validationErrors?.cardCvc?.message ? (
            <Error message={t(validationErrors?.cardCvc?.message)} show={true} />
          ) : null}
        </Column>
        <Column className="md:flex-col-3">
          <InputField
            data-testid="postalCode"
            defaultValue={postalCode}
            register={register}
            label={t('common.postalCode')}
            name={`${prefix}postalCode`}
            error={validationErrors?.postalCode?.message}
          />
        </Column>
      </Row>
      {children}
    </>
  );
};

export default NewCard;
