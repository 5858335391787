import React from 'react';
import useMultiCardForm from 'Modules/creditDebit/hooks/useMultiCardForm';
import { PrimaryButton, SecondaryOutlineButton } from 'Core/components/Button';
import { useFieldArray } from 'react-hook-form';
import { getDecimals, getPreferredPaymentMethod } from 'Core/utils/utils';
import CardSelector from 'Core/components/CardSelector';
import AmountSplitter from 'Modules/creditDebit/components/AmountSplitter';
import StyledFormGroup from 'Core/components/FormElements/StyledFormGroup';
import DownPayment from 'Core/components/ShoppingCart/DownPayment';
import ShoppingCart from 'Core/components/ShoppingCart';
import { useShowSidebar } from 'Core/hooks/useShowSidebar';
import { Column, Row } from 'Core/components/Layout';
import InputField from 'Core/components/FormElements/InputField';
import { Trans, useTranslation } from 'react-i18next';
import TermsInput from 'Core/components/FormElements/TermsInput';
import { formatCurrency } from 'Core/utils/numberFormat';

const MultiCardForm = ({
  paymentMethods,
  paymentSummary,
  order,
  onCancel,
  onSubmit,
  cardErrors,
}) => {
  const { t } = useTranslation();
  const shouldShowSidebar = useShowSidebar();

  const isMixedPayment = order.optionSelected === 'mixed';
  const { downPayment, totalAmountToPay: totalAmount } = paymentSummary;
  const showDownPayment = isMixedPayment && downPayment > 0;

  //We do this to manage decimals separately in the onSubmit handler
  const totalAmountInteger = Math.floor(totalAmount);

  const { paymentMethodId } = getPreferredPaymentMethod(paymentMethods);
  const defaultCardValues = paymentMethodId
    ? { paymentMethodId, amount: totalAmountInteger.toString() }
    : {
        firstName: '',
        lastName: '',
        postalCode: '',
        cardNumber: '',
        cardExpiry: '',
        cardCvc: '',
        amount: totalAmountInteger.toString(),
      };
  const form = useMultiCardForm(defaultCardValues, { decimals: getDecimals(totalAmount, 6) });
  const { register, handleSubmit, control, setValue } = form;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'cards',
  });

  const remainingToCharge =
    totalAmountInteger -
    form
      .watch('cards')
      .reduce((acc, curr) => acc + (curr.amount ? parseInt(curr.amount, 10) : 0), 0);

  //This is necessary to update the form state when you remove a card.
  //You cannot call 2 actions together and need to wait for a rerender
  React.useEffect(() => {
    if (fields.length === 1) setValue('cards[0].amount', totalAmountInteger.toString());
  }, [fields.length, setValue, totalAmountInteger]);

  //Set remaining to form
  React.useEffect(() => {
    setValue('remaining', remainingToCharge, { shouldValidate: true });
  }, [remainingToCharge, setValue]);

  return (
    <form name="multiCardForm" onSubmit={handleSubmit(onSubmit)}>
      {showDownPayment ? <DownPayment /> : null}
      {fields.map(({ id, ...field }, i) => {
        const cardError = cardErrors?.[i]?.message;
        return (
          <StyledFormGroup key={id} error={cardError}>
            <CardSelector
              cardError={cardError}
              defaultValues={field}
              namePrefix={`cards`}
              cardCount={fields.length}
              index={i}
              form={form}
              paymentMethods={paymentMethods}
              onRemove={() => remove(i)}
            >
              <AmountSplitter
                decimals={form.getValues('decimals')}
                totalAmount={totalAmountInteger}
                form={form}
                index={i}
                cardCount={fields.length}
              />
              <input type="hidden" {...register('decimals')} />
            </CardSelector>
          </StyledFormGroup>
        );
      })}

      {form.formState?.errors?.remaining && (
        <h3 className="text-error mb-4">
          <Trans
            i18nKey={`payment.remainingAmountToCharge`}
            values={{ amount: formatCurrency(remainingToCharge, { withDecimals: false }) }}
          />
        </h3>
      )}

      <div className="p-4">
        <Row className="!bg-gray-300 !pt-4 !rounded !flex-nowrap">
          <Column noFlex className="!flex-grow">
            <b>{t('common.addCardControlLabel')}</b>
          </Column>
          <Column noFlex>
            <PrimaryButton
              data-testid="add-card"
              onClick={() => {
                append({
                  paymentMethodId: '',
                  firstName: '',
                  lastName: '',
                  postalCode: '',
                  cardNumber: '',
                  cardExpiry: '',
                  cardCvc: '',
                  amount: '',
                });
              }}
            >
              {t('common.addCard')}
            </PrimaryButton>
          </Column>
        </Row>
      </div>

      {!shouldShowSidebar && <ShoppingCart withTitle />}
      {showDownPayment && !shouldShowSidebar ? <DownPayment /> : null}

      <Row>
        <Column className="md:!flex-col-6">
          <InputField
            register={register}
            label={t('common.receiptEmail')}
            name={`email`}
            error={form.formState.errors?.email?.message}
          />
        </Column>
      </Row>

      <TermsInput
        name="terms"
        register={register}
        errors={form.formState.errors}
        label={t('common.termsAndConditions')}
      >
        <p className="mb-0">{t('terms.card.charge')}</p>
      </TermsInput>

      <Row className={`!justify-between`}>
        <Column className={`!flex-col-6 md:!flex-col-4 lg:!flex-col-3`}>
          <SecondaryOutlineButton block type="reset" onClick={onCancel} data-testid="cancel">
            {t('common.cancel')}
          </SecondaryOutlineButton>
        </Column>
        <Column className={`!flex-col-6 md:!flex-col-4 lg:!flex-col-3`}>
          <PrimaryButton type="submit" block data-testid="checkout">
            {t('common.checkout')}
          </PrimaryButton>
        </Column>
      </Row>
    </form>
  );
};

export default MultiCardForm;
