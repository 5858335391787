import React from 'react';
import { formatCurrency } from 'Core/utils/numberFormat';
import { Column, Modal, Row } from 'Core/components/Layout';
import { generateURL } from 'Core/utils/utils';
import { Alert } from 'Core/components/Alert';
import { OutlineButton, PrimaryButton } from 'Core/components/Button';

export default function FinancingNotEligible({ already_paid_amount, amount_to_pay, routes }) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const onOpenConfirmationPopup = () => {
    setIsModalOpen(true);
  };
  const onCollectPayment = () => {
    const url = generateURL({ url: routes?.payment });
    window.open(url, '_self');
  };

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Row className="justify-center flex-col items-center w-full">
        <Column className="font-regular text-large w-full">
          <Alert
            showIcon
            type="warningLight"
            title="correction required"
            data-testid="message-not-eligible"
          >
            <h3 className="font-bold">Financing amount too low!</h3>
            <span>
              Your application is approved. However, you have an available credit of&nbsp;
            </span>
            <span className="font-bold">({formatCurrency(already_paid_amount)})</span>
            <span>&nbsp;so the amount to be financed&nbsp;</span>
            <span className="font-bold">
              ({formatCurrency(amount_to_pay - already_paid_amount)})
            </span>
            <span>
              &nbsp;is lower than the minimum eligible amount that can be financed. You can click on
              the button below to complete the remaining amount instead of financing it.
            </span>
          </Alert>
        </Column>
        <Column className="text-center text-gray-400 w-1/3">
          <PrimaryButton data-testid="btn-collect-payment" block onClick={onOpenConfirmationPopup}>
            Collect Payment
          </PrimaryButton>
        </Column>
      </Row>
      <Modal isOpen={isModalOpen}>
        <h3 className="font-bold text-center">
          Are you sure you want to proceed to Payment Collection?
        </h3>
        <div className="p-6 text-center" data-testid="title-autopay-redirect">
          You will leave the financing application and be redirected to the payment collection
          portal
        </div>
        <hr></hr>
        <div className="flex items-end justify-end gap-4">
          <OutlineButton type="button" onClick={onModalClose} data-testid="btn-modal-cancel">
            Cancel
          </OutlineButton>
          <PrimaryButton type="button" onClick={onCollectPayment} data-testid="btn-modal-confirm">
            Confirm
          </PrimaryButton>
        </div>
      </Modal>
    </div>
  );
}
