import React from 'react';
import useMultiCardForm from 'Modules/creditDebit/hooks/useMultiCardForm';
import { PrimaryButton, OutlineButton } from 'Core/components/Button/Button';
import { useFieldArray } from 'react-hook-form';
import { getDecimals, getPreferredPaymentMethod } from 'Core/utils/utils';
import CardSelector from 'Core/components/CardSelector';
import AmountSplitter from 'Modules/creditDebit/components/AmountSplitter';
import StyledFormGroup from 'Core/components/FormElements/StyledFormGroup';
import { Column, Row } from 'Core/components/Layout';
import InputField from 'Core/components/FormElements/InputField';
import { Trans, useTranslation } from 'react-i18next';
import TermsInput from 'Core/components/FormElements/TermsInput';
import { formatCurrency } from 'Core/utils/numberFormat';
import Card from 'Core/components/FormElements/Card';
import { AlertError } from 'Core/components/Alert';

const PaymentCardForm = ({ paymentMethods, onSubmit, cardErrors, totalAmount }) => {
  const { t } = useTranslation();

  //We do this to manage decimals separately in the onSubmit handler
  const totalAmountInteger = Math.floor(totalAmount);

  const { paymentMethodId } = getPreferredPaymentMethod(paymentMethods);
  const defaultCardValues = paymentMethodId
    ? { paymentMethodId, amount: totalAmountInteger.toString() }
    : {
        firstName: '',
        lastName: '',
        postalCode: '',
        cardNumber: '',
        cardExpiry: '',
        cardCvc: '',
        amount: totalAmountInteger.toString(),
      };
  const form = useMultiCardForm(defaultCardValues, { decimals: getDecimals(totalAmount, 6) });
  const { register, handleSubmit, control, setValue } = form;
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'cards',
  });

  const remainingToCharge =
    totalAmountInteger -
    form
      .watch('cards')
      .reduce((acc, curr) => acc + (curr.amount ? parseInt(curr.amount, 10) : 0), 0);

  //This is necessary to update the form state when you remove a card.
  //You cannot call 2 actions together and need to wait for a rerender
  React.useEffect(() => {
    if (fields.length === 1) setValue('cards[0].amount', totalAmountInteger.toString());
  }, [fields.length, setValue, totalAmountInteger]);

  //Set remaining to form
  React.useEffect(() => {
    setValue('remaining', remainingToCharge, { shouldValidate: true });
  }, [remainingToCharge, setValue]);

  return (
    <form name="multiCardForm" onSubmit={handleSubmit(onSubmit)}>
      <Card type="slate">
        <Row className="flex-nowrap content-center">
          <Column className="md:!flex-col-8 mb-0">
            <h3 className="!mb-0">{t('payment.totalAmountLabel')}</h3>
          </Column>
          <Column className="md:!flex-col-4 text-right mb-0 pr-5 font-bold text-xl">
            <Trans
              i18nKey={`{{amount}}`}
              values={{ amount: formatCurrency(totalAmount, { withDecimals: true }) }}
            />
          </Column>
        </Row>
      </Card>
      {fields.map(({ id, ...field }, i) => {
        const cardError = cardErrors?.[i]?.message;
        return (
          <StyledFormGroup key={id} error={cardError}>
            <CardSelector
              update={update}
              cardError={cardError}
              defaultValues={field}
              namePrefix={`cards`}
              cardCount={fields.length}
              index={i}
              form={form}
              paymentMethods={paymentMethods}
              onRemove={() => remove(i)}
            >
              <AmountSplitter
                decimals={form.getValues('decimals')}
                totalAmount={totalAmountInteger}
                form={form}
                index={i}
                cardCount={fields.length}
              />
              <input type="hidden" {...register('decimals')} />
            </CardSelector>
          </StyledFormGroup>
        );
      })}

      {form.formState?.errors?.remaining && (
        <AlertError>
          {
            <Trans
              i18nKey={`payment.remainingAmountToCharge`}
              values={{ amount: formatCurrency(remainingToCharge, { withDecimals: false }) }}
            />
          }
        </AlertError>
      )}

      <Card className="pb-0">
        <Row className="!flex-nowrap items-center">
          <Column noFlex className="!flex-grow">
            <div className="font-semibold">{t('common.splitAmount')}</div>
            <span>{t('common.addCardControlLabel')}</span>
          </Column>
          <Column noFlex className="!mb-3 pl-0 flex-grow">
            <OutlineButton
              block
              data-testid="add-card"
              onClick={() => {
                append({
                  paymentMethodId: '',
                  firstName: '',
                  lastName: '',
                  postalCode: '',
                  cardNumber: '',
                  cardExpiry: '',
                  cardCvc: '',
                  amount: '',
                });
              }}
            >
              {t('common.addCard')}
            </OutlineButton>
          </Column>
        </Row>
      </Card>

      <Card>
        <TermsInput
          name="terms"
          register={register}
          errors={form.formState.errors}
          label={t('common.termsAndConditions')}
        >
          <p className="mb-0">{t('terms.card.charge')}</p>
        </TermsInput>
      </Card>

      <Card className={`flex !justify-end mb-2 !px-0`}>
        <Row className="flex w-full content-center justify-between">
          <Column className="md:!flex-col-6 mb-1">
            <InputField
              register={register}
              label={t('common.receiptEmail')}
              name={`email`}
              error={form.formState.errors?.email?.message}
            />
          </Column>

          <Column className="!flex-col-12 md:!flex-col-4 lg:!flex-col-3 pt-4 mb-1 mr-6">
            <PrimaryButton block={true} type="submit" data-testid="checkout" size="tall">
              {t('common.checkout')}
            </PrimaryButton>
          </Column>
        </Row>
      </Card>
    </form>
  );
};

export default PaymentCardForm;
