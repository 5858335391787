import { useTranslation } from 'react-i18next';
import useErrorStore, { setErrorActionSelector } from 'Core/stores/error';
import { useNavigate } from 'react-location';
import { useQuery } from 'react-query';
import cacheKeys from 'Core/hooks/queries/cacheKeys';
import { get } from 'Core/utils/platformApi';
import { errorPath } from 'Core/routes';
import { useGetPaymentInfo } from 'Core/hooks/platform/useGetPaymentInfo';

export function useGetTransactionHistory() {
  const { t } = useTranslation();
  const setError = useErrorStore(setErrorActionSelector);
  const navigate = useNavigate();
  const { data: paymentInfo } = useGetPaymentInfo();

  return useQuery(
    cacheKeys.transactionHistory,
    () =>
      get('/transaction/history', {
        headers: { external_reference_id: paymentInfo.external_reference_id },
      }),
    {
      enabled: Boolean(paymentInfo),
      select(data) {
        return data.filter(({ status }) => ['AUTHORIZED', 'PAID'].includes(status));
      },
      onError(e) {
        if (e) {
          setError(t('status.error.getTransactionHistoryFailed') + ` - (${e.message})`);
          navigate({ to: errorPath, search: true });
        }
      },
    }
  );
}
