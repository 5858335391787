import React from 'react';
import { useTranslation } from 'react-i18next';
import { Column, Container, Row } from 'Core/components/Layout';
import { getAppConfig } from 'Core/config/appConfig';
import logoStripe from 'Images/powered_by_stripe.svg';
import logoHearCom from 'Images/logo_hearcom_payment-portal.svg';
import logoAudibene from 'Images/logo-de.svg';

const logos = {
  us: logoHearCom,
  ca: logoHearCom,
  de: logoAudibene,
  nl: logoAudibene,
  fr: logoAudibene,
};
const stripeLink = {
  us: 'https://stripe.com/',
  ca: 'https://stripe.com/en-ca',
  de: 'https://stripe.com/de',
  nl: 'https://stripe.com/en-nl',
  fr: 'https://stripe.com/fr',
};

const HeaderBanner = () => {
  const { t } = useTranslation();
  const { country } = getAppConfig();

  return (
    <Container className="!mb-0">
      <Row className="!pt-6">
        <Column className="md:!flex-col-4">
          <img src={logos[country]} alt="Payment Portal Logo" style={{ height: '35px' }} />
        </Column>
        <Column className="md:!flex-col-4 !text-center">
          <h1>{t('common.website-claim')}</h1>
        </Column>
        <Column className="md:!flex-col-4 !pr-4 !flex !justify-end">
          <a href={stripeLink[country]} target="_blank" rel="noreferrer">
            <img src={logoStripe} alt="powered by stripe" style={{ height: '28px' }} />
          </a>
        </Column>
      </Row>
    </Container>
  );
};

export default HeaderBanner;
