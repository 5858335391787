import React from 'react';
import classnames from 'classnames';

const styles = {
  base: `
    inline-block mr-2
    border-4 border-solid border-current rounded-full border-r-transparent
    align-text-bottom
    animate-spin
  `,
  small: 'border-2 mr-2',
};

export default function Spinner({ className = 'w-4 h-4', small = false, children }) {
  return (
    <span className={classnames(styles.base, { [styles.small]: small }, className)}>
      {children}
    </span>
  );
}
