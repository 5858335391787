import React from 'react';

import FinancingRedirect from 'Modules/financing/routes/FinancingRedirect';
import FinancingSuccess from 'Modules/financing/routes/FinancingSuccess';

import FinancingDeclined from 'Modules/financing/routes/FinancingDeclined';
import Financing from 'Modules/financing/routes/Financing';
import CreateApplication from './routes/CreateApplication';

import { Payment } from 'Modules/cardPayment/routes/Payment';
import MixedSuccess from 'Modules/financing/routes/MixedSuccess';
import MixedError from 'Modules/financing/routes/MixedError';

export const createFinancingPath = '/financing-nextgen/financing';
export const financingPath = '/financing-nextgen/office/finance';
export const financingSuccessPath = '/financing-nextgen/funded';
export const financingErrorPath = '/financing-nextgen/declined';
export const createSecondaryFinancingPath = '/financing-nextgen/financing/secondary';

export const mixedAvailableCreditPath = '/financing-nextgen/mixed/available-credit';
export const mixedDownPaymentCheckoutPath = '/financing-nextgen/mixed/down-payment/checkout';
export const mixedFinancingPath = '/financing-nextgen/mixed/financing';
export const mixedSuccessPath = '/financing-nextgen/mixed/success';
export const mixedErrorPath = '/financing-nextgen/mixed/error';

export const routes = [
  { path: createFinancingPath, element: <FinancingRedirect /> },
  { path: createSecondaryFinancingPath, element: <CreateApplication type="secondary" /> },
  {
    path: financingPath,
    element: (
      <Financing nextRoutes={{ success: financingSuccessPath, error: financingErrorPath }} />
    ),
  },
  {
    path: financingSuccessPath,
    element: <FinancingSuccess />,
  },
  {
    path: financingErrorPath,
    element: <FinancingDeclined />,
  },

  {
    path: mixedDownPaymentCheckoutPath,
    element: (
      <Payment
        paymentType="downPayment"
        nextRoutes={{
          success: mixedFinancingPath,
          error: mixedErrorPath,
        }}
      />
    ),
  },
  {
    path: mixedFinancingPath,
    element: <Financing nextRoutes={{ success: mixedSuccessPath, error: mixedErrorPath }} />,
  },
  {
    path: mixedSuccessPath,
    element: <MixedSuccess />,
  },
  {
    path: mixedErrorPath,
    element: <MixedError />,
  },
];
