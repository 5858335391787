import React from 'react';
import Error from 'Core/components/FormElements/Error';
import { useTranslation } from 'react-i18next';

const Checkbox = ({ register = () => {}, errors = {}, name, label, className }) => {
  const { t } = useTranslation();
  const error = errors[name]?.message;

  return (
    <div className={`${className} mb-4`}>
      <label className="flex items-center">
        <input
          className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300  dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 mr-2"
          id={name}
          type="checkbox"
          data-testid={name}
          name={name}
          {...register(name)}
        />
        {label}
      </label>
      <Error message={t('requiredAcceptTermsAndConditions')} show={Boolean(error)} />
    </div>
  );
};

export default Checkbox;
