export default [
  'Salesforce.Opportunity.ab - Sales Consulting Console',
  'Salesforce.Opportunity.ab - Sales Consulting Manager Console',
  'Salesforce.Opportunity.ab - Invoicing',
  'Salesforce.Opportunity.ab - Quality Manager',
  'Salesforce.Opportunity.ab - Partner Manager',
  'Salesforce.Opportunity.ab - Business Development (without admin)',
  'Salesforce.Opportunity.ab - Quality Management',
  'Salesforce.Lead.ab - Sales Consulting Console',
  'Salesforce.Lead.ab - Sales Consulting Manager Console',
];
