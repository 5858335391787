import React from 'react';
import classnames from 'classnames';

export default function Card({ children, error, cardPadding, type, ...props }) {
  const paddingX = 'px-8';
  let paddingTop = 'py-5';
  switch (cardPadding) {
    case 'sm':
      paddingTop = 'py-2';
      break;
    case 'lg':
      paddingTop = 'py-6';
      break;
    case 'xl':
      paddingTop = 'py-8';
      break;
    case 'md':
    default:
      paddingTop = 'py-4';
      break;
  }
  return (
    <div
      {...props}
      className={classnames(
        props.className,
        `border border-solid border-gray-200 shadow-sm rounded-lg mb-4 ${paddingX} ${paddingTop} transition-colors bg-white`,
        { 'border-error': !!error, 'bg-slate-300 border-slate-400': type === 'slate' }
      )}
    >
      {children}
    </div>
  );
}
