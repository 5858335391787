import { Modal } from 'Core/components/Layout';
import React from 'react';
import SendContractLinkForm from 'Modules/financing/FinalizeOffer/SendLink/components/SendContractLinkForm';
import { useTranslation } from 'react-i18next';

export default function SendLinkModal({ isModalOpen, setIsModalOpen, onLinkSent }) {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isModalOpen} className="w-[50em]">
      <h3 className="font-bold text-center">{t('financing.nextgen.sendLink.modal.title')}</h3>
      <SendContractLinkForm
        setIsModalOpen={setIsModalOpen}
        onLinkSent={onLinkSent}
      ></SendContractLinkForm>
    </Modal>
  );
}
