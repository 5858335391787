import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import useErrorStore, { setErrorActionSelector } from 'Core/stores/error';
import cacheKeys from 'Core/hooks/queries/cacheKeys';
import { get } from 'Core/utils/api';
import { useNavigate } from 'react-location';
import { errorPath } from 'Core/routes';

export default function useOrder({ enabled = true } = {}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const setError = useErrorStore(setErrorActionSelector);

  return useQuery(cacheKeys.order, () => get(`/api/orders`), {
    enabled,
    onError(e) {
      const message = ` - ${e?.response?.data?.message}` || '';
      setError(t('status.error.orderServiceFailed') + message);
      navigate({ to: errorPath });
    },
  });
}
