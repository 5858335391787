const paymentOptions = {
  CREDIT_DEBIT: 'credit-debit',
  FINANCING: 'financing',
  MIXED: 'mixed',
  SEPA: 'sepa',
  NA: 'N/A',
};

const orderStatus = {
  CLOSED: 'Closed',
  CREATED: 'Created',
  FAILED: 'Failed',
  IN_PROGRESS: 'InProgress',
  PAID: 'Paid',
};

const depositStatus = {
  SUCCEEDED: 'succeeded',
};

const reducerActions = {
  SET_PAYMENT_STATE: 'SET_PAYMENT_STATE',
};

const limit = {
  FAILED_PAYMENT_ATTEMPTS: 3,
};

const constants = {
  paymentOptions,
  orderStatus,
  depositStatus,
  reducerActions,
  limit,
};

export default constants;
