import React from 'react';

export const StyledLabel = ({ children, ...rest }) => (
  <label className="radio-option grid" {...rest}>
    {children}
  </label>
);

export const StyledCheckbox = ({ children }) => (
  <span className="radio-option-checkbox">{children}</span>
);

export const StyledBody = ({ children }) => <span className={'radio-option-body'}>{children}</span>;

export const StyledInput = (props) => <input {...props} className={'radio-option-input'} />;
