import React from 'react';
import classNames from 'classnames';
import SendLinkModal from 'Modules/financing/FinalizeOffer/SendLink/components/SendLinkModal';
import SendLinkButton from 'Modules/financing/FinalizeOffer/SendLink/components/SendLinkButton';
import { useTranslation } from 'react-i18next';

export default function SendLink({ className }) {
  const { t } = useTranslation();
  const AUTO_CLOSE_TIME_MS = 45000;
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [showSendLinkMessage, setShowSendLinkMessage] = React.useState(false);
  const [showButtonSpinner, setShowButtonSpinner] = React.useState(false);
  const [method, setMethod] = React.useState('');
  const openSendLinkModal = () => {
    setIsModalOpen(true);
    setShowSendLinkMessage(false);
  };
  const handleLinkSent = ({ method: argMethod }) => {
    setMethod(argMethod);
    setShowSendLinkMessage(true);
    setShowButtonSpinner(true);
  };

  const onTimerFinished = () => {
    setShowButtonSpinner(false);
  };

  return (
    <>
      <div className={classNames(className, 'w-full')}>
        <SendLinkButton
          openSendLinkModal={openSendLinkModal}
          showButtonSpinner={showButtonSpinner}
          spinnerTime={AUTO_CLOSE_TIME_MS}
          onTimerFinished={onTimerFinished}
        />
        <SendLinkModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          onLinkSent={handleLinkSent}
        ></SendLinkModal>
        <div className="mt-6">
          {showSendLinkMessage && method === 'SMS' && (
            <div className="text-slate-400 text-justify border-slate-300 border-l-4 pl-2">
              <span className="font-semibold">Note: </span>
              {t('financing.nextgen.sendlink.sentInfo.sms')}
            </div>
          )}
          {showSendLinkMessage && method === 'EMAIL' && (
            <div className="text-slate-400 text-justify border-slate-300 border-l-4 pl-2">
              <span className="font-semibold">Note: </span>
              {t('financing.nextgen.sendlink.sentInfo.email')}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
