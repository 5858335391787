import React from 'react';
import { useTranslation } from 'react-i18next';
import { useScrollTop } from 'Core/hooks/useScrollTop';
import { useShowSidebar } from 'Core/hooks/useShowSidebar';
import { Alert, AlertError, AlertSuccess } from 'Core/components/Alert';
import Footer from 'Core/components/Footer';
import { PrimaryButton } from 'Core/components/Button';
import Checklist from 'Modules/creditDebit/components/Checklist';
import useOrder from 'Core/hooks/queries/order/useOrder';
import useGetFinancingApplicationDetails from 'Core/hooks/queries/payment/useGetFinancingApplicationDetails';
import Page from 'Core/components/Page';
import ShoppingCart from 'Core/components/ShoppingCart';
import constants from 'Core/config/constants';
import Loader from 'Core/components/Loader';
import { features, postLogMessage } from 'Core/utils/logger';
import useGetPaymentSummary from 'Core/hooks/queries/payment/useGetPaymentSummary';
import { useNavigate, Navigate } from 'react-location';
import { createFinancingPath, paymentOptionsPath } from 'Modules/creditDebit/routes';

const Financing = ({ nextRoutes }) => {
  useScrollTop();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const shouldShowSidebar = useShowSidebar();
  const [state, setState] = React.useState({
    refetchInterval: false,
    financingWindow: null,
    approved: 'done',
    approvedSigned: 'idle',
    funded: 'idle',
    isError: false,
  });

  const { data: order } = useOrder();
  const { data: paymentSummary } = useGetPaymentSummary();
  const { data: financingApplicationDetails, isError } = useGetFinancingApplicationDetails(
    state.refetchInterval
  );

  React.useEffect(() => {
    if (!financingApplicationDetails) {
      return;
    }

    const { status } = financingApplicationDetails;
    const financingStatus = status.toLowerCase();

    if (isError) {
      setState({
        refetchInterval: false,
        approvedSigned: 'error',
        funded: 'error',
      });
      return;
    }

    if (financingStatus === 'approved & signed' && state.approvedSigned !== 'done') {
      setState({
        ...state,
        approvedSigned: 'done',
      });
      return;
    }

    if (financingStatus === 'funded') {
      const featureList = {
        financing: features.financingCheckout,
        mixed: features.mixedPaymentCheckout,
      };

      postLogMessage({
        amount: paymentSummary.totalAmountToPay,
        feature: featureList[order.optionSelected],
        status: 'success',
      });
      navigate({ to: nextRoutes.success, search: true });
    } else if (financingStatus === 'declined') {
      navigate({ to: nextRoutes.error, search: true });
    }
  }, [
    isError,
    state,
    financingApplicationDetails,
    nextRoutes.success,
    nextRoutes.error,
    paymentSummary.totalAmountToPay,
    order.optionSelected,
    navigate,
  ]);

  if (!order || !paymentSummary || !financingApplicationDetails) {
    return null;
  }

  const openFinancingWindow = (e) => {
    e.preventDefault();
    const financingWindow = window.open(financingApplicationDetails.windowUrl, '_blank');

    setState({
      ...state,
      refetchInterval: 5000,
      financingWindow,
      approvedSigned: 'loading',
      funded: 'loading',
    });
  };

  const checklistItems = [
    { label: t('page.financing.checklist.approved'), status: state.approved },
    { label: t('page.financing.checklist.approvedSigned'), status: state.approvedSigned },
    { label: t('page.financing.checklist.funded'), status: state.funded },
  ];

  // TODO find a better solution for this ...
  let infobox = (
    <Alert data-testid="infobox">{t('page.financing.financingApproved.infobox')}</Alert>
  );
  let mixedQuestion = '';

  if (order.optionSelected === 'mixed') {
    checklistItems.unshift({
      label: t('financingApproved.checklist.downPayment'),
      status: 'done',
    });

    infobox = <AlertSuccess>{t('page.financing.financingApproved.infobox')}</AlertSuccess>;
    mixedQuestion = (
      <span>
        <b>{t('page.financing.failedFinancing.question')}</b>
        <br />
        {t('page.financing.failedFinancing.answer')}
      </span>
    );
  }

  return (
    <Page title={t('page.financing.header')} withSidebar>
      {state.isError && (
        <AlertError>{t('status.error.failedToRequestFinancingApplicationState')}</AlertError>
      )}
      {infobox}

      <Alert>
        <p>{t('page.financing.financingApproved.intro')}</p>

        <div className={`text-center`}>
          <Checklist items={checklistItems} />
        </div>

        <div className={`py-2 text-center`}>
          <PrimaryButton onClick={openFinancingWindow} data-testid="finalize-financing">
            {t('page.financing.openFinancingPartnerWindow')}
          </PrimaryButton>
        </div>

        <hr />

        <p>
          <b>{t('page.financing.whereIsTheTab.question')}</b>
          <br />
          {t('page.financing.whereIsTheTab.answer')}
        </p>
        <p>
          <b>{t('page.financing.troubleFinalizing.question')}</b>
          <br />
          {t('page.financing.troubleFinalizing.answer')}
        </p>
        {mixedQuestion}
      </Alert>
      <Footer
        hasPrev={order.optionSelected === constants.paymentOptions.FINANCING}
        labelPrev={t('common.back')}
        onPrev={() => navigate({ to: paymentOptionsPath, search: true })}
      />
      {!shouldShowSidebar && <ShoppingCart withTitle />}
    </Page>
  );
};

// TODO solve this hack!
const FinancingApplicationCheck = (props) => {
  const { t } = useTranslation();
  const { data, isLoading, isError } = useGetFinancingApplicationDetails(); // <-- PUBLIC

  if (isLoading) {
    return <Loader message={t('status.loading.financingApplication')} />;
  }

  if (isError) {
    return <Navigate to={createFinancingPath} search={true} />;
  }

  if (data?.nextAction === 'createApplication' || data?.nextAction === 'selectOffer') {
    return <Navigate to={createFinancingPath} search={true} />;
  }

  return <Financing {...props} />;
};

export default FinancingApplicationCheck;
