import React from 'react';
import InputField from 'Core/components/FormElements/InputField';
import { useTranslation } from 'react-i18next';
import SelectMenu from 'Core/components/FormElements/SelectMenu';
import { AlertError, Alert } from 'Core/components/Alert';
import InfoBox from 'Core/components/InfoBox';
import { PrimaryButton } from 'Core/components/Button';
import { usStates } from 'Modules/creditDebit/config/usStates';
import Page from 'Core/components/Page';
import BirthDateInput from 'Core/components/FormElements/BirthDateInput';
import { Column, Row } from 'Core/components/Layout';
import { StyledFormSection } from 'Core/components/FormElements/formElements.styled';
import TermsInput from 'Core/components/FormElements/TermsInput';
import Loader from 'Core/components/Loader';
import { useNavigate } from 'react-location';
import { financingPath } from 'Modules/financing/routes';

import useCreateApplicationForm from 'Modules/financing/hooks/useCreateApplicationForm';
import useCreateApplication from 'Modules/financing/hooks/useCreateApplication';
import useGetCustomerInfo from 'Modules/financing/hooks/useGetCustomerInfo';
import useGetApplication from 'Modules/financing/hooks/useGetApplication';
import { FaEye, FaEyeSlash } from 'react-icons/fa/index';
import Quote from 'Core/components/Quote';
import { useScrollTop } from 'Core/hooks/useScrollTop';
import { relationTypes } from '../config/relationTypes';
import { ImInfo } from 'react-icons/im';
import classNames from 'classnames';
import getStateAbbreviation from 'Modules/financing/hooks/getStateAbbreviation';
import { actions, features, postLogMessage } from 'Core/utils/logger';

const dateFormat = 'yyyy-MM-dd';

const mapCustomerData = (customerData) => {
  return {
    ...customerData,
    firstName: customerData.first_name ?? '',
    lastName: customerData.last_name ?? '',
    address: customerData.address_line ?? '',
    city: customerData.city ?? '',
    state: getStateAbbreviation(customerData.state) ?? '',
    zip: customerData.zip ?? '',
    country: customerData.country ?? '',
    email: customerData.email ?? '',
    socialSecurityNumber: customerData.customer_identity_number ?? '',
    phone: customerData.phone_number ?? '',
    annualIncome: (customerData.monthly_income ? customerData.monthly_income * 12 : '').toString(),
    relationship: customerData.relationship ?? 'SELF',
  };
};

const CreateApplication = ({ type = 'primary', customer_id = '' }) => {
  document.title = 'Payment Portal - Create Application';
  const navigate = useNavigate();
  const { t } = useTranslation();
  const scroll = useScrollTop();
  const { mutateAsync: createApplication, isLoading: isCreateAppLoading } = useCreateApplication();
  const [requestId, setRequestId] = React.useState('');
  const [showSsn, setShowSsn] = React.useState(false);
  const [showRelationField, setshowRelationField] = React.useState(type !== 'primary'); // false
  const {
    isSuccess: isGetAppSuccess,
    isLoading: isGetAppLoading,
    data: getAppData,
  } = useGetApplication(requestId);
  const { data: customerInfo } = useGetCustomerInfo();
  const [dobDefaults, setDobDefaults] = React.useState({ day: '01', month: '01', year: '' });
  const [isFinanceAppLoading, setIsFinanceAppLoading] = React.useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors, isSubmitted },
  } = useCreateApplicationForm();

  const selectedRelationship = watch('relationship');
  const relationshipSessionStorageString = sessionStorage.getItem('relationship');
  const previousRelationship = JSON.parse(relationshipSessionStorageString)?.relationship || '';
  const sessionStorageCustomerId = JSON.parse(relationshipSessionStorageString)?.customer_id || '';
  const filteredRelationTypes = relationTypes.filter((item) =>
    !previousRelationship || type === 'primary' ? true : item.value !== previousRelationship
  );
  React.useEffect(() => {
    if (isGetAppLoading) {
      setIsFinanceAppLoading(true);
    }
    if (isGetAppSuccess && getAppData?.next_action) {
      if (getAppData?.next_action) {
        sessionStorage.setItem('application', JSON.stringify(getAppData));
        navigate({ to: financingPath, search: true, replace: true });
        setIsFinanceAppLoading(false);
      } else {
        setIsFinanceAppLoading(true);
      }
    }

    if (customerInfo) {
      const customerData = mapCustomerData(customerInfo);
      if (
        customerData.relationship === previousRelationship &&
        sessionStorageCustomerId === customer_id &&
        type !== 'primary'
      ) {
        customerData.relationship = null;
      }
      Object.keys(customerData).forEach((field) => setValue(field, customerData[field]));

      if (customerData.date_of_birth) {
        const [year] = customerData.date_of_birth;
        let [, month, day] = customerData.date_of_birth;
        if (month > 0 && month < 10) {
          month = '0' + month;
        }
        if (day > 0 && day < 10) {
          day = '0' + day;
        }

        setDobDefaults({ day, month, year });
      }
    } else {
      setDobDefaults({ day: '01', month: '01', year: '' });
      reset(mapCustomerData({}));
    }
  }, [
    customerInfo,
    reset,
    setValue,
    isGetAppSuccess,
    isGetAppLoading,
    navigate,
    requestId,
    getAppData?.next_action,
  ]);

  React.useEffect(() => {
    const errorFields = Object.keys(errors);

    if (errorFields?.length) {
      const errorMap = errorFields.map((field) => {
        return { field, message: errors[field].message };
      });
      console.log('form errors!', errorMap);
      postLogMessage({
        feature: features.createAllegroApp,
        action: actions.createApplicationFormError,
        errors: errorMap,
      });
    }
  }, [errors]);
  const handleRelationshipChange = () => {
    if (customerInfo && customerInfo.relationship === selectedRelationship) {
      const customerData = mapCustomerData(customerInfo);
      Object.keys(customerData).forEach((field) => setValue(field, customerData[field]));

      if (customerData.date_of_birth) {
        const [year] = customerData.date_of_birth;
        let [, day, month] = customerData.date_of_birth;
        if (month > 0 && month < 10) {
          month = '0' + month;
        }
        if (day > 0 && day < 10) {
          day = '0' + day;
        }

        setDobDefaults({ day, month, year });
      }
    } else {
      setDobDefaults({ day: '01', month: '01', year: '' });
      reset(mapCustomerData({}));
    }
  };
  const ssn = watch('socialSecurityNumber');
  React.useEffect(() => {
    setValue('socialSecurityNumber', ssn?.replace(/\D+/g, ''));
  }, [setValue, ssn]);

  const hasFormErrors = Object.keys(errors).length > 0;

  const onSubmit = async (formData) => {
    scroll.top();
    const {
      headers: { request_id },
    } = await createApplication(formData);
    setRequestId(request_id);
  };

  const toggleSsnDisplay = () => {
    setShowSsn(!showSsn);
  };

  return (
    <Page
      title={t('page.createFinancingApplication.header')}
      subtitle={t('page.createFinancingApplication.subtitle')}
      withStepProgress={false}
      stickyFooter
    >
      {isCreateAppLoading && <Loader message={t('allegro.createApplication.loaderText')} />}
      {isFinanceAppLoading && <Loader message={t('status.loading.financingApplication')} />}

      <form name="allegroCreateApplication" onSubmit={handleSubmit(onSubmit)}>
        {hasFormErrors && <AlertError type="error">{t('status.error.checkErrors')}</AlertError>}
        <StyledFormSection>
          <Row>
            {!showRelationField && (
              <Column className="text-gray-500">
                <div className="flex items-center my-2">
                  <span className="inline-block pr-2">
                    {' '}
                    <ImInfo />
                  </span>
                  Filling the application for someone else?
                  <button
                    className="text-blue-500 hover:underline mx-1"
                    type="button"
                    onClick={() => setshowRelationField(true)}
                  >
                    Click here
                  </button>{' '}
                  to change relationship
                </div>
              </Column>
            )}

            <Column className="!mb-0">
              <Alert type="infoLight">{t('allegro.createApplication.enterLegalNames')}</Alert>
            </Column>

            <Column className={classNames('md:!flex-col-6', { hidden: !showRelationField })}>
              <SelectMenu
                register={register}
                label={t('common.relationship')}
                name="relationship"
                items={filteredRelationTypes}
                onChange={handleRelationshipChange}
                errors={errors}
              />
            </Column>

            <Column className="!mb-2"></Column>
            <Column className="md:!flex-col-6">
              <InputField
                register={register}
                label={t('common.firstName')}
                name="firstName"
                errors={errors}
              />
            </Column>

            <Column className="md:!flex-col-6">
              <InputField
                register={register}
                label={t('common.lastName')}
                name="lastName"
                errors={errors}
              />
            </Column>

            <Column className="md:!flex-col-3">
              <InputField
                register={register}
                label={t('common.street')}
                name="address"
                errors={errors}
              />
            </Column>

            <Column className="md:!flex-col-3">
              <InputField
                register={register}
                label={t('common.city')}
                name="city"
                errors={errors}
              />
            </Column>

            <Column className="md:!flex-col-3">
              <SelectMenu
                register={register}
                label={t('common.state')}
                name="state"
                items={usStates}
                errors={errors}
              />
            </Column>

            <Column className="md:!flex-col-3">
              <InputField
                register={register}
                label={t('common.postalCode')}
                name="zip"
                errors={errors}
              />
            </Column>
          </Row>

          <hr />

          <Row>
            <Column className="md:!flex-col-6">
              <InputField
                register={register}
                label={t('common.email')}
                name="email"
                errors={errors}
              />
            </Column>

            <Column className="md:!flex-col-6">
              <InputField
                register={register}
                label={t('common.phone')}
                name="phone"
                errors={errors}
              />
              <Quote className="mt-4">
                {t('allegro.createApplication.readToCustomer.phonenote')}
              </Quote>
            </Column>
          </Row>

          <Row>
            <Column className="md:!flex-col-6 flex items-end">
              <InputField
                className="!rounded-r-none"
                register={register}
                label={t('common.ssn')}
                name="socialSecurityNumber"
                errors={errors}
                onAppendClick={toggleSsnDisplay}
                appendText={showSsn ? <FaEyeSlash /> : <FaEye />}
                type={showSsn ? 'text' : 'password'}
              />
            </Column>

            <Column className="md:!flex-col-6">
              <BirthDateInput
                format={dateFormat}
                register={register}
                name="dateOfBirth"
                padZeros
                onChange={(value) => {
                  setValue('dateOfBirth', value, { shouldValidate: isSubmitted });
                }}
                defaultValues={dobDefaults}
                errors={errors}
              />
            </Column>
          </Row>

          <hr />

          <Row>
            <Column className="md:!flex-col-6">
              <InputField
                register={register}
                label={t('common.annualIncome')}
                name="annualIncome"
                type="number"
                errors={errors}
              />
            </Column>

            <Column className="md:!flex-col-12">
              <Quote>{t('allegro.createApplication.readToCustomer.note2')}</Quote>
            </Column>
          </Row>

          <hr />

          <div className="mt-2">
            <h3>{t('common.noticeToApplicants')}</h3>
            <Alert type="warning">
              <span className="font-semibold text-sm text-black">
                {t('common.readToCustomer.doubleTitle')}
              </span>
            </Alert>
            <div className="mt-4">
              <InfoBox showBorder>{t('financing.nextgen.createApplication.terms.note1')}</InfoBox>
            </div>
            <div className="mt-4">
              <InfoBox showBorder>{t('financing.nextgen.createApplication.terms.note2')}</InfoBox>
            </div>
          </div>

          <hr />

          <TermsInput name="terms" register={register} errors={errors}>
            <b>{t('financing.nextgen.createApplication.terms')}</b>
            <ol className="list-decimal ml-4 mt-2">
              <li>{t('allegro.createApplication.terms.note1')}</li>
              <li>{t('allegro.createApplication.terms.note2')}</li>
            </ol>
          </TermsInput>
        </StyledFormSection>

        <Row>
          <Column className="md:!flex-col-12 !flex gap-1 !justify-end">
            <PrimaryButton type="submit" data-testid="submit-application">
              {t('common.submitPrequalificationApplication')}
            </PrimaryButton>
          </Column>
        </Row>
      </form>
    </Page>
  );
};

export default CreateApplication;
