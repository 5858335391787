import React from 'react';
import classnames from 'classnames';

export default function StyledFormGroup({ children, error }) {
  return (
    <div
      className={classnames(
        'border border-solid border-gray-200 shadow-md rounded-lg mb-4 px-8 py-6 transition-colors bg-white',
        { 'border-error': !!error }
      )}
    >
      {children}
    </div>
  );
}
