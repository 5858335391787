import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import useOrder from 'Core/hooks/queries/order/useOrder';
import useAlertStore, { setErrorAlertSelector } from 'Core/stores/alert';
import cacheKeys from 'Core/hooks/queries/cacheKeys';
import { get } from 'Core/utils/api';

export const queryFn = (orderId) => get(`/api/payment-options/${orderId}`);

export default function useGetPaymentOptions() {
  const { data: order } = useOrder();
  const { t } = useTranslation();
  const setErrorAlert = useAlertStore(setErrorAlertSelector);

  return useQuery(cacheKeys.paymentOptions, () => queryFn(order.orderId), {
    enabled: Boolean(order),
    onError(e) {
      setErrorAlert(t('status.error.getPaymentOptionsQueryFailed'));
      throw e;
    },
  });
}
