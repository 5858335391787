import { useMutation, useQueryClient } from 'react-query';
import cacheKeys from 'Core/hooks/queries/cacheKeys';
import { del } from 'Core/utils/api';

export default function useDeletePaymentMethod() {
  const queryClient = useQueryClient();

  return useMutation(
    (paymentMethodId) => {
      return del(`/api/payment-methods/${paymentMethodId}`);
    },
    {
      async onMutate(paymentMethodId) {
        await queryClient.cancelQueries(cacheKeys.savedPaymentMethods);

        const previousPaymentMethods = queryClient.getQueryData(cacheKeys.savedPaymentMethods);

        queryClient.setQueryData(cacheKeys.savedPaymentMethods, (paymentMethods) =>
          paymentMethods.filter((pm) => pm.paymentMethodId !== paymentMethodId)
        );

        return { previousPaymentMethods };
      },
      onError(e) {
        queryClient.setQueryData(cacheKeys.savedPaymentMethods, context.previousPaymentMethods);
        throw e;
      },
      onSettled() {
        return queryClient.invalidateQueries(cacheKeys.paymentHistory);
      },
    }
  );
}
