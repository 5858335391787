import React from 'react';
import { StyledBody, StyledInput, StyledLabel } from './VerticalRadioOption.styled';
import classnames from 'classnames';
import Ribbon from 'Modules/core/components/Ribbon/Ribbon';
export default function VerticalRadioOption({
  name,
  value,
  checked,
  disabled,
  testid,
  onChange,
  children,
  tag,
}) {
  const id = `${name}-${value}`;

  return (
    <StyledLabel htmlFor={id} data-testid={testid || id}>
      <StyledInput
        type="radio"
        name={name}
        value={value}
        id={id}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        role="radio"
      />
      <StyledBody>
        {Boolean(tag) && <Ribbon label={tag}></Ribbon>}

        <span className={classnames('w-full flex flex-col h-full', { 'pt-8': !tag })}>
          {children}
        </span>
      </StyledBody>
    </StyledLabel>
  );
}
