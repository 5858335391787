import React from 'react';
import { useTranslation } from 'react-i18next';
import useOrder from 'Core/hooks/queries/order/useOrder';
import CreateApplication from 'Modules/creditDebit/components/Financing/allegro/CreateApplication';
import SelectOffer from 'Modules/creditDebit/components/Financing/allegro/SelectOffer';
import useGetApplication from 'Modules/creditDebit/components/Financing/allegro/useGetApplication';
import OrderData from 'Core/components/Data/OrderData';
import Loader from 'Core/components/Loader';
import ApplicationStatus from 'Modules/creditDebit/components/Financing/allegro/ApplicationStatus';
import {
  financingPath,
  mixedDownPaymentCheckoutPath,
  paymentOptionsPath,
} from 'Modules/creditDebit/routes';

// TODO solve this hack!
const FinancingRedirect = () => {
  const routes = {
    paymentOptions: paymentOptionsPath,
    financing: financingPath,
    mixed: mixedDownPaymentCheckoutPath,
  };

  const { t } = useTranslation();
  const { data: application, isLoading, error } = useGetApplication(); // <-- PARTNER-ID REQUIRED
  const { nextAction = 'createApplication' } = application || {};

  // No application found? -> Show form to create one
  if (error?.response?.status === 404) {
    return <CreateApplication routes={{ paymentOptions: routes.paymentOptions }} />;
  }

  if (isLoading) {
    return <Loader message={t('status.loading.financingApplication')} />;
  }

  if (application?.nextAction) {
    switch (nextAction) {
      case 'createApplication':
        return <CreateApplication routes={{ paymentOptions: routes.paymentOptions }} />;

      case 'selectOffer':
      case 'readyToBeSigned':
        return (
          <OrderData>
            <SelectOffer application={application} routes={routes} />
          </OrderData>
        );

      default:
        return (
          <ApplicationStatus
            application={application}
            routes={{ paymentOptions: routes.paymentOptions }}
          />
        );
    }
  }

  // Application status is approved? -> Show form to set the terms/offer
  if (status === 'approved') {
    return (
      <OrderData>
        <SelectOffer application={application} routes={routes} />
      </OrderData>
    );
  }

  return (
    <ApplicationStatus
      application={application}
      routes={{ paymentOptions: routes.paymentOptions }}
    />
  );
};

export default function FinancingOffice() {
  const { data: order } = useOrder();

  if (!order) {
    return null;
  }

  return <FinancingRedirect />;
}
