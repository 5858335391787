import React from 'react';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';

export default function useDirectSalesForm(defaultValues) {
  const { t } = useTranslation();

  const schema = React.useMemo(() => {
    return z.union([
      z.object({
        firstName: z.string().nonempty({ message: t('common.requiredFieldFirstName') }),
        lastName: z.string().nonempty({ message: t('common.requiredFieldLastName') }),
        postalCode: z.string().nonempty({ message: t('common.requiredFieldPostalCode') }),
        cardNumber: z
          .any()
          .refine((value) => Boolean(value), { message: t('common.requiredFieldCardNumber') }),
        cardExpiry: z
          .any()
          .refine((value) => Boolean(value), { message: t('common.requiredFieldCardExpiry') }),
        cardCvc: z
          .any()
          .refine((value) => Boolean(value), { message: t('common.requiredFieldCardCode') }),
        amount: z.string(),
        type: z.string(),
        terms: z.boolean().refine((val) => val, { message: t('common.requiredFieldTerms') }),
        stripeElement: z.any(),
        stripe: z.any(),
      }),
      z.object({
        paymentMethodId: z.string().nonempty({ message: t('common.requiredFieldFirstName') }),
        amount: z.string(),
        type: z.string(),
        terms: z.boolean().refine((val) => val, { message: t('common.requiredFieldTerms') }),
      }),
    ]);
  }, [t]);

  return useForm({
    resolver: zodResolver(schema),
    defaultValues,
    shouldUnregister: true,
  });
}
