import React from 'react';
import PropTypes from 'prop-types';

const FooterBlock = ({ children, testid = '' }) => {
  return (
    <div className={'px-1 my-2 last:mb-0'} data-testid={testid}>
      {children}
    </div>
  );
};

FooterBlock.propTypes = {
  children: PropTypes.any.isRequired,
  testid: PropTypes.string,
};

export default FooterBlock;
