import React from 'react';
import PropTypes from 'prop-types';

const Disclaimer = ({ content, show = false }) => {
  return (
    show && (
      <div className="pt-2 mt-2 border-t border-solid border-gray-300 text-xxs">{content}</div>
    )
  );
};

Disclaimer.propTypes = {
  content: PropTypes.string.isRequired,
  show: PropTypes.bool,
};

export default Disclaimer;
