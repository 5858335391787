import React from 'react';
import { filterByPropertyValue, getPreferredPaymentMethod } from 'Core/utils/utils';
import useDepositForm from 'Modules/deposit/hooks/useDepositForm';
import CardSelector from 'Core/components/CardSelector';
import StyledFormGroup from 'Core/components/FormElements/StyledFormGroup';
import { PrimaryButton } from 'Core/components/Button';
import { Trans, useTranslation } from 'react-i18next';
import { Column } from 'Core/components/Layout';
import Row from 'Core/components/Layout/Row';
import { formatCurrency } from 'Core/utils/numberFormat';
import { Alert } from 'Core/components/Alert';
import Option from 'Core/components/Option/Option';
import TermsInput from 'Core/components/FormElements/TermsInput';
import Checkbox from 'Core/components/FormElements/Checkbox';

function StyledOptionItem({ children }) {
  return (
    <div className="flex flex-col items-center justify-between bg-white border-2 border-solid border-gray-300 rounded py-6 px-2 mb-4 w-full transition-colors hover:border-primary hover:[background:linear-gradient(165deg,#dedede_50%,#d2d2d2_50%)]">
      {children}
    </div>
  );
}

const DepositOptions = ({ options, form }) => {
  const optionRef = React.useRef(null);
  const { t } = useTranslation();
  const { register, getValues, setValue } = form;
  const { type } = form.getValues();
  const [displayedOptions, setDisplayedOptions] = React.useState(
    filterByPropertyValue(options, 'visibility', 'VISIBLE')
  );

  // Manually set value in case there is only 1 option
  React.useEffect(() => {
    if (displayedOptions.length === 1) {
      const [{ amount }] = displayedOptions;
      setValue('amount', String(amount));
    }

    //Need to set manually for initial render to register the field
    setValue('type', type);
  }, [type, displayedOptions, setValue]);

  const onToggleOptions = React.useCallback(() => {
    if (options.length === displayedOptions.length) {
      setDisplayedOptions(filterByPropertyValue(options, 'visibility', 'VISIBLE'));
    } else {
      setDisplayedOptions(options);
    }
  }, [displayedOptions.length, options]);

  //Set first option as selected when toggling options
  React.useEffect(() => {
    const [{ type, amount }] = displayedOptions;
    setValue('type', type);
    setValue('amount', String(amount));
    optionRef.current.querySelector('input').click();
    optionRef.current.dispatchEvent(new Event('click', { bubbles: true }));
  }, [displayedOptions, displayedOptions.length, setValue]);

  if (displayedOptions.length === 1) {
    const [{ type, amount }] = displayedOptions;
    return (
      <Alert className="text-base font-bold">
        <Trans
          i18nKey={`deposit.${type}`}
          values={{ amount: formatCurrency(amount, { withDecimals: false }) }}
        />
      </Alert>
    );
  }

  return (
    <>
      <h2>{t('common:depositAmount')}</h2>
      <button type="button" className="text-lg mb-2 underline" onClick={onToggleOptions}>
        {t('common.optionsToggle')} {options.length === displayedOptions.length ? '↑' : '↓'}
      </button>
      <Row data-testid="deposit-card-option-container" ref={optionRef}>
        {displayedOptions.map(({ option, amount, type }) => (
          <Column className="md:flex-col-4" key={option}>
            <Option
              register={register}
              className="styled-deposit-option !flex"
              name="amount"
              value={amount}
              defaultChecked={getValues().amount === amount}
              onChange={() => setValue('type', type)}
            >
              <StyledOptionItem>
                <span className="text-4xl font-bold">
                  {formatCurrency(amount, { withDecimals: false })}
                </span>
                <span className="text-xl font-bold">{t(`common.${type}`)}</span>
              </StyledOptionItem>
            </Option>
          </Column>
        ))}
      </Row>
    </>
  );
};

export default function DepositCardForm({ paymentMethods, depositOptions, onSubmit, cardErrors }) {
  const { t } = useTranslation();
  const { paymentMethodId } = getPreferredPaymentMethod(paymentMethods);
  const [{ type, amount }] = depositOptions;

  const defaultValues = paymentMethodId
    ? { paymentMethodId, type, amount, terms: false }
    : {
        firstName: '',
        lastName: '',
        postalCode: '',
        cardNumber: '',
        cardExpiry: '',
        cardCvc: '',
        preferred: true,
        type,
        amount: 1,
        terms: false,
      };
  const form = useDepositForm(defaultValues);
  const {
    watch,
    handleSubmit,
    register,
    formState: { errors },
  } = form;

  const cardError = cardErrors?.[0].message;

  const termsTextId = watch('type') === 'authorize' ? 'terms.card.hold' : 'terms.card.charge';

  return (
    <form name="depositCardForm" onSubmit={handleSubmit(onSubmit)}>
      <StyledFormGroup error={cardError}>
        <CardSelector cardError={cardError} form={form} paymentMethods={paymentMethods}>
          <Checkbox label={t('common.markAsPreferredCard')} name="preferred" register={register} />
        </CardSelector>
      </StyledFormGroup>

      <DepositOptions options={depositOptions} form={form} />

      <TermsInput
        name="terms"
        register={register}
        errors={errors}
        label={t('common.termsAndConditions')}
      >
        <p>{t(termsTextId)}</p>
      </TermsInput>

      <Row className="!justify-end">
        <Column className="!flex-col-6 md:!flex-col-4 lg:!flex-col-3">
          <PrimaryButton block={true} type="submit" data-testid="checkout">
            {t('common.checkout')}
          </PrimaryButton>
        </Column>
      </Row>
    </form>
  );
}
