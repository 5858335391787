import { useQuery } from 'react-query';
import cacheKeys from 'Core/hooks/queries/cacheKeys';
import { get } from 'Core/utils/api';

export const queryFn = () => get(`/api/payment-methods`);

export default function useGetSavedPaymentMethods(enabled = true) {
  return useQuery(cacheKeys.savedPaymentMethods, queryFn, {
    enabled,
  });
}
