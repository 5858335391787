import React from 'react';
import PropTypes from 'prop-types';
import { useGetOrderReceiptUrls } from 'Core/hooks/queries/order/useGetOrderReceiptUrls';
import Receipt from './Receipt';

const OrderReceipts = ({ title = '' }) => {
  const queryResponse = useGetOrderReceiptUrls();

  return <Receipt title={title} {...queryResponse} />;
};

OrderReceipts.propTypes = {
  title: PropTypes.string,
};

export default OrderReceipts;
