import { useMutation, useQueryClient } from 'react-query';
import cacheKeys from 'Core/hooks/queries/cacheKeys';
import { post } from 'Core/utils/api';
import { features, postLogMessage } from 'Core/utils/logger';

export default function useAddPaymentMethod(shouldLog = false) {
  const queryClient = useQueryClient();

  return useMutation(
    ({ paymentMethodId, preferred }) => {
      return post(`/api/payment-methods/`, { paymentMethodId, preferred });
    },
    {
      onSuccess() {
        //normally used to track card pref center.
        shouldLog &&
          postLogMessage({
            feature: features.cardPreferenceAddCard,
            status: 'success',
          });

        return queryClient.invalidateQueries(cacheKeys.savedPaymentMethods);
      },
    }
  );
}
