import React from 'react';
import PropTypes from 'prop-types';
import { formatCurrency } from 'Core/utils/numberFormat';

function Skeleton() {
  return <div className="h-5 rounded w-full bg-gray-200 animate-pulse" />;
}

const Item = ({ amount, label, quantity = null }) => {
  return (
    <div
      className={
        'flex flex-nowrap text-right text-sm py-2 px-1 bg-white border-t border-solid border-gray-300'
      }
    >
      <div className="text-left px-1 [width:45%]">{label || <Skeleton />}</div>
      <div className="px-1 [width:25%]">{quantity ?? <Skeleton />}</div>
      <div className="px-1 font-bold [width:30%]">
        {typeof amount === 'number' ? formatCurrency(amount) : <Skeleton />}
      </div>
    </div>
  );
};

Item.propTypes = {
  amount: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  quantity: PropTypes.number,
};

export default Item;
