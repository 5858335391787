export function getUrlParam(name, defaultValue = null) {
  const urlObj = new URL(window.location.href);
  return urlObj.searchParams.get(name) || defaultValue;
}

export function setUrlParam(key, newVal = null) {
  try {
    const urlObj = new URL(window.location.href);
    if (!urlObj.searchParams.get(key)) {
      urlObj.searchParams.append(key, newVal);
      window.history.replaceState(null, '', urlObj.toString());
    }
  } catch (error) {
    console.error('Error in setting URL params', error);
  }
}

export function getPaymentPortalUrl() {
  return window.location.href.replace(window.location.pathname, '/');
}

export function sortByObjectProp(prop) {
  let sortOrder = 1;

  if (prop[0] === '-') {
    sortOrder = -1;
    prop = prop.substr(1);
  }

  return function (a, b) {
    const result = a[prop] < b[prop] ? -1 : a[prop] > b[prop] ? 1 : 0;
    return result * sortOrder;
  };
}

export function multiSortByObjectProp(...props) {
  return function (obj1, obj2) {
    let i = 0,
      result = 0;
    const propsCount = props.length;

    while (result === 0 && i < propsCount) {
      result = sortByObjectProp(props[i])(obj1, obj2);
      i++;
    }
    return result;
  };
}

export function getPropValue(obj, key, defaultValue) {
  return (
    key.split('.').reduce((o, k) => {
      return o ? o[k] : o;
    }, obj) || defaultValue
  );
}

export function filterByPropertyValue(arr, property, value) {
  return arr.filter((item) => item[property] === value);
}

export function findByPropertyValue(arr, property, value) {
  return arr.find((item) => item[property] === value);
}

export function stripTrailingSlash(str) {
  return str.replace(/(.+)(\/$)/, '$1');
}

export function dedupeConsecutiveSlashes(str) {
  return str.replace(/\/\/+/, '/');
}

export function getPreferredPaymentMethod(paymentMethods) {
  const preferredPaymentMethod = findByPropertyValue(paymentMethods, 'preferred', true);
  return preferredPaymentMethod
    ? preferredPaymentMethod
    : paymentMethods.length
    ? paymentMethods[0]
    : { paymentMethodId: '' };
}

export function getFormPrefix(prefix, index) {
  return prefix ? `${prefix}[${index}].` : '';
}

export function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export function getDecimals(num, res = 2) {
  return parseFloat((num % 1).toFixed(res));
}

export function generateURL({ url = window.location.origin, params = {} }) {
  const searchParams = new URLSearchParams(window.location.search);
  for (const [key, value] of Object.entries(params)) {
    searchParams.append(key, value);
  }
  return `${url}?${searchParams.toString()}`;
}
