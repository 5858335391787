import axios from 'axios';
import { getUrlParam } from 'Core/utils/utils';
import { getAppConfig } from 'Core/config/appConfig';

const axiosInstance = axios.create({
  baseURL: process.env.API_PAYMENT_URL,
  headers: {
    'Payment-Token': getUrlParam('token'),
    'Accept-Language': getAppConfig().locale,
  },
});

export function get(url, config) {
  return axiosInstance.get(url, config).then(({ data }) => data);
}

export function post(url, payload, config) {
  return axiosInstance.post(url, payload, config).then(({ data }) => data);
}

export function put(url, payload, config) {
  return axiosInstance.put(url, payload, config).then(({ data }) => data);
}

export function del(url, payload, config) {
  return axiosInstance.delete(url, payload, config).then(({ data }) => data);
}
export function mock(url, config) {
  return axios.get(url, config).then(({ data }) => data);
}
