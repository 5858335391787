import React from 'react';
import { useTranslation } from 'react-i18next';
import Page from 'Core/components/Page';

export const NotFound = () => {
  const { t } = useTranslation();

  return (
    <Page title={t('page.error.header')} withStepProgress={false} withSidebar={false}>
      <h1>{t('common.error404')}</h1>
      <p>{t('status.error.pageNotFound')}</p>
    </Page>
  );
};
