import { useMutation, useQueryClient } from 'react-query';
import cacheKeys from 'Core/hooks/queries/cacheKeys';
import { features, postLogMessage } from 'Core/utils/logger';
import { post } from 'Core/utils/platformApi';

export default function usePostAddCard() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ customerId, externalReferenceId, paymentMethodId, preferred, brand, last4 }) => {
      return post(
        `/cards`,
        {
          customer_id: customerId,
          card: { payment_method_id: paymentMethodId, preferred, last4digit: last4, brand },
        },
        {
          headers: { external_reference_id: externalReferenceId },
        }
      );
    },
    {
      onSuccess() {
        postLogMessage({
          feature: features.platformAddCard,
          status: 'success',
        });

        return queryClient.invalidateQueries(cacheKeys.savedCards);
      },
    }
  );
}
