import { useMutation } from 'react-query';
import { get } from 'Core/utils/platformApi';
import { useGetPaymentInfo } from 'Core/hooks/platform/useGetPaymentInfo';

export default function useGetContract() {
  const { data: paymentInfo } = useGetPaymentInfo();

  return useMutation(
    () =>
      get(`/download/contract`, {
        headers: {
          external_reference_id: paymentInfo?.external_reference_id,
          'Content-Type': 'application/pdf',
        },
        responseType: 'arraybuffer',
      }),
    {
      throwOnError: true,
      retry: 3,
      enabled: Boolean(paymentInfo?.external_reference_id),
    }
  );
}
