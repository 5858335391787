import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from 'Core/components/Button';
import CopyField from 'Core/components/FormElements/CopyField';
import { getPaymentPortalUrl, isEmpty } from 'Core/utils/utils';
import useOrder from 'Core/hooks/queries/order/useOrder';
import usePostPaymentInvitation from 'Core/hooks/queries/payment/usePostPaymentInvitation';
import {
  StyledCard,
  StyledCardBody,
  StyledCardFooter,
  StyledCardHeader,
} from 'Core/components/Panels/Panels';
import TextArea from 'Core/components/FormElements/TextArea';
import Spinner from 'Core/components/Spinner';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import classNames from 'classnames';

export default function SendPaymentLinkPanel() {
  const { t } = useTranslation();

  const { data: order } = useOrder();
  const { mutateAsync: sendPaymentInvitation, isLoading, status } = usePostPaymentInvitation();
  const [statusMessage, setStatusMessage] = React.useState();

  const schema = React.useMemo(() => {
    return z.object({
      emails: z
        .string()
        .transform((text) => text.split('\n'))
        .refine(
          (emails) => {
            const { success } = z.array(z.string().email()).safeParse(emails);
            return success;
          },
          { message: t('status.error.invalidEmailAddress') }
        )
        .refine((emails) => [...new Set(emails)].length === emails.length, {
          message: t('status.error.duplicateEmailAddress'),
        }),
    });
  }, [t]);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      emails: '',
    },
    shouldUnregister: true,
  });

  const statusMessageStyle =
    status === 'error' || !isEmpty(errors)
      ? `text-error`
      : status === 'success'
      ? `text-success`
      : ``;

  React.useEffect(() => {
    isSubmitSuccessful && reset();
  }, [isSubmitSuccessful, reset]);

  async function onSubmit(formData) {
    setStatusMessage(t('status.loading.submitInviteEmail'));
    try {
      await sendPaymentInvitation({ orderId: order.orderId, emailList: formData.emails });
      reset();
      setStatusMessage(t('status.success.sendingPaymentInviteEmail'));
    } catch (e) {
      setStatusMessage(t('status.error.sendingPaymentInviteEmail'));
    }
  }

  return (
    <form name="sendPaymentLinkForm" onSubmit={handleSubmit(onSubmit)}>
      <StyledCard>
        <StyledCardHeader>{t('page.dashboard.panel.sendInviteEmailTitle')}</StyledCardHeader>
        <StyledCardBody>
          <TextArea
            register={register}
            label={t('page.dashboard.panel.sendInviteEmailBody')}
            name="emails"
          />
          <div className={classNames(`py-1 text-xs`, statusMessageStyle)}>
            {errors?.emails?.message || statusMessage}
          </div>
        </StyledCardBody>
        <StyledCardFooter>
          <PrimaryButton
            block
            size="small"
            data-testid="send-emails"
            type="submit"
            disabled={isLoading || !watch('emails')}
          >
            {isLoading && <Spinner small />}
            {isLoading
              ? t('status.loading.submitInviteEmail')
              : t('page.dashboard.panel.sendInviteEmailButton')}
          </PrimaryButton>
        </StyledCardFooter>
        <StyledCardFooter>
          <CopyField
            value={getPaymentPortalUrl(window.location.href)}
            testid="paymentPortalUrlCopy"
          />
        </StyledCardFooter>
      </StyledCard>
    </form>
  );
}
