import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import useAlertStore, { setErrorAlertSelector } from 'Core/stores/alert';
import { post } from 'Core/utils/api';

export default function usePostCaptureAvailableCredit() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const setErrorAlert = useAlertStore(setErrorAlertSelector);

  return useMutation(
    () => {
      const { orderId } = queryClient.getQueryData('order');
      return post(`/api/orders/${orderId}/payments/capture`);
    },
    {
      onSuccess() {
        return Promise.resolve();
      },
      onError(e) {
        const text =
          e?.response?.data?.errors?.map((item) => item.message).join('\n') ||
          t('status.error.postCaptureAvailableCreditQueryFailed');
        setErrorAlert(text);
        throw e;
      },
    }
  );
}
