import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from 'Core/components/Button';
import CreditCardLogos from 'Core/components/CreditCardLogos';
import allegroLogo from 'Images/allegro-logo.png';
import {
  StyledCard,
  StyledCardBody,
  StyledCardFooter,
  StyledCardHeader,
} from 'Core/components/Panels/Panels';

export default function CollectPaymentPanel({ gotoNext }) {
  const { t } = useTranslation();

  return (
    <StyledCard>
      <StyledCardHeader>{t('page.dashboard.panel.collectPaymentTitle')}</StyledCardHeader>
      <StyledCardBody>
        {t('page.dashboard.panel.collectPaymentBody')}

        <div className="flex flex-col justify-center h-full">
          <CreditCardLogos size="large" />
          <div className="mt-4 text-center">
            <img src={allegroLogo} style={{ height: '48px', margin: 'auto' }} alt="hear.com" />
          </div>
        </div>
      </StyledCardBody>
      <StyledCardFooter>
        <PrimaryButton block size="small" data-testid="continue" onClick={() => gotoNext()}>
          {t('page.dashboard.panel.collectPaymentButton')}
        </PrimaryButton>
      </StyledCardFooter>
    </StyledCard>
  );
}
