import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

function StyledOptionsList({ isSelected, isDisabled, children, ...rest }) {
  return (
    <div
      {...rest}
      className={classnames(
        `options-list flex flex-nowrap items-start
        bg-white
        border-2 border-solid border-gray-300 rounded
        p-2
        mb-2
        transition-colors
        cursor-pointer
        hover:border-gray-500 last:mb-4`,
        {
          'border-primary hover:border-primary options-list__is-selected': isSelected,
          'cursor-not-allowed bg-gray-200 border-gray-200 hover:border-gray-200 options-list__is-disabled':
            isDisabled,
        }
      )}
    >
      {children}
    </div>
  );
}

const OptionsList = (props) => {
  const {
    options,
    compareKey,
    disabledKey = 'disabled',
    selectedOption,
    onSelect,
    optionComponent: Option,
  } = props;

  return (
    <div role="listbox">
      {options.map((option) => {
        const optionValue = option[compareKey];
        const isSelected = selectedOption === optionValue;
        const isDisabled = option[disabledKey];
        const selectOption = () => {
          !isDisabled && onSelect(optionValue, option);
        };
        const tabIndex = isDisabled ? '-1' : '0';

        return (
          <StyledOptionsList
            key={optionValue}
            isSelected={isSelected}
            isDisabled={isDisabled}
            onClick={selectOption}
            data-testid={props['data-testid']}
            role="option"
            aria-selected={isSelected}
            aria-disabled={isDisabled}
            tabIndex={tabIndex}
          >
            <div className="options-list__checkbox" />
            <div className="w-full">
              <Option isSelected={isSelected} {...option} />
            </div>
          </StyledOptionsList>
        );
      })}
    </div>
  );
};

OptionsList.propTypes = {
  options: PropTypes.array.isRequired,
  optionComponent: PropTypes.elementType.isRequired,
  compareKey: PropTypes.string.isRequired,
  selectedOption: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  testid: PropTypes.string,
};

export default OptionsList;
