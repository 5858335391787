import React from 'react';
import InputField from 'Core/components/FormElements/InputField';
import { useTranslation } from 'react-i18next';

export default function EmailOption({ form, errors }) {
  const { register } = form;
  const { t } = useTranslation();

  return (
    <InputField block register={register} label={t('common.email')} name="email" errors={errors} />
  );
}
