import { useMutation } from 'react-query';
import { post } from 'Core/utils/api';
import { postLogMessage, features } from 'Core/utils/logger';

export default function usePostChargeDeposit() {
  return useMutation((payload) => post(`/api/deposit`, payload), {
    throwOnError: true,
    onSuccess: (data, variables) => {
      postLogMessage({
        amount: variables.amount,
        feature: features.depositCheckout,
        status: 'success',
      });
    },
  });
}
