import React from 'react';
import Icon from 'Core/components/Icon/Icon';
import classnames from 'classnames';

export default function Attachment({ type = 'link', url, children, onClick, disabled }) {
  if (type === 'link') {
    return (
      <a
        className={classnames('bg-transparent hover:bg-gray-100 rounded inline-block', {
          'cursor-not-allowed pointer-events-none opacity-30': disabled,
        })}
        target="_blank"
        rel="noreferrer"
        href={url}
      >
        <div className="p-2 border border-gray-300 bg-gray-100 w-72 h-16 flex items-center rounded hover:bg-blue-50">
          <div className="px-2">
            <Icon type="receipt"></Icon>
          </div>
          <div className="h-full border-l-2 ml-2"></div>
          <div className="ml-4 font-semibold text-gray-700">{children}</div>
        </div>
      </a>
    );
  } else if (type === 'button') {
    return (
      <button
        type="button"
        className="bg-transparent hover:bg-gray-100 rounded inline-block"
        onClick={onClick}
      >
        <div className="p-2 border border-gray-300 bg-gray-100 w-72 h-16 flex items-center rounded hover:bg-blue-50">
          <div className="px-2">
            <Icon type="receipt"></Icon>
          </div>
          <div className="h-full border-l-2 ml-2"></div>
          <div className="ml-4 font-semibold text-gray-700">{children}</div>
        </div>
      </button>
    );
  }
}
