import React from 'react';
import { Column, Row } from 'Core/components/Layout';
import { getCard } from 'Core/components/CreditCardLogos';

const SavedCard = ({ paymentMethod }) => {
  const { img, name } = getCard(paymentMethod.card.brand);

  return (
    <>
      <Row>
        <Column className="!mb-0">
          <h3 className="flex font-medium">
            <img src={img} className="h-8 mr-2" alt={name} /> **** **** ****{' '}
            {paymentMethod.card.last4}
          </h3>
        </Column>
      </Row>
    </>
  );
};

export default SavedCard;
