import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Page from 'Core/components/Page';
import Footer from 'Core/components/Footer';
import { Alert, AlertWarning } from 'Core/components/Alert';
import { useNavigate } from 'react-location';

const ApplicationStatus = ({ application, routes }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id, status } = application;

  const getStatusMessage = () => {
    switch (status.toLowerCase()) {
      case 'created':
      case 'contact':
      case 'prequalified':
      case 'prequalify':
      case 'no decision':
      case 'pending':
      case 'review':
        return t('allegro.application.status.additionalInputNeeded');

      case 'rejected':
      case 'declined':
        return t('allegro.application.status.notEligible');

      default:
        return t('allegro.application.status.processCanceled');
    }
  };

  return (
    <Page title={t('page.applicationStatus.header')} withStepProgress={false}>
      <AlertWarning>{t('allegro.application.status.intro')}</AlertWarning>
      <h3>
        <Trans i18nKey="allegro.application.status" values={{ id, status }} />
      </h3>

      <Alert>{getStatusMessage()}</Alert>

      <Footer
        hasNext={true}
        labelNext={t('common.goBackToPaymentOptions')}
        onNext={() => navigate({ to: routes.paymentOptions, search: true })}
      />
    </Page>
  );
};

export default ApplicationStatus;
