import React from 'react';
import { OutlineButton } from 'Core/components/Button';
import { Column, Row } from 'Core/components/Layout';
import Spinner from 'Core/components/Spinner';
import { useTranslation } from 'react-i18next';
import SendLinkTimer from 'Modules/financing/FinalizeOffer/SendLink/components/SendLinkTimer';
export default function SendLinkButton({
  openSendLinkModal,
  showButtonSpinner,
  onTimerFinished,
  spinnerTime,
}) {
  const { t } = useTranslation();
  if (showButtonSpinner) {
    return (
      <Row data-testid="sendlink-spinner">
        <Column className="!flex-col-12 text-center mb-2 mt-2">
          <Spinner small className="!w-6 !h-6 !text-primary" />
          <span className="pl-4 text-success">
            {t('financing.nextgen.sendLink.loadingText')}
            <SendLinkTimer
              duration={spinnerTime}
              onTimerFinished={onTimerFinished}
              className="font-semibold"
            ></SendLinkTimer>
          </span>
        </Column>
      </Row>
    );
  }
  return (
    <OutlineButton block onClick={openSendLinkModal} data-testid="btn-sendlink-open-popup">
      {t('common.sendLinkButtonLabel')}
    </OutlineButton>
  );
}
