import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function SendLinkTimer({ duration, onTimerFinished, className }) {
  const [secondsLeft, setSecondsLeft] = React.useState(duration / 1000);
  const { t } = useTranslation();
  React.useEffect(() => {
    const interval = setInterval(() => {
      if (secondsLeft >= 0) {
        setSecondsLeft((seconds) => seconds - 1);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
      if (onTimerFinished && secondsLeft <= 0) {
        onTimerFinished();
      }
    };
  });

  return (
    <span className={classNames(className)} data-testid="seconds-left">
      {secondsLeft}&nbsp;{t('common.seconds')}
    </span>
  );
}
