import React from 'react';
import { useTranslation } from 'react-i18next';
import DownPaymentItem from './DownPaymentItem';
import { formatCurrency } from 'Core/utils/numberFormat';
import useGetPaymentSummary from 'Core/hooks/queries/payment/useGetPaymentSummary';

const DownPayment = () => {
  const { t } = useTranslation();
  const { data: paymentSummary } = useGetPaymentSummary();

  if (!paymentSummary) {
    return null;
  }

  const { downPayment, totalAmountToPay, amountToBeFinanced, amountAlreadyPaid, payments } =
    paymentSummary;
  const hasDeposit =
    payments.length && payments.find((payment) => payment.purpose.toLowerCase() === 'deposit');
  const showAvailableCredit = hasDeposit && amountAlreadyPaid > 0;

  return (
    <>
      <h1>{t('common.downPayment')}</h1>
      <div
        data-testid="down-payment"
        className="p-2 mb-4 bg-yellow-100 border border-solid border-yellow-200 rounded"
      >
        {showAvailableCredit ? (
          <>
            <DownPaymentItem
              label={t('common.availableCredit')}
              value={<b className="text-success">{formatCurrency(-amountAlreadyPaid)}</b>}
            />
            <DownPaymentItem label={t('common.downPayment')} value={formatCurrency(downPayment)} />
          </>
        ) : null}
        <DownPaymentItem
          label={<b>{t('common.downPaymentWithCreditCard')}</b>}
          value={<b>{formatCurrency(totalAmountToPay)}</b>}
        />
        <DownPaymentItem
          label={t('common.resdiualFinancingAmount')}
          value={formatCurrency(amountToBeFinanced)}
        />
      </div>
    </>
  );
};

export default DownPayment;
