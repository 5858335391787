import React from 'react';
import Error from 'Core/components/FormElements/Error';
import { useTranslation } from 'react-i18next';

const TermsInput = ({ register, errors, errorMessage, name, children, label }) => {
  const { t } = useTranslation();
  const error = errorMessage || errors?.[name]?.message;

  return (
    <>
      {label && <h4 className="font-semibold">{label}</h4>}
      <div className="">
        <div className="flex">
          <div>
            <input
              className="w-4 h-4 pr-2 text-blue-600 bg-gray-100 rounded border-gray-300  dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              id={name}
              data-testid="termsAndConditions"
              type="checkbox"
              name={name}
              {...register(name)}
            />
          </div>
          <label className="text-sm m-0 pl-4 pr-2 text-justify overflow-auto w-full" htmlFor={name}>
            {children}
          </label>
        </div>
        <Error message={t('requiredAcceptTermsAndConditions')} show={Boolean(error)} />
      </div>
    </>
  );
};

export default TermsInput;
