import React from 'react';

function useScrollBottom() {
  const scrollBottom = (x = 0) => {
    window.scrollTo({
      left: x,
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  };

  React.useEffect(() => {
    scroll();
  }, []);

  return {
    bottom: scrollBottom,
  };
}

export { useScrollBottom };
