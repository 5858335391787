import React from 'react';
import CheckListItem from './CheckListItem';
import IndicatorCheckmark from './IndicatorCheckmark';
import IndicatorWarn from './IndicatorWarn';
import IndicatorError from './IndicatorError';
import IndicatorLoading from './IndicatorLoading';
import PropTypes from 'prop-types';

const indicatorList = {
  done: <IndicatorCheckmark color="green" />,
  loading: <IndicatorLoading />,
  warn: <IndicatorWarn color="orange" />,
  error: <IndicatorError color="red" />,
  idle: <IndicatorCheckmark color="gray" />,
};

export default function Checklist({ items }) {
  const checklist = items.map((item) => {
    const status = item.status.toLowerCase();

    return (
      <CheckListItem key={item.label} label={item.label} data-testid={`checklist-item-${status}`}>
        {indicatorList[status]}
      </CheckListItem>
    );
  });

  return <div className="inline-block">{checklist}</div>;
}

Checklist.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      status: PropTypes.oneOf(['done', 'loading', 'warn', 'error']),
    })
  ),
};
