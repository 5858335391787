import classNames from 'classnames';
import React from 'react';

export default function Quote({ className, children }) {
  return (
    <div
      className={classNames(
        'text-sm text-justify italic text-gray-500 border-l-4 pl-3 border-l-amber-300',
        className
      )}
    >
      {children}
    </div>
  );
}
