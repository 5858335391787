import React from 'react';
import PropTypes from 'prop-types';

const Error = ({ message, show = false }) => {
  return show ? (
    <div
      className={'border-0 border-l-3 border-solid border-error text-error pl-1 mt-1 text-xs'}
      data-testid="inline-error"
    >
      {message}
    </div>
  ) : null;
};

Error.propTypes = {
  message: PropTypes.string,
  show: PropTypes.bool,
};

export default Error;
