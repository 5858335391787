import React from 'react';

import InputField from 'Core/components/FormElements/InputField';
import { useTranslation } from 'react-i18next';
export default function SMSOption({ form, errors }) {
  const { register } = form;
  const { t } = useTranslation();
  return (
    <InputField block register={register} errors={errors} label={t('common.phone')} name="phone" />
  );
}
