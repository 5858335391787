import React from 'react';
import PropTypes from 'prop-types';

const DownPaymentItem = ({ label, value }) => {
  return (
    <div data-testid="down-payment-item" className="flex text-sm">
      <div className={`text-right px-1 w-3/4`}>{label}</div>
      <div className={`text-right px-1 w-1/4`}>{value}</div>
    </div>
  );
};

DownPaymentItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default DownPaymentItem;
