import React from 'react';
import { useTranslation } from 'react-i18next';
import { useScrollTop } from 'Core/hooks/useScrollTop';
import { Alert, AlertError } from 'Core/components/Alert';
import Checklist from 'Modules/creditDebit/components/Checklist';
import { PrimaryButton } from 'Core/components/Button';
import Page from 'Core/components/Page';
import { useNavigate } from 'react-location';
import { paymentOptionsPath } from 'Modules/creditDebit/routes';
import OrderData from 'Core/components/Data/OrderData';

const MixedError = () => {
  const navigate = useNavigate();
  useScrollTop();
  const { t } = useTranslation();

  const checklistItems = [
    { label: t('mixed.financingApproved.checklist.downPayment'), status: 'done' },
    { label: t('page.financing.checklist.approved'), status: 'done' },
    { label: t('page.financing.checklist.approvedSigned'), status: 'done' },
    { label: t('page.financing.checklist.funded'), status: 'error' },
  ];

  return (
    <OrderData>
      <Page title={t('page.mixed.error.header')}>
        <AlertError>{t('page.mixed.error.infobox')}</AlertError>

        <Alert>
          <p>{t('page.mixed.error.intro')}</p>

          <div className={`text-center`}>
            <Checklist items={checklistItems} />
          </div>

          <div className={`py-2 text-center`}>
            <PrimaryButton onClick={() => navigate({ to: paymentOptionsPath, search: true })}>
              {t('common.useAnotherPaymentOption')}
            </PrimaryButton>
          </div>

          <hr />
        </Alert>
      </Page>
    </OrderData>
  );
};

export default MixedError;
