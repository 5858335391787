import React from 'react';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useScrollTop } from 'Core/hooks/useScrollTop';
import constants from 'Core/config/constants';
import Footer from 'Core/components/Footer';
import { Alert } from 'Core/components/Alert';
import OptionsList from 'Core/components/OptionsList';
import { PaymentOption } from 'Core/components/Options';
import Loader from 'Core/components/Loader';
import CreditCardLogos from 'Core/components/CreditCardLogos';
import allegroLogo from 'Images/allegro-logo.png';
import useAlertStore, { clearAlertSelector, setErrorAlertSelector } from 'Core/stores/alert';
import usePaymentStore from 'Core/stores/payment';
import shallow from 'zustand/shallow';
import useGetPaymentSummary from 'Core/hooks/queries/payment/useGetPaymentSummary';
import usePageStore from 'Core/stores/page';
import useGetPaymentOptions from 'Core/hooks/queries/payment/useGetPaymentOptions';
import usePutPaymentOption from 'Core/hooks/queries/payment/usePutPaymentOption';
import usePostCaptureAvailableCredit from 'Core/hooks/queries/payment/usePostCaptureAvailableCredit';
import useOrder from 'Core/hooks/queries/order/useOrder';
import cacheKeys from 'Core/hooks/queries/cacheKeys';
import Page from 'Core/components/Page';
import { useShowSidebar } from 'Core/hooks/useShowSidebar';
import ShoppingCart from 'Core/components/ShoppingCart';
import { Column, Row } from 'Core/components/Layout';
import {
  creditDebitCheckoutPath,
  dashboardPath,
  financingPath,
  mixedAvailableCreditPath,
  mixedDownPaymentCheckoutPath,
  mixedFinancingPath,
  paymentOptionsPath,
} from 'Modules/creditDebit/routes';
import { useNavigate } from 'react-location';

function getEnabledPaymentOptions(statusList, paymentOptionList) {
  return statusList
    .map((status) => {
      const paymentOption =
        paymentOptionList.filter((item) => item.option === status.option).pop() || {};

      return {
        ...paymentOption,
        ...status,
        disabled: !!status.message,
      };
    })
    .filter(({ enabled, message }) => enabled || message);
}

const paymentSelector = ({ selectedOption, setPaymentState }) => ({
  selectedOption,
  setPaymentState,
});

const checkDepositCoversDownPayment = ({ amountAlreadyPaid, downPayment, payments }) => {
  const hasDeposit =
    payments.length && payments.find((payment) => payment.purpose.toLowerCase() === 'deposit');
  return hasDeposit && amountAlreadyPaid >= downPayment;
};

export default function PaymentOptions() {
  useScrollTop();
  const shouldShowSidebar = useShowSidebar();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const payment = usePaymentStore(paymentSelector, shallow);
  const clearAlert = useAlertStore(clearAlertSelector);
  const setErrorAlert = useAlertStore(setErrorAlertSelector);
  const isOfficeUser = usePageStore((state) => state.isOfficeUser);
  const navigate = useNavigate();

  // server state
  const { data: order } = useOrder();
  const { data: paymentSummary, isLoading: isPaymentSummaryLoading } = useGetPaymentSummary();
  const { data: paymentOptions, isLoading: isPaymentOptionsLoading } = useGetPaymentOptions();
  const {
    mutateAsync: setPaymentOption,
    isSuccess: isSetPaymentOptionSuccess,
    isLoading: isSetPaymentOptionLoading,
  } = usePutPaymentOption();
  const { mutateAsync: chargeAvailableCredit, isLoading: isPostCaptureAvailableCreditLoading } =
    usePostCaptureAvailableCredit();

  if (isPaymentSummaryLoading || isPaymentOptionsLoading) {
    return <Loader message={t('status.loading.paymentOptions')} />;
  }

  if (
    isSetPaymentOptionLoading ||
    isSetPaymentOptionSuccess ||
    isPostCaptureAvailableCreditLoading
  ) {
    return <Loader message={t('status.loading.savingPaymentOption')} />;
  }

  if (!order && !paymentSummary && !paymentOptions) {
    return null;
  }

  const onSelectPaymentOption = (option) => {
    clearAlert();
    payment.setPaymentState({
      selectedOption: option,
    });
  };

  const gotoPrevPage = () => {
    clearAlert();
    const route = isOfficeUser ? dashboardPath : paymentOptionsPath;
    navigate({ to: route, search: true });
  };

  const gotoNextPage = async () => {
    clearAlert();
    if (!payment.selectedOption) {
      setErrorAlert(t('status.error.noPaymentOptionSelected'));
      return;
    }

    // skip checkout and jump directly to the financing part
    if (
      payment.selectedOption === constants.paymentOptions.MIXED &&
      order.status === constants.orderStatus.IN_PROGRESS
    ) {
      return navigate({ to: mixedFinancingPath, search: true });
    }

    await setPaymentOption(payment.selectedOption);
    const updatedPaymentSummary = queryClient.getQueryData(cacheKeys.paymentSummary);
    const hasAvailableCredit = checkDepositCoversDownPayment(updatedPaymentSummary);

    // when the user has paid a deposit that is greater than or equal to the down payment amount,
    // try to capture the deposit, redirect and inform the user that we captured the deposit as a
    // down payment
    if (payment.selectedOption === constants.paymentOptions.MIXED && hasAvailableCredit) {
      await chargeAvailableCredit();
      return navigate({ to: mixedAvailableCreditPath, search: true });
    }

    const targets = {
      'credit-debit': creditDebitCheckoutPath,
      financing: financingPath,
      mixed: mixedDownPaymentCheckoutPath,
    };

    navigate({ to: targets[payment.selectedOption], search: true });
  };

  const showAvailableCreditNote = checkDepositCoversDownPayment(paymentSummary);
  const paymentOptionConfig = [
    {
      option: constants.paymentOptions.CREDIT_DEBIT,
      image: <CreditCardLogos size="small" />,
      label: t('page.paymentOptions.creditDebitTitle'),
      description: t('page.paymentOptions.creditDebitDescription'),
    },
    {
      option: constants.paymentOptions.FINANCING,
      label: t('page.paymentOptions.financingTitle'),
      image: <img src={allegroLogo} alt={t('page.paymentOptions.financingTitle')} />,
      description: (
        <React.Fragment>
          {t('page.paymentOptions.financingDescription')}
          <div>{t('page.paymentOptions.financingNote')}</div>
        </React.Fragment>
      ),
    },
    {
      option: constants.paymentOptions.MIXED,
      label: t('page.paymentOptions.mixedTitle'),
      image: (
        <CreditCardLogos size="small">
          <img src={allegroLogo} alt={t('page.paymentOptions.financingTitle')} />
        </CreditCardLogos>
      ),
      description: showAvailableCreditNote ? (
        <React.Fragment>
          {t('page.paymentOptions.mixedDescription')}
          <div className={`mt-2 p-2 bg-yellow-100 border border-solid border-yellow-200 rounded`}>
            {t('page.paymentOptions.availableCreditNote')}
          </div>
        </React.Fragment>
      ) : (
        t('page.paymentOptions.mixedDescription')
      ),
    },
  ];

  const enabledPaymentOptions = getEnabledPaymentOptions(paymentOptions, paymentOptionConfig);

  return (
    <Page title={t('page.paymentOptions.header')} stepProgressProps={{ type: 'n/a' }} withSidebar>
      <Alert data-testid="payment-options-info">{t('page.paymentOptions.infobox')}</Alert>
      <Row>
        <Column>
          <OptionsList
            options={enabledPaymentOptions}
            optionComponent={PaymentOption}
            compareKey="option"
            selectedOption={payment.selectedOption}
            onSelect={onSelectPaymentOption}
            data-testid="payment-option"
          />
        </Column>
      </Row>

      {!shouldShowSidebar && <ShoppingCart withTitle />}

      <Footer
        hasPrev={isOfficeUser}
        labelPrev={t('common.back')}
        labelNext={t('common.continue')}
        onPrev={gotoPrevPage}
        onNext={gotoNextPage}
      />
    </Page>
  );
}
