import React from 'react';

export const StyledLabel = ({ children, ...rest }) => (
  <label className="radio-option radio-vertical h-full !rounded-lg" {...rest}>
    {children}
  </label>
);

export const StyledCheckbox = ({ children }) => (
  <span className="radio-option-checkbox">{children}</span>
);

export const StyledBody = ({ children }) => (
  <span className={'radio-option-body h-full !p-0 flex-col rounded-lg'}>{children}</span>
);

export const StyledButton = ({ children }) => (
  <button type="button" className="rounded-full border-primary bg-none">
    {children}
  </button>
);

export const StyledInput = (props) => <input {...props} className={'radio-option-input'} />;
