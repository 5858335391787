import React from 'react';
import { Controller } from 'react-hook-form';
import { add, formatISO, parse } from 'date-fns';
import InputField from 'Core/components/FormElements/InputField';
import Page from 'Core/components/Page';
import { PrimaryButton } from 'Core/components/Button';
import { Trans, useTranslation } from 'react-i18next';
import Card from 'Core/components/FormElements/Card';
import { Column, Row } from 'Core/components/Layout';
import Tabset from 'Core/components/Tabset/Tabset';
import Tooltip from 'Core/components/Tooltip';
import { useScrollBottom } from 'Core/hooks/useScrollBottom';

import Loader from 'Core/components/Loader';
import CalendarInputField from 'Core/components/FormElements/CalendarInputField';
import useAlertStore, { clearAlertSelector, setErrorAlertSelector } from 'Core/stores/alert';
import { formatCurrency } from 'Core/utils/numberFormat';
import { getAppConfig } from 'Core/config/appConfig';
import { financingTabNames } from 'Modules/financing/config/financingTabNames';

import useSelectOfferForm from 'Modules/financing/hooks/useSelectOfferForm';
import { useGetPaymentInfo } from 'Core/hooks/platform/useGetPaymentInfo';
import useUpdateOffer from 'Modules/financing/hooks/useUpdateOffer';
import useGetApplication from 'Modules/financing/hooks/useGetApplication';
import { useGetNextStep } from 'Modules/financing/hooks/useGetNextStep';

import cacheKeys from 'Core/hooks/queries/cacheKeys';
import { useQueryClient } from 'react-query';
import FinalizerOffer from 'Modules/financing/FinalizeOffer';

import UpdateAchModal from 'Modules/financing/components/UpdateAchModal';

import useTabStore from 'Core/stores/tabs';
import SelectOffer from 'Modules/financing/SelectOffer';

const FinancingPreparation = ({ application, routes }) => {
  document.title = 'Payment Portal - Prepare Application';
  const { dateFormat } = getAppConfig();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const scroll = useScrollBottom();
  const setErrorAlert = useAlertStore(setErrorAlertSelector);
  const clearAlert = useAlertStore(clearAlertSelector);

  const DEFAULT_TAB_CONFIG = [
    {
      id: financingTabNames.SELECT_OFFER,
      label: t('financing.nextgen.setOffer.headline-1'),
      pageHeader: t('page.selectFinancingOffer.header'),
      isActive: true,
      isDisabled: false,
    },
    {
      id: financingTabNames.DOWN_PAYMENT_AND_DELIVERY,
      label: t('financing.nextgen.setOffer.headline-2'),
      pageHeader: t('page.selectFinancingOffer.header'),
      isActive: false,
      isDisabled: true,
    },
    {
      id: financingTabNames.FINALIZE_OFFER,
      label: t('financing.nextgen.setOffer.headline-3'),
      pageHeader: t('page.completeFinancing.header'),
      isActive: false,
      isDisabled: false,
    },
  ];

  const [tabConfig, setTabConfig] = React.useState(DEFAULT_TAB_CONFIG);

  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedOffer, setSelectedOffer] = React.useState({
    id: '',
    amount_min: 0,
    require_downpayment_percent: 0,
    type: '',
  });
  const [requestId, setRequestId] = React.useState('');
  const [enableSignContract, setEnableSignContract] = React.useState(false);
  const activeTab = useTabStore((state) => state.activeTab);
  const setActiveTab = useTabStore((state) => state.setActiveTab);
  const [activeTabId, setActiveTabId] = React.useState(activeTab || financingTabNames.SELECT_OFFER);
  const [refetchInterval, setRefetchInterval] = React.useState(false);
  const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = React.useState(false);
  const [isUpdateAchModalOpen, setIsUpdateAchModalOpen] = React.useState(false);
  const [isUpdateButtonPressed, setIsUpdateButtonPressed] = React.useState(false);
  const { mutateAsync: updateOffer, isFetching: isLoadingUpdateOffer } = useUpdateOffer();
  const { data: { amount_to_pay = 0 } = {} } = useGetPaymentInfo();
  const {
    data: getAppData,
    isLoading: isGetAppLoading,
    isFetching: isGetAppFetching,
  } = useGetApplication(requestId);

  const { data: getNextStepData } = useGetNextStep(refetchInterval, refetchInterval);

  const latestApp = getNextStepData || getAppData || application || {};
  const {
    next_action,
    payload: {
      offers,
      selected_offer_id,
      already_paid_amount = 0,
      additional_payment = 0,
      minimum_additional_payment = 0,
      links: { app: appLink } = {},
    } = {},
  } = latestApp;

  const priceInfo = latestApp?.payload?.offer_term?.price;
  const today = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });
  const [{ delivery_date = today } = {}] = priceInfo?.products
    ? JSON.parse(priceInfo?.products)
    : [];
  const minimumDownPayment = Math.max(
    amount_to_pay * selectedOffer.require_downpayment_percent,
    minimum_additional_payment,
    already_paid_amount
  );
  const maximumDownPayment = amount_to_pay - selectedOffer.amount_min;

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    watch,
    setValue,
    control,
    reset,
  } = useSelectOfferForm({
    minimumDownPayment,
    maximumDownPayment,
    dateFormat,
  });

  const setDownPaymentLabel = (minAmount, maxAmount) => {
    const downPaymentMinLabel = formatCurrency(minAmount, { withDecimals: false });
    const downPaymentMaxLabel = formatCurrency(maxAmount, { withDecimals: false });
    return selectedOffer.id ? `Down Payment (${downPaymentMinLabel} - ${downPaymentMaxLabel})` : '';
  };

  const downPaymentLabel = setDownPaymentLabel(minimumDownPayment, maximumDownPayment);

  const inputDownPayment = watch('downPayment') || 0;
  const calculatedAmountToCollect = inputDownPayment - already_paid_amount;

  const openAchWindow = () => {
    if (appLink) {
      window.open(appLink, '_target');
    }
  };
  const navigateToFinalizeTab = () => {
    setTabConfig((currentTabConfig) => {
      return currentTabConfig.map((tab) => {
        tab.isActive = tab.id === financingTabNames.FINALIZE_OFFER;
        setActiveTabId(financingTabNames.FINALIZE_OFFER);
        setEnableSignContract(true);
        return tab;
      });
    });
  };

  const handleAchConfirm = () => {
    navigateToFinalizeTab();
    setIsUpdateAchModalOpen(false);
    openAchWindow();
  };

  const handleAchSkipped = () => {
    navigateToFinalizeTab();
    setIsUpdateAchModalOpen(false);
  };

  React.useEffect(() => {
    setTabConfig((currentTabConfig) => {
      return currentTabConfig.map((tab) => {
        if (tab.id === financingTabNames.FINALIZE_OFFER) {
          tab.isDisabled = isDirty || next_action === financingTabNames.SELECT_OFFER;
        }
        return tab;
      });
    });
    if (next_action === 'SIGN_CONTRACT') {
      setRefetchInterval(false);
      queryClient.invalidateQueries(cacheKeys.transactionHistory);
    }
  }, [isDirty, next_action, queryClient]);

  React.useEffect(() => {
    setTabConfig((currentTabConfig) => {
      return currentTabConfig.map((tab) => {
        if (tab.id === financingTabNames.DOWN_PAYMENT_AND_DELIVERY) {
          tab.isDisabled =
            (!enableSignContract && !selectedOffer.id) || next_action === 'SIGN_CONTRACT';
          if ((!enableSignContract && !selectedOffer.id) || next_action === 'SIGN_CONTRACT') {
            setIsUpdateButtonDisabled(true);
          } else {
            setIsUpdateButtonDisabled(false);
          }
        }
        return tab;
      });
    });
  }, [enableSignContract, selectedOffer.id, next_action]);

  React.useEffect(() => {
    setValue('deliveryDate', delivery_date);
    setValue('downPayment', Math.max(additional_payment, minimumDownPayment));
  }, [additional_payment, delivery_date, minimumDownPayment, setValue, selectedOffer.id]);

  React.useEffect(() => {
    if (!isGetAppFetching) {
      switch (next_action) {
        case financingTabNames.SELECT_OFFER:
          break;
        case 'UPDATE_OFFER':
          setEnableSignContract(true);
          if (isUpdateButtonPressed) {
            if (priceInfo?.payment_method === '') {
              setIsUpdateAchModalOpen(true);
            } else {
              navigateToFinalizeTab();
              setIsUpdateAchModalOpen(false);
            }
            setIsUpdateButtonPressed(false);
          } else {
            navigateToFinalizeTab();
            setIsUpdateAchModalOpen(false);
          }
          break;
        case 'SIGN_CONTRACT':
          setTabConfig((currentTabConfig) => {
            return currentTabConfig.map((tab) => {
              tab.isDisabled = tab.id !== financingTabNames.FINALIZE_OFFER;
              tab.isActive = tab.id === financingTabNames.FINALIZE_OFFER;
              setActiveTabId(financingTabNames.FINALIZE_OFFER);
              setEnableSignContract(true);
              return tab;
            });
          });
          break;
        default:
          break;
      }
    }
  }, [next_action, isGetAppFetching, priceInfo?.payment_method]);

  const handleTabChange = (tabId) => {
    setTabConfig((currentTabConfig) => {
      return currentTabConfig.map((tab) => ({ ...tab, isActive: tabId === tab.id }));
    });
    setActiveTabId(tabId);
  };

  const onFocus = (event) => event.target.select();

  const onSubmit = async (values) => {
    scroll.bottom();
    setIsUpdateButtonPressed(true);
    setIsUpdateButtonDisabled(true);
    setActiveTab(activeTabId);
    setRequestId('');
    try {
      const result = await updateOffer({
        offer_id: selectedOffer.id,
        auto_pay: 'false',
        default_waived_interest: 'false',
        payment_date: 22,
        additional_payment: Number(watch('downPayment')) || 0,
        financing_down_payment: calculatedAmountToCollect,
        products: [
          {
            delivery_date: formatISO(parse(values.deliveryDate, dateFormat, new Date()), {
              representation: 'date',
            }),
            type: 'Medical Product',
            description: 'Hearing Aids',
            serial_number: null,
          },
        ],
      });
      setIsUpdateButtonDisabled(false);

      setRequestId(result.headers.request_id);
      queryClient.invalidateQueries(cacheKeys.financingPlatformApplication);

      reset(values);
      clearAlert();
    } catch (err) {
      setErrorAlert(err.message);
      setIsUpdateButtonDisabled(false);
    }
  };

  if (isLoading) {
    return <Loader message={t('status.loading.initializingFinancing')} />;
  }
  const finalizeProps = {
    application: latestApp,
    routes,
    enableSignContract,
    watch,
    setIsLoading,
    setRefetchInterval,
  };
  const selectOfferProps = {
    offers,
    selectedOffer,
    setSelectedOffer,
    enableSignContract,
    handleTabChange,
    amount_to_pay,
    already_paid_amount,
    routes,
    selected_offer_id,
  };

  return (
    <Page
      title={
        tabConfig.find((tab) => tab.id === activeTabId)?.pageHeader ||
        t('page.completeFinancing.header')
      }
      withStepProgress={false}
      stickyFooter
    >
      {(isLoadingUpdateOffer || isGetAppLoading || isGetAppFetching) && (
        <Loader message={t('allegro.updateApplication.loaderText')} />
      )}
      <Card className="bg-slate-300">
        <Row className="flex-wrap content-center">
          <Column className="md:!flex-col-8 mb-0">
            <h4 className="!mb-0">{t('payment.totalAmountLabel')}</h4>
          </Column>
          <Column className="md:!flex-col-4 text-right mb-0 pr-5 font-bold text-md">
            <Trans
              i18nKey={`{{amount}}`}
              values={{ amount: formatCurrency(amount_to_pay, { withDecimals: true }) }}
            />
          </Column>
        </Row>
      </Card>

      <Card className="bg-stone-100 !pt-0">
        <Tabset tabConfig={tabConfig} onTabChange={handleTabChange} />

        <div>
          {activeTabId === financingTabNames.SELECT_OFFER && (
            <SelectOffer {...selectOfferProps}></SelectOffer>
          )}

          {activeTabId === financingTabNames.DOWN_PAYMENT_AND_DELIVERY && (
            <form name="selectOfferForm" onSubmit={handleSubmit(onSubmit)}>
              <h3 className="!mt-4">{t('common.updateDownPayment')}</h3>

              <Card>
                <Row>
                  <Column className="md:!flex-col-4">
                    <div className="flex items-center">
                      <div className="mr-2" data-testid="value-available-credit">
                        {t('common.availableCredit')}
                      </div>
                      <Tooltip
                        content={t('common.availableCreditTooltip')}
                        data-testid="tooltip-available-credit"
                      ></Tooltip>
                    </div>

                    <div className="mt-1 text-lg font-semibold">
                      {formatCurrency(already_paid_amount)}
                    </div>
                  </Column>
                  <Column className="md:!flex-col-6 lg:!flex-col-4">
                    <InputField
                      register={register}
                      label={downPaymentLabel}
                      data-testid="input-down-payment"
                      prependText="$"
                      name="downPayment"
                      type="number"
                      disabled={!selectedOffer.id}
                      errors={errors}
                      onFocus={onFocus}
                    />
                  </Column>
                </Row>
              </Card>

              <h3 className="!mt-4">{t('common.updateDeliveryDate')}</h3>

              <Card>
                <Row className="justify-between items-end">
                  <Column className="md:!flex-col-4">
                    <Controller
                      name="deliveryDate"
                      control={control}
                      rules={{ required: t('common.requiredFieldDeliveryDate') }}
                      render={({ field: { onChange, onBlur, name, value } }) => (
                        <CalendarInputField
                          label={t('common.deliveryDate')}
                          name={name}
                          value={value}
                          onBlur={onBlur}
                          data-testid="input-delivery-date"
                          onChange={onChange}
                          dayPickerProps={{
                            disabledDays: {
                              before: new Date(),
                              after: add(new Date(), { days: 60 }),
                            },
                          }}
                          errors={errors}
                        />
                      )}
                    />
                  </Column>
                </Row>
              </Card>
              <UpdateAchModal
                isModalOpen={isUpdateAchModalOpen}
                onModalConfirm={handleAchConfirm}
                onModalClose={handleAchSkipped}
              ></UpdateAchModal>
              <Row className="flex justify-end mt-4">
                <Column className="md:!flex-col-4 !flex !items-end">
                  <PrimaryButton
                    block
                    type="submit"
                    data-testid="update-offer"
                    disabled={!selectedOffer.id || isUpdateButtonDisabled}
                  >
                    {t('common.updateOffer')}
                  </PrimaryButton>
                </Column>
              </Row>
            </form>
          )}
          {activeTabId === financingTabNames.FINALIZE_OFFER && (
            <FinalizerOffer {...finalizeProps} />
          )}
        </div>
      </Card>
    </Page>
  );
};

export default FinancingPreparation;
