import React from 'react';
import CreditCardLogos, { getCard } from 'Core/components/CreditCardLogos';

const StarIcon = ({ isActive, onClick }) => {
  const color = isActive ? `text-yellow-400` : `text-gray-300`;
  const fill = isActive ? 'currentColor' : 'none';

  return (
    <span data-testid="mark-preferred" className={color} data-active={isActive}>
      <svg
        onClick={onClick}
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6 cursor-pointer"
        fill={fill}
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
        />
      </svg>
    </span>
  );
};

/*
const TrashIcon = ({ onClick }) => {
  return (
    <svg
      data-testid="delete-card"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6 cursor-pointer"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
      />
    </svg>
  );
};
*/

const PaymentMethod = ({ paymentMethod, onMarkPreferred }) => {
  const {
    paymentMethodId,
    card: { last4, brand },
    preferred,
  } = paymentMethod;
  const { name } = getCard(brand);

  const togglePreferredState = () => {
    onMarkPreferred({ paymentMethodId, preferred: !preferred });
  };

  /*
  const handleDelete = () => {
    onDelete(paymentMethodId);
  };
  */

  return (
    <div
      className="bg-white border border-solid border-gray-300 rounded mb-4 p-3 flex items-center shadow"
      data-testid="payment-method"
    >
      <StarIcon isActive={preferred} onClick={togglePreferredState} />
      <div className="flex items-center flex-1 ml-4 font-bold">
        <CreditCardLogos brand={brand} size="small" className="mr-2" />
        <div className="flex-1">{name}</div>
        <div>**** {last4}</div>
      </div>
      {/*<TrashIcon onClick={handleDelete} />*/}
    </div>
  );
};

export default PaymentMethod;
