import React from 'react';
import { useTranslation } from 'react-i18next';
import { useScrollTop } from 'Core/hooks/useScrollTop';
import { AlertSuccess } from 'Core/components/Alert';
import Card from 'Core/components/FormElements/Card';
import OrderReceipts from 'Core/components/Receipts/OrderReceipts';
import Page from 'Core/components/Page';
import OrderData from 'Core/components/Data/OrderData';

export default function Success() {
  useScrollTop();
  const { t } = useTranslation();

  return (
    <OrderData>
      <Page title={t(`page.creditDebit.success.header`)}>
        <AlertSuccess>{t('page.creditDebit.success.infobox')}</AlertSuccess>

        <Card className="justify-center">
          <p className="text-center">{t('page.creditDebit.success.intro')}</p>

          <OrderReceipts title={t('page.creditDebit.success.clickToViewReceipt')} />

          <hr />
        </Card>
      </Page>
    </OrderData>
  );
}
