import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { parseISO, formatISO } from 'date-fns';
import { getAppConfig } from 'Core/config/appConfig';
import { StyledFormElement } from 'Core/components/FormElements/formElements.styled';
import Error from 'Core/components/FormElements/Error';

const BirthDateInput = ({
  className,
  register: parentRegister,
  name,
  format,
  errors,
  onChange = () => {},
  padZeros,
  defaultValues = {},
}) => {
  const { t } = useTranslation();
  const { register, watch, setValue } = useForm({ defaultValues });
  const { dateFormat } = getAppConfig();
  const formatter = format || dateFormat;
  const { day, month, year } = watch();
  const error = errors[name]?.message;

  React.useEffect(() => {
    parentRegister(name);
  }, [parentRegister, name]);

  React.useEffect(() => {
    if (padZeros && !isNaN(day) && day !== '') {
      if (Number(day) > 0 && Number(day) < 10) {
        if (day.length === 1) {
          const modNum = Number(day).toString();
          setValue('day', '0' + modNum);
        }
      } else {
        const modNum = Number(day).toString();
        setValue('day', modNum);
      }
    }

    const dateObj = parseISO(`${year}-${month}-${day}`);

    try {
      onChange(formatISO(dateObj, { representation: 'date' }));
    } catch {
      onChange(dateObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [day, month, year, formatter]);

  React.useEffect(() => {
    if (defaultValues) {
      Object.keys(defaultValues).forEach((field) => setValue(field, defaultValues[field]));
    }
  }, [defaultValues, setValue]);

  const items = [
    { label: 'January', value: '01' },
    { label: 'February', value: '02' },
    { label: 'March', value: '03' },
    { label: 'April', value: '04' },
    { label: 'May', value: '05' },
    { label: 'June', value: '06' },
    { label: 'July', value: '07' },
    { label: 'August', value: '08' },
    { label: 'September', value: '09' },
    { label: 'October', value: '10' },
    { label: 'November', value: '11' },
    { label: 'December', value: '12' },
  ];

  return (
    <StyledFormElement className={className}>
      <label>{t('common.dob')}</label>
      <div className="flex flex-wrap gap-x-2 content-start">
        <select
          className={'[width:55%_!important]'}
          name="month"
          data-testid={`${name}-month`}
          {...register('month')}
        >
          {items.map(({ label: itemLabel, value: itemValue }) => (
            <option key={itemValue} value={itemValue}>
              {itemLabel}
            </option>
          ))}
        </select>
        <input
          className={'flex-1 text-right'}
          name="day"
          type="number"
          min="1"
          max="31"
          placeholder="DD"
          data-testid={`${name}-day`}
          {...register('day')}
        />
        <input
          className={'flex-1 text-right'}
          name="year"
          type="number"
          placeholder="YYYY"
          data-testid={`${name}-year`}
          {...register('year')}
        />
      </div>
      <Error message={error} show={Boolean(error)} />
    </StyledFormElement>
  );
};

export default BirthDateInput;
