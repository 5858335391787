import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Alert, AlertSuccess } from 'Core/components/Alert';
import Checklist from 'Modules/creditDebit/components/Checklist';
import Card from 'Core/components/FormElements/Card';
import { useGetTransactionHistory } from 'Core/hooks/platform/useGetTransactionHistory';
import Page from 'Core/components/Page';
import Attachment from 'Core/components/Receipts/Attachment';
import { Column, Row } from 'Core/components/Layout';
import { useGetNextStep } from '../hooks/useGetNextStep';
import { PrimaryButton } from 'Core/components/Button';
import { generateURL, getUrlParam } from 'Core/utils/utils';
import { useQueryClient } from 'react-query';
import cacheKeys from 'Core/hooks/queries/cacheKeys';
import { mixedDownPaymentCheckoutPath } from 'Modules/financing/routes';
import Icon from 'Core/components/Icon/Icon';
import { formatCurrency } from 'Core/utils/numberFormat';
import useGetContract from '../hooks/useGetContract';
import { features, postLogMessage, actions } from 'Core/utils/logger';

const FinancingSuccess = () => {
  document.title = 'Payment Portal - Success';
  const { t } = useTranslation();
  const routes = { mixed: mixedDownPaymentCheckoutPath };
  const { data: transactions } = useGetTransactionHistory();
  const checklistItems = [
    { label: t('page.financing.checklist.approved'), status: 'done' },
    { label: t('page.financing.checklist.approvedSigned'), status: 'done' },
    { label: t('page.financing.checklist.funded'), status: 'done' },
  ];
  const [isCollectPaymentLoading, setIsCollectPaymentLoading] = React.useState(false);
  const [refetchInterval, setRefetchInterval] = React.useState(false);
  const { data: application, isSuccess: isGetNextStepSuccess } = useGetNextStep(refetchInterval);
  const {
    next_action,
    external_reference_id = '',
    payload: { financing_down_payment = 0, payment_id = '', application_id = '' } = {},
  } = application;
  const { mutateAsync: getContract, data: pdfData } = useGetContract();
  React.useEffect(() => {
    if (isGetNextStepSuccess && payment_id && application_id && external_reference_id) {
      postLogMessage({
        feature: features.financingSignContract,
        action: actions.financingSuccessScreenDisplayed,
        payment_id,
        application_id,
        external_reference_id,
      });
    }
  }, [isGetNextStepSuccess]);
  const queryClient = useQueryClient();

  const onCollectPayment = () => {
    const payment_id = getUrlParam('payment-id');
    const web_code = getUrlParam('web-code');
    const url = generateURL({
      url: routes.mixed,
      params: { 'payment-id': payment_id, 'web-code': web_code },
    });
    setIsCollectPaymentLoading(true);
    setRefetchInterval(5000);
    queryClient.invalidateQueries(cacheKeys.financingNextStep);

    setTimeout(() => setIsCollectPaymentLoading(false), 5 * 60 * 1000);
    window.open(url, '_blank')?.focus();
  };

  React.useEffect(() => {
    if (next_action === 'DONE') {
      setRefetchInterval(false);
      queryClient.invalidateQueries(cacheKeys.transactionHistory);
    }
  }, [next_action, queryClient]);

  const downloadContract = async () => {
    const resp = await getContract();
    const uriContent = URL.createObjectURL(new Blob([resp]));
    const downloadLink = document.createElement('a');
    const fileName = 'contract.pdf';
    downloadLink.href = uriContent;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  return (
    <Page title={t('page.financing.header')} withStepProgress={false} stickyFooter>
      <AlertSuccess>{t('page.financing.financingFunded.infobox')}</AlertSuccess>

      <Card>
        <p>{t('page.financing.financingFunded.intro')}</p>

        <div className={`text-center`}>
          <Checklist items={checklistItems} />
        </div>
        {next_action === 'PENDING_DOWN_PAYMENT' && (
          <>
            <Alert
              // showIcon
              className="mt-8 mb-8 p-2"
              type="warningLight"
              title="correction required"
              data-testid="correction-required-message"
            >
              <Row className="justify-between items-center">
                <Column className="!flex-col-9 font-regular text-large mb-0">
                  <span>
                    <Trans
                      i18nKey="financing.downPayment.outstandingMessage"
                      values={{
                        amount: formatCurrency(financing_down_payment, { withDecimals: false }),
                      }}
                    />
                  </span>
                </Column>
                <Column className="!flex-col-3 text-right mb-0">
                  <PrimaryButton
                    data-testid="btn-verify"
                    block
                    onClick={onCollectPayment}
                    disabled={isCollectPaymentLoading}
                  >
                    {isCollectPaymentLoading && <Icon type="spinner" className="mr-4"></Icon>}
                    Collect Payment
                  </PrimaryButton>
                </Column>
              </Row>
            </Alert>
          </>
        )}

        <hr></hr>
        <h4 className="">Documents</h4>
        <Row className="flex justify-start gap-4 py-2">
          <Column className="!flex-1 !flex-grow-0">
            <Attachment
              type="button"
              onClick={downloadContract}
              disabled={Boolean(pdfData)}
              data-testid="contract-download"
            >
              <span>Financing Contract</span>
            </Attachment>
          </Column>
          {transactions && transactions.length > 0 && (
            <>
              {transactions.map(
                ({ payment_id: paymentId, receipt: { url = '' } = {} }, idx, arr) => {
                  return (
                    <Column key={paymentId} className="!flex-1 !flex-grow-0">
                      <Attachment url={url} data-testid={'receipt-download-' + idx}>
                        <span>Down Payment Receipt {arr.length > 1 ? idx + 1 : ''}</span>
                      </Attachment>
                    </Column>
                  );
                }
              )}
            </>
          )}
        </Row>
      </Card>
    </Page>
  );
};

export default FinancingSuccess;
