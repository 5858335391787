import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import useOrder from 'Core/hooks/queries/order/useOrder';
import cacheKeys from 'Core/hooks/queries/cacheKeys';
import { get } from 'Core/utils/api';
import useErrorStore, { setErrorActionSelector } from 'Core/stores/error';
import { useNavigate } from 'react-location';
import { errorPath } from 'Core/routes';

export default function (refetchInterval = false) {
  const { t } = useTranslation();
  const setError = useErrorStore(setErrorActionSelector);
  const { data: order } = useOrder();
  const navigate = useNavigate();

  return useQuery(
    [cacheKeys.financingApplicationDetails, order.orderId],
    () => get(`/api/application-details/${order.orderId}`),
    {
      enabled: Boolean(order),
      refetchInterval,
      onError(e) {
        const message = ` - ${e?.response?.data?.message}` || '';
        setError(t('status.error.getFinancingApplicationFailed') + message);
        navigate({ to: errorPath, search: true });
      },
    }
  );
}
