import React from 'react';
import classnames from 'classnames';

export default function Container({ className, children }) {
  return (
    <div className={classnames('mb-4 w-full xl:max-w-screen-xl mx-auto px-4', className)}>
      {children}
    </div>
  );
}
