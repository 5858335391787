import React from 'react';
import { Error } from 'Core/routes/Error';
import { CustomerJourney } from 'Core/routes/CustomerJourney';
import { Platform } from 'Core/routes/Platform';
import { NotFound } from 'Core/routes/NotFound';
import Teleaudiology from 'Core/routes/Teleaudiology';
import Collect from 'Core/routes/Collect';
import OrderData from 'Core/components/Data/OrderData';

export const errorPath = '/error';
export const journeyPath = '/journey';
export const teleaudiologyPath = '/teleaudiology';
export const collectPath = '/collect';
export const platformPath = '/platform';

export const routes = [
  {
    path: errorPath,
    element: <Error />,
  },
  {
    path: journeyPath,
    element: <CustomerJourney />,
  },
  {
    path: teleaudiologyPath,
    element: (
      <OrderData>
        <Teleaudiology />
      </OrderData>
    ),
  },
  {
    path: collectPath,
    element: (
      <OrderData>
        <Collect />
      </OrderData>
    ),
  },
  {
    path: platformPath,
    element: <Platform />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
];
