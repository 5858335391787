import React from 'react';
import { useTranslation } from 'react-i18next';
import { useScrollTop } from 'Core/hooks/useScrollTop';
import { Alert, AlertSuccess } from 'Core/components/Alert';
import Checklist from 'Modules/creditDebit/components/Checklist';

import Page from 'Core/components/Page';

const MixedSuccess = () => {
  useScrollTop();
  const { t } = useTranslation();

  const checklistItems = [
    { label: t('mixed.financingApproved.checklist.downPayment'), status: 'done' },
    { label: t('page.financing.checklist.approved'), status: 'done' },
    { label: t('page.financing.checklist.approvedSigned'), status: 'done' },
    { label: t('page.financing.checklist.funded'), status: 'done' },
  ];

  return (
    <Page title={t('page.mixed.success.header')} stickyFooter>
      <AlertSuccess>{t('page.mixed.success.infobox')}</AlertSuccess>

      <Alert>
        <p>{t('page.mixed.success.intro')}</p>

        <div className={`text-center`}>
          <Checklist items={checklistItems} />
        </div>

        {/* <OrderReceipts title={t('page.creditDebit.success.clickToViewReceipt')} /> */}

        <hr />
      </Alert>
    </Page>
  );
};

export default MixedSuccess;
