import { useTranslation } from 'react-i18next';
import { useScrollTop } from 'Core/hooks/useScrollTop';
import React from 'react';
import Page from 'Core/components/Page';
import Card from 'Core/components/FormElements/Card';
// import { PrimaryButton } from 'Core/components/Button';
import Row from 'Core/components/Layout/Row';
import Column from 'Core/components/Layout/Column';
export default function FinancingApproved() {
  document.title = 'Payment Portal - Application Approved';
  useScrollTop();

  const { t } = useTranslation();

  return (
    <Page title={t('page.financing.header')} withStepProgress={false} stickyFooter>
      <Card className="justify-center text-center">
        <Row className="justify-center">
          <h1 className="font-regular" data-testid="title-finance-approved">
            {t('common.newApplication')}
          </h1>
        </Row>
        <Column>
          <hr></hr>
        </Column>
        <Row className="justify-center">
          <Column className="text-center p-7 font-extralight text-2xl">
            <span className="mr-4">✅</span>
            <span data-testid="message-finance-approved">{t('financing.applicationApproved')}</span>
          </Column>
          <Column className="md:!flex-col-4 text-center text-gray-400">
            <span>{t('common.closeWindow')}</span>
          </Column>
        </Row>
      </Card>
    </Page>
  );
}
