import React from 'react';
import './Ribbon.css';

export default function Ribbon({ label }) {
  if (label) {
    return (
      <div className="c_ribbon">
        {label}
        <span className="flair-top-left"></span>
      </div>
    );
  }
  return <></>;
}
