import React from 'react';
import InputField from 'Core/components/FormElements/InputField';
import { useTranslation } from 'react-i18next';
import SelectMenu from 'Core/components/FormElements/SelectMenu';
import { AlertError, Alert } from 'Core/components/Alert';
import InfoBox from 'Core/components/InfoBox';
import { PrimaryButton, SecondaryOutlineButton } from 'Core/components/Button';
import { useScrollTop } from 'Core/hooks/useScrollTop';
import { usStates } from 'Modules/creditDebit/config/usStates';
import useCreateApplicationForm from './useCreateApplicationForm';
import Page from 'Core/components/Page';
import useCreateApplication from 'Modules/creditDebit/components/Financing/allegro/useCreateApplication';
import BirthDateInput from 'Core/components/FormElements/BirthDateInput';
import { Column, Row } from 'Core/components/Layout';
import { StyledFormSection } from 'Core/components/FormElements/formElements.styled';
import TermsInput from 'Core/components/FormElements/TermsInput';
import Loader from 'Core/components/Loader';
import useGetApplication from 'Modules/creditDebit/components/Financing/allegro/useGetApplication';
import { useNavigate } from 'react-location';

const dateFormat = 'yyyy-MM-dd';

const mapCustomerData = (customerData) => {
  return {
    ...customerData,
    phone: customerData.phoneNumber,
    annualIncome: (customerData.monthlyIncome * 12).toString(),
  };
};

const CreateApplication = ({ routes }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const scroll = useScrollTop();
  const { mutateAsync: createApplication, isLoading } = useCreateApplication();
  const { data: applicationDetails } = useGetApplication();
  const { customerInfo } = applicationDetails || {};
  const [dobDefaults, setDobDefaults] = React.useState({});
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitted },
  } = useCreateApplicationForm();

  React.useEffect(() => {
    if (customerInfo) {
      const customerData = mapCustomerData(customerInfo);
      Object.keys(customerData).forEach((field) => setValue(field, customerData[field]));

      const [year, month, day] = customerData.dateOfBirth.split('-');
      setDobDefaults({ day, month, year });
    }
  }, [customerInfo, setValue]);

  const hasFormErrors = Object.keys(errors).length > 0;

  const onSubmit = async (formData) => {
    scroll.top();
    await createApplication(formData);
  };

  return (
    <Page title={t('page.createFinancingApplication.header')} withStepProgress={false}>
      {isLoading && <Loader message={t('allegro.createApplication.loaderText')} />}

      <form name="allegroCreateApplication" onSubmit={handleSubmit(onSubmit)}>
        {hasFormErrors && <AlertError type="error">{t('status.error.checkErrors')}</AlertError>}

        <StyledFormSection>
          <Alert>{t('allegro.createApplication.enterLegalNames')}</Alert>

          <Row>
            <Column className="md:!flex-col-6">
              <InputField
                register={register}
                label={t('common.firstName')}
                name="firstName"
                errors={errors}
                defaultValue=""
              />
            </Column>

            <Column className="md:!flex-col-6">
              <InputField
                register={register}
                label={t('common.lastName')}
                name="lastName"
                errors={errors}
              />
            </Column>

            <Column className="md:!flex-col-3">
              <InputField
                register={register}
                label={t('common.street')}
                name="address"
                errors={errors}
              />
            </Column>

            <Column className="md:!flex-col-3">
              <InputField
                register={register}
                label={t('common.city')}
                name="city"
                errors={errors}
              />
            </Column>

            <Column className="md:!flex-col-3">
              <SelectMenu
                register={register}
                label={t('common.state')}
                name="state"
                items={usStates}
                errors={errors}
              />
            </Column>

            <Column className="md:!flex-col-3">
              <InputField
                register={register}
                label={t('common.postalCode')}
                name="zip"
                errors={errors}
              />
            </Column>
          </Row>

          <hr />

          <Row>
            <Column className="md:!flex-col-6">
              <InputField
                register={register}
                label={t('common.email')}
                name="email"
                errors={errors}
              />
            </Column>

            <Column className="md:!flex-col-6">
              <InputField
                register={register}
                label={t('common.phone')}
                name="phone"
                errors={errors}
              />
            </Column>
          </Row>

          <InfoBox title={t('common.readToCustomer.title')}>
            {t('allegro.createApplication.readToCustomer.note1')}
          </InfoBox>

          <Row>
            <Column className="md:!flex-col-6">
              <InputField
                register={register}
                label={t('common.ssn')}
                name="socialSecurityNumber"
                errors={errors}
              />
            </Column>

            <Column className="md:!flex-col-6">
              <BirthDateInput
                format={dateFormat}
                register={register}
                name="dateOfBirth"
                onChange={(value) => {
                  setValue('dateOfBirth', value, { shouldValidate: isSubmitted });
                }}
                defaultValues={dobDefaults}
                errors={errors}
              />
            </Column>
          </Row>

          <hr />

          <Row>
            <Column className="md:!flex-col-6">
              <InputField
                register={register}
                label={t('common.annualIncome')}
                name="annualIncome"
                type="number"
                errors={errors}
              />
            </Column>
          </Row>

          <InfoBox title={t('common.readToCustomer.title')}>
            {t('allegro.createApplication.readToCustomer.note2')}
          </InfoBox>

          <hr />

          <h3>{t('common.noticeToApplicants')}</h3>
          <InfoBox title={t('common.readToCustomer.title')}>
            {t('allegro.createApplication.readToCustomer.note3')}
          </InfoBox>

          <hr />

          <TermsInput name="terms" register={register} errors={errors}>
            <b>{t('allegro.createApplication.terms')}</b>
            <ol>
              <li>{t('allegro.createApplication.terms.note1')}</li>
              <li>{t('allegro.createApplication.terms.note2')}</li>
            </ol>
          </TermsInput>
        </StyledFormSection>

        <Row>
          <Column className="md:!flex-col-6">
            <SecondaryOutlineButton
              data-testid="cancel"
              onClick={() => navigate({ to: routes.paymentOptions, search: true, replace: true })}
            >
              {t('common.cancel')}
            </SecondaryOutlineButton>
          </Column>

          <Column className="md:!flex-col-6 !flex gap-1 !justify-end">
            <PrimaryButton type="submit" data-testid="submit-application">
              {t('common.submitApplication')}
            </PrimaryButton>
          </Column>
        </Row>
      </form>
    </Page>
  );
};

export default CreateApplication;
