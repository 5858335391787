import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { get } from 'Core/utils/api';
import useOrder from 'Core/hooks/queries/order/useOrder';
import cacheKeys from 'Core/hooks/queries/cacheKeys';
import useErrorStore, { setErrorActionSelector } from 'Core/stores/error';
import { errorPath } from 'Core/routes';
import { useNavigate } from 'react-location';

export default function useGetApplication() {
  const { t } = useTranslation();
  const setError = useErrorStore(setErrorActionSelector);
  const { data: order } = useOrder();
  const navigate = useNavigate();

  return useQuery(
    [cacheKeys.financingApplication, order.orderId],
    () => get(`/api/orders/${order.orderId}/financing/application`),
    {
      onError(e) {
        if (e?.response?.status !== 404) {
          const message = ` - ${e?.response?.data?.message}` || '';
          setError(t('status.error.getFinancingApplicationFailed') + message);
          navigate({ to: errorPath, search: true });
        }
      },
      retry: 1,
    }
  );
}
