import { useMutation } from 'react-query';

export default function usePostCreatePaymentMethods() {
  return useMutation(
    async ({ stripe, data }) => {
      const paymentMethods = data.map(async (card) => {
        if (card.paymentMethodId) return card;

        const result = await stripe.createPaymentMethod({
          type: 'card',
          card: card.stripeElement,
          billing_details: card.billingDetails,
        });

        return {
          amount: card.amount,
          cardId: card.cardId,
          paymentMethodId: result.paymentMethod.id,
        };
      });

      return await Promise.all(paymentMethods);
    },
    {
      throwOnError: true,
    }
  );
}
