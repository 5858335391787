import { useMutation, useQueryClient } from 'react-query';
import { post } from 'Core/utils/api';
import useOrder from 'Core/hooks/queries/order/useOrder';
import cacheKeys from 'Core/hooks/queries/cacheKeys';
import useAlertStore, { clearAlertSelector, setErrorAlertSelector } from 'Core/stores/alert';
import { useTranslation } from 'react-i18next';
import { features, postLogMessage } from 'Core/utils/logger';

function transformPayload({
  firstName,
  lastName,
  address,
  city,
  state,
  zip,
  email,
  phone: number,
  socialSecurityNumber,
  dateOfBirth,
  annualIncome,
}) {
  return {
    firstName,
    lastName,
    dateOfBirth,
    socialSecurityNumber,
    email,
    phones: [
      {
        number,
        type: 'mobile',
      },
    ],
    mailingAddress: {
      address,
      city,
      state,
      zip,
    },
    monthlyIncome: Math.floor(annualIncome / 12),
    agreeToTerms: true,
  };
}

export default function useCreateApplication() {
  const { t } = useTranslation();
  const setErrorAlert = useAlertStore(setErrorAlertSelector);
  const clearAlert = useAlertStore(clearAlertSelector);

  const { data: order } = useOrder();
  const queryClient = useQueryClient();

  return useMutation(
    (payload) =>
      post(`/api/orders/${order.orderId}/financing/application`, transformPayload(payload)),
    {
      throwOnError: true,
      retry: 2,
      onSuccess() {
        postLogMessage({
          feature: features.createAllegroApp,
          status: 'success',
        });

        clearAlert();
        return queryClient.invalidateQueries(cacheKeys.financingApplication);
      },
      onError(error) {
        setErrorAlert(
          t('status.error.createApplicationRequestFailed') + `(${error?.response?.data?.message})`
        );
      },
    }
  );
}
