import { useMutation, useQueryClient } from 'react-query';
import { post } from 'Core/utils/api';
import { features, postLogMessage } from 'Core/utils/logger';

export default function usePostChargeCards() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ receiptEmail, paymentMethods }) => {
      const { orderId } = queryClient.getQueryData('order');
      return post(`/api/orders/${orderId}/payments/checkout`, { receiptEmail, paymentMethods });
    },
    {
      onSuccess(data, variables) {
        const { optionSelected } = queryClient.getQueryData('order');

        if (optionSelected === 'credit-debit') {
          postLogMessage({
            amount: variables.paymentMethods.reduce((totalAmount, paymentMethod) => {
              return totalAmount + paymentMethod.amount;
            }, 0),
            feature: features.creditDebitCheckout,
            status: 'success',
          });
        }

        return Promise.resolve();
      },
      throwOnError: true,
    }
  );
}
