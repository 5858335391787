import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Page from 'Core/components/Page';
import { PaymentHistory } from 'Modules/directSales/components/PaymentHistory';
import { DirectSalesForm } from 'Modules/directSales/components/DirectSalesForm';
import { useGetPaymentHistory } from 'Core/hooks/queries/payment/useGetPaymentHistory';
import Loader from 'Core/components/Loader';
import { useGetPaymentOptions } from 'Modules/directSales/hooks/useGetPaymentOptions';
import Status from 'Core/components/Status';
import usePostCreatePaymentMethods from 'Core/hooks/queries/stripe/usePostCreatePaymentMethods';
import usePostChargeCard from 'Modules/directSales/hooks/usePostChargeCard';
import { useScrollTop } from 'Core/hooks/useScrollTop';
import { toast } from 'react-toastify';
import useAddPaymentMethod from 'Core/hooks/queries/payment/useAddPaymentMethod';
import useGetSavedPaymentMethods from 'Core/hooks/queries/payment/useGetSavedPaymentMethods';
import { features, postLogMessage } from 'Core/utils/logger';
import { useGetTokenInfo } from 'Core/hooks/useGetTokenInfo';
import useErrorStore, { setErrorActionSelector } from 'Core/stores/error';
import { journeys } from 'Core/config/journeyConfig';
import { useNavigate } from 'react-location';
import { errorPath } from 'Core/routes';

export default function DirectSales() {
  const scroll = useScrollTop();
  const { t } = useTranslation();
  const { data: paymentHistory } = useGetPaymentHistory();
  const { data: savedPaymentMethods } = useGetSavedPaymentMethods();
  const { data: paymentOptions } = useGetPaymentOptions();
  const [cardErrors, setCardErrors] = React.useState();
  const [status, setStatus] = React.useState({ type: '', text: '' });
  const { mutateAsync: createPaymentMethods } = usePostCreatePaymentMethods();
  const { mutateAsync: chargeCard } = usePostChargeCard();
  const { mutateAsync: addCard } = useAddPaymentMethod();
  const { data: tokenInfo } = useGetTokenInfo();
  const setError = useErrorStore(setErrorActionSelector);
  const navigate = useNavigate();

  useEffect(() => {
    if (tokenInfo && journeys.directSales !== tokenInfo.customerJourney) {
      setError(t('status.error.invalidCustomerJourney'));
      navigate({ to: errorPath, search: true });
    }
  }, [navigate, setError, t, tokenInfo]);

  async function onSubmit(formData, event) {
    scroll.top();
    let paymentMethods;
    setStatus({ type: 'loading', text: t('status.loading.submitPaymentInformation') });

    if (!formData.paymentMethodId) {
      const paymentMethodData = [
        {
          cardId: 'card0',
          amount: formData.amount,
          stripeElement: formData.stripeElement,
          billingDetails: {
            name: `${formData.firstName} ${formData.lastName}`,
            address: { postal_code: formData.postalCode },
          },
        },
      ];

      if (!formData.stripe) {
        return setStatus({ type: 'error', text: t('status.error.paymentGatewayInitialize') });
      }

      try {
        paymentMethods = await createPaymentMethods({
          stripe: formData.stripe,
          data: paymentMethodData,
        });
      } catch {
        setStatus({ type: 'error', text: t('status.error.failedToCreatePaymentMethods') });
        return;
      }
    }

    const paymentMethodId = formData.paymentMethodId || paymentMethods[0].paymentMethodId;

    try {
      await chargeCard({
        paymentMethods: [
          {
            amount: formData.amount,
            paymentMethodId,
            type: formData.type,
          },
        ],
      });

      await addCard({
        paymentMethodId,
        preferred: true,
      });

      //sale for this token is complete. lets log it
      const shouldLog = paymentHistory.receipts.length === 0 ? formData.type === 'capture' : true;
      if (shouldLog) {
        postLogMessage({
          feature: features.directSales,
          status: 'success',
        });
      }

      event.target.reset();
      setStatus({ type: 'success' });
      toast.success(t('status.success.paymentProcessed'));
    } catch (error) {
      const detailedErrorMessage =
        error?.response?.data?.message || t('status.error.failedToChargeCard');
      setStatus({ type: 'error', text: detailedErrorMessage });

      if (error?.response?.data?.hasOwnProperty('errors')) {
        setCardErrors(error.response.data.errors);
      }
    }
  }

  if (!paymentHistory || !savedPaymentMethods || !paymentOptions) {
    return <Loader message={t('status.loading')} />;
  }

  return (
    <Page title={t('page.directSales.header')} withStepProgress={false}>
      <Status {...status} />
      <PaymentHistory history={paymentHistory.receipts} />
      {paymentOptions.length > 0 ? (
        <DirectSalesForm
          paymentMethods={savedPaymentMethods}
          paymentOptions={paymentOptions}
          onSubmit={onSubmit}
          cardErrors={cardErrors}
        />
      ) : null}
    </Page>
  );
}
