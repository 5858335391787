import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from 'Core/components/Alert';
import Loader from 'Core/components/Loader';
import constants from 'Core/config/constants';
import useAlertStore, { setWarningAlertSelector } from 'Core/stores/alert';
import Page from 'Core/components/Page';
import MultiCardForm from 'Modules/creditDebit/components/MultiCardForm';
import useCardCheckoutPageData from 'Core/hooks/queries/useCardCheckoutPageData';
import { useScrollTop } from 'Core/hooks/useScrollTop';
import usePostCreatePaymentMethods from 'Core/hooks/queries/stripe/usePostCreatePaymentMethods';
import { useStripe } from '@stripe/react-stripe-js';
import usePostChargeCards from 'Core/hooks/queries/payment/usePostChargeCards';
import useDialogStore, { showDialogSelector } from 'Core/stores/dialog';
import { findByPropertyValue } from 'Core/utils/utils';
import Status from 'Core/components/Status';
import usePostCancelCardPayment from 'Core/hooks/queries/payment/usePostCancelCardPayment';
import { useNavigate } from 'react-location';
import { paymentOptionsPath } from 'Modules/creditDebit/routes';

const Checkout = ({ nextRoutes }) => {
  const scroll = useScrollTop();
  const stripe = useStripe();
  const [cardErrors, setCardErrors] = React.useState();
  const [status, setStatus] = React.useState({ type: '', text: '' });
  const [failedPaymentAttempts, setFailedPaymentAttempts] = React.useState(0);
  const showDialog = useDialogStore(showDialogSelector);
  const setWarningAlert = useAlertStore(setWarningAlertSelector);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutateAsync: createPaymentMethods } = usePostCreatePaymentMethods();
  const { mutateAsync: cancelCardPayment } = usePostCancelCardPayment();
  const { mutateAsync: chargeCards } = usePostChargeCards();
  const { data, isSuccess } = useCardCheckoutPageData();

  React.useEffect(() => {
    if (data?.paymentSummary) {
      if (data.paymentSummary.totalAmountToPay === 0)
        navigate({ to: nextRoutes.success, search: true });
    }
  }, [data]);

  if (!isSuccess) {
    return <Loader message={t('status.loading.shoppingCart')} />;
  }

  const { order, paymentMethods: savedPaymentMethods, paymentSummary } = data;

  async function onSubmit(formData) {
    scroll.top();
    let paymentMethods;

    setStatus({ type: 'loading', text: t('status.loading.submitPaymentInformation') });

    const paymentMethodData = formData.cards.map((card, index) => {
      const amount = index === 0 ? +card.amount + formData.decimals : +card.amount;
      if (card.paymentMethodId) {
        return {
          amount,
          paymentMethodId: card.paymentMethodId,
        };
      }

      return {
        amount,
        stripeElement: card.stripeElement,
        billingDetails: {
          name: `${card.firstName} ${card.lastName}`,
          address: { postal_code: card.postalCode },
        },
      };
    });

    try {
      paymentMethods = await createPaymentMethods({
        stripe,
        data: paymentMethodData,
      });
    } catch {
      return setStatus({ type: 'error', text: t('status.error.failedToCreatePaymentMethods') });
    }

    try {
      await chargeCards({ receiptEmail: formData.email, paymentMethods });
      navigate({ to: nextRoutes.success, search: true });
    } catch (error) {
      const detailedErrorMessage =
        error?.response?.data?.message || t('status.error.failedToChargeCard');
      setStatus({ type: 'error', text: detailedErrorMessage });

      const updatedFailedAttempts = failedPaymentAttempts + 1;
      setFailedPaymentAttempts(updatedFailedAttempts);
      if (updatedFailedAttempts >= constants.limit.FAILED_PAYMENT_ATTEMPTS) {
        navigate({ to: nextRoutes.error, search: true });
      }

      if (error.response.data.hasOwnProperty('errors')) {
        const errors = paymentMethods.map(({ paymentMethodId }) => {
          const cardError = findByPropertyValue(
            error.response.data.errors,
            'paymentMethodId',
            paymentMethodId
          );
          return cardError || {};
        });
        setCardErrors(errors);
      }
    }
  }

  const onCancel = () => {
    showDialog({
      title: t('common.cancelPaymentProcess'),
      description: t('common.confirmCardPaymentCancellation'),
      confirmButtonLabel: t('common.yes'),
      cancelButtonLabel: t('common.no'),
      confirmCallback: () => {
        cancelCardPayment();
        setWarningAlert(
          t('status.error.paymentCanceled') + '\n' + t('status.error.paymentAuthorizationsCanceled')
        );
        navigate({ to: paymentOptionsPath, search: true });
      },
    });
  };

  return (
    <Page title={t(`${order?.optionSelected}.checkout.standard.header`)} withSidebar>
      <Alert data-testid="infobox">{t(`${order.optionSelected}.checkout.standard.infobox`)}</Alert>

      <Status {...status} />

      <MultiCardForm
        paymentMethods={savedPaymentMethods}
        paymentSummary={paymentSummary}
        order={order}
        onCancel={onCancel}
        onSubmit={onSubmit}
        cardErrors={cardErrors}
      />
    </Page>
  );
};

export default Checkout;
