import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import useAlertStore, { setErrorAlertSelector } from 'Core/stores/alert';
import { post } from 'Core/utils/api';
import { features, postLogMessage } from 'Core/utils/logger';

export default function usePostPaymentInvitation() {
  const { t } = useTranslation();
  const setErrorAlert = useAlertStore(setErrorAlertSelector);

  return useMutation(
    ({ orderId, emailList = [] }) =>
      post(`/api/payment-invitations/sendInvitation`, {
        orderId,
        email: emailList,
      }),
    {
      onError(e) {
        setErrorAlert(t('status.error.sendingPaymentInviteEmail'));
        throw e;
      },
      onSuccess() {
        postLogMessage({
          feature: features.sendPaymentLink,
          status: 'success',
        });
      },
    }
  );
}
