import React from 'react';
import { useTranslation } from 'react-i18next';
import useOrder from 'Core/hooks/queries/order/useOrder';
import FooterBlock from './FooterBlock';
import FooterItem from './FooterItem';
import constants from 'Core/config/constants';
import { formatCurrency } from 'Core/utils/numberFormat';

const CartFooter = ({ paymentSummary }) => {
  const { t } = useTranslation();
  const { data: order } = useOrder();

  const {
    quoteDetails: { totalAmount, totalDiscountAmount },
    amountAlreadyPaid,
    payments,
    totalAmountToPay,
    insuranceBenefit,
    hearingTestFee = 0,
  } = paymentSummary;

  const isMixedPayment = order.optionSelected === constants.paymentOptions.MIXED;
  const hasDiscount = totalDiscountAmount > 0;
  const hasDeposit =
    payments.length && payments.find((payment) => payment.purpose.toLowerCase() === 'deposit');
  const hasInsuranceBenefit = insuranceBenefit > 0;
  const showAvailableCredit = !isMixedPayment && hasDeposit && amountAlreadyPaid > 0;

  return (
    <div>
      <FooterBlock testid="static-cart-footer-block">
        {hasDiscount ? (
          <FooterItem
            label={t('common.discountIncluded')}
            amount={
              <span data-testid="discount" className="text-success">
                {formatCurrency(-totalDiscountAmount)}
              </span>
            }
          />
        ) : null}
        {showAvailableCredit ? (
          <FooterItem
            label={t('common.availableCredit')}
            amount={
              <span data-testid="availableCredit" className="text-success">
                {formatCurrency(-amountAlreadyPaid)}
              </span>
            }
          />
        ) : null}
        <FooterItem label={t('common.total')} amount={formatCurrency(totalAmount)} />
        {hearingTestFee > 0 ? (
          <FooterItem label={t('common.hearingTestFee')} amount={formatCurrency(-hearingTestFee)} />
        ) : null}
      </FooterBlock>

      {hasInsuranceBenefit ? (
        <FooterBlock testid="insurance-cart-footer-block">
          <FooterItem
            label={t('common.expectedInsuranceBenefit')}
            amount={
              <span data-testid="expectedInsuranceBenefit" className="text-success">
                {formatCurrency(-insuranceBenefit)}
              </span>
            }
          />

          <FooterItem
            label={<b>{t('common.amountDue')}</b>}
            amount={<b>{formatCurrency(totalAmountToPay)}</b>}
          />
        </FooterBlock>
      ) : null}
    </div>
  );
};

export default CartFooter;
