import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'Core/components/Spinner';
import { Overlay } from 'Core/components/Layout';
import classnames from 'classnames';

const messageStyles = {
  overlay: `mt-4`,
  inline: `ml-2`,
};

const loaderWrapperStyles = {
  overlay: `
    flex-col
    bg-white
    border border-solid border-gray-300 rounded
    py-8 px-2 my-10
    [box-shadow:0_4px_4px_1px_#b3b3b3]
    [min-width:50vw]
  `,
  inline: `py-1 px-5`,
};

const spinnerStyles = {
  overlay: `w-8 h-8 text-primary`,
  inline: `w-4 h-4`,
};

const Loader = ({ message, type = 'overlay', className, ...rest }) => {
  const loaderContent = (
    <div
      data-testid="spinner"
      className={classnames('flex items-center justify-center', className)}
      {...rest}
    >
      <div
        className={classnames(
          `flex flex-nowrap items-center justify-center`,
          loaderWrapperStyles[type]
        )}
      >
        <Spinner small={type === 'inline'} className={spinnerStyles[type]} />
        <div className={classnames('text-center', messageStyles[type])}>{message}</div>
      </div>
    </div>
  );

  if (type === 'inline') {
    return loaderContent;
  }

  return <Overlay>{loaderContent}</Overlay>;
};

Loader.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['overlay', 'inline']),
};

export default Loader;
