import React from 'react';
import { useTranslation } from 'react-i18next';
import { multiSortByObjectProp } from 'Core/utils/utils';
import CartHeader from './CartHeader';
import CartItems from './CartItems';
import CartFooter from './CartFooter';
import Disclaimer from './Disclaimer';
import useGetPaymentSummary from 'Core/hooks/queries/payment/useGetPaymentSummary';
import Item from 'Core/components/ShoppingCart/CartItems/Item';
import classnames from 'classnames';

function StyledCart({ withElevation, children }) {
  return (
    <div
      className={classnames('bg-white rounded p-2 mb-4', {
        'box-shadow: 0 7px 7px -3px #c3c3c3': withElevation,
      })}
    >
      {children}
    </div>
  );
}

const ShoppingCart = ({ withTitle = false, withElevation = false }) => {
  const { t } = useTranslation();
  const { data: paymentSummary } = useGetPaymentSummary();

  if (!paymentSummary) {
    return (
      <StyledCart withElevation={withElevation}>
        <CartHeader />
        <div>
          <Item />
          <Item />
          <Item />
        </div>
      </StyledCart>
    );
  }

  const {
    quoteDetails: { items },
    insuranceBenefit,
  } = paymentSummary;

  const hasInsuranceBenefit = insuranceBenefit > 0;
  const sortedItems = items.concat([]).sort(multiSortByObjectProp('-unitPrice', 'description'));

  return (
    <>
      {withTitle && <h2 data-testid="shopping-cart-headline">{t('common.shoppingCart')}</h2>}
      <StyledCart withElevation={withElevation}>
        <CartHeader />
        <CartItems items={sortedItems} />
        <CartFooter paymentSummary={paymentSummary} />
        <Disclaimer content={t('common.insuranceBenefitDisclaimer')} show={hasInsuranceBenefit} />
      </StyledCart>
    </>
  );
};

export default ShoppingCart;
