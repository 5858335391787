import React from 'react';
import CreditCardLogos from 'Core/components/CreditCardLogos';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from 'Core/utils/numberFormat';
import classnames from 'classnames';

export function PaymentHistory({ history }) {
  const { t } = useTranslation();

  return history.length === 0 ? null : (
    <div className="mb-12">
      <h2>{t('directSales.history.header')}</h2>
      {history.map((transaction) => {
        const {
          paymentId,
          txnType,
          amount,
          transactionDate,
          card: { brand, last4 },
          url: receiptUrl,
        } = transaction;

        return (
          <div
            className="bg-white border border-solid border-gray-300 rounded mb-2 p-3 shadow gap-y-3 flex flex-col"
            key={paymentId}
          >
            <div className="flex items-center  gap-x-3">
              <div className="w-32 flex">
                <div
                  className={classnames(
                    'border border-solid rounded-xl px-4 py-1 text-white font-bold text-lg',
                    {
                      'bg-green-300 border-green-300': txnType.toLowerCase() === 'capture',
                      'bg-yellow-300 border-yellow-300': txnType.toLowerCase() !== 'capture',
                    }
                  )}
                >
                  {t(`common.${txnType.toLowerCase()}`)}
                </div>
              </div>
              <div className="text-2xl">{formatCurrency(amount, { withDecimals: false })}</div>
              <div className="flex-1" />
              <div className="flex items-center ml-4 font-bold">
                <CreditCardLogos brand={brand} size="medium" className="mr-2" />
                <div className="flex-1">**** {last4}</div>
              </div>
            </div>
            <div className="flex">
              <div>{transactionDate}</div>
              <div className="flex-1" />
              <a
                href={receiptUrl}
                className="text-lg underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('common.viewReceipt')}
              </a>
            </div>
          </div>
        );
      })}
    </div>
  );
}
