import React from 'react';
import classnames from 'classnames';

const borderStyles = {
  green: `border-success`,
  orange: `border-yellow-500`,
  red: `border-error`,
  gray: `border-gray-300`,
};

export function StyledIndicator({ color, children }) {
  return (
    <div
      className={classnames('border-2 border-solid rounded-full h-7 w-7 my-2', {
        [borderStyles[color]]: Boolean(color),
        'border-transparent': !color,
      })}
    >
      {children}
    </div>
  );
}
