import { useTranslation } from 'react-i18next';
import useErrorStore, { setErrorActionSelector } from 'Core/stores/error';
import { useQuery } from 'react-query';
import cacheKeys from 'Core/hooks/queries/cacheKeys';
import { get } from 'Core/utils/platformApi';
import { useGetPaymentInfo } from 'Core/hooks/platform/useGetPaymentInfo';

export function useGetLatestTransaction(requestId) {
  const { t } = useTranslation();
  const setError = useErrorStore(setErrorActionSelector);
  const { data: paymentInfo } = useGetPaymentInfo();

  return useQuery(
    cacheKeys.latestTransaction,
    () =>
      get('/transaction/latest', {
        headers: {
          request_id: requestId,
          external_reference_id: paymentInfo.external_reference_id,
        },
      }),
    {
      enabled: Boolean(requestId),
      refetchInterval: (data) => (data ? (data.length ? false : 500) : false),
      onError(e) {
        if (e) {
          setError(t('status.error.getTransactionHistoryFailed') + ` - (${e.message})`);
        }
      },
    }
  );
}
