import React from 'react';
import Dashboard from 'Modules/creditDebit/routes/Dashboard';
import PaymentOptions from 'Modules/creditDebit/routes/PaymentOptions';
import Checkout from 'Modules/creditDebit/routes/Checkout';
import Success from 'Modules/creditDebit/routes/Success';
import Error from 'Modules/creditDebit/routes/Error';
import FinancingRedirect from 'Modules/creditDebit/routes/FinancingRedirect';
import FinancingSuccess from 'Modules/creditDebit/routes/FinancingSuccess';
import FinancingError from 'Modules/creditDebit/routes/FinancingError';
import Financing from 'Modules/creditDebit/routes/Financing';
import OrderData from 'Core/./components/Data/OrderData';
import AvailableCredit from 'Modules/creditDebit/routes/AvailableCredit';
import MixedSuccess from 'Modules/creditDebit/routes/MixedSuccess';
import MixedError from 'Modules/creditDebit/routes/MixedError';

export const dashboardPath = '/office';
export const paymentOptionsPath = '/';

export const creditDebitCheckoutPath = '/credit-debit/checkout';
export const creditDebitSuccessPath = '/credit-debit/success';
export const creditDebitErrorPath = '/credit-debit/error';

export const createFinancingPath = '/office/financing';
export const financingPath = '/financing';
export const financingSuccessPath = '/financing/funded';
export const financingErrorPath = '/financing/declined';

export const mixedAvailableCreditPath = '/mixed/available-credit';
export const mixedDownPaymentCheckoutPath = '/mixed/down-payment/checkout';
export const mixedFinancingPath = '/mixed/financing';
export const mixedSuccessPath = '/mixed/success';
export const mixedErrorPath = '/mixed/error';

export const routes = [
  {
    path: dashboardPath,
    element: (
      <OrderData>
        <Dashboard />
      </OrderData>
    ),
  },
  {
    path: paymentOptionsPath,
    element: (
      <OrderData>
        <PaymentOptions />
      </OrderData>
    ),
  },
  {
    path: creditDebitCheckoutPath,
    element: (
      <OrderData>
        <Checkout
          nextRoutes={{
            success: creditDebitSuccessPath,
            error: creditDebitErrorPath,
          }}
        />
      </OrderData>
    ),
  },
  {
    path: creditDebitSuccessPath,
    element: (
      <OrderData>
        <Success />
      </OrderData>
    ),
  },
  {
    path: creditDebitErrorPath,
    element: (
      <OrderData>
        <Error />
      </OrderData>
    ),
  },
  { path: createFinancingPath, element: <FinancingRedirect /> },
  {
    path: financingPath,
    element: (
      <OrderData>
        <Financing nextRoutes={{ success: financingSuccessPath, error: financingErrorPath }} />
      </OrderData>
    ),
  },
  {
    path: financingSuccessPath,
    element: (
      <OrderData>
        <FinancingSuccess />
      </OrderData>
    ),
  },
  {
    path: financingErrorPath,
    element: (
      <OrderData>
        <FinancingError />
      </OrderData>
    ),
  },
  {
    path: mixedAvailableCreditPath,
    element: (
      <OrderData>
        <AvailableCredit />
      </OrderData>
    ),
  },
  {
    path: mixedDownPaymentCheckoutPath,
    element: (
      <OrderData>
        <Checkout
          nextRoutes={{
            success: mixedFinancingPath,
            error: mixedErrorPath,
          }}
        />
      </OrderData>
    ),
  },
  {
    path: mixedFinancingPath,
    element: (
      <OrderData>
        <Financing nextRoutes={{ success: mixedSuccessPath, error: mixedErrorPath }} />
      </OrderData>
    ),
  },
  {
    path: mixedSuccessPath,
    element: (
      <OrderData>
        <MixedSuccess />
      </OrderData>
    ),
  },
  {
    path: mixedErrorPath,
    element: (
      <OrderData>
        <MixedError />
      </OrderData>
    ),
  },
];
