import React from 'react';
import TabItem from 'Core/components/Tabset/TabItem';

export default function Tabset({ tabConfig, onTabChange }) {
  const [tabs, setTabs] = React.useState([]);
  React.useEffect(() => {
    setTabs(!tabConfig ? [] : [...tabConfig]);
  }, [tabConfig]);

  const toggleTab = (e) => {
    const tabId = e.currentTarget.dataset.id;
    onTabChange(tabId);
  };
  if (!tabConfig) {
    return <></>;
  }
  return (
    <ul
      className="flex flex-col md:flex-row flex-wrap list-none border-b-0 pl-0 mb-4"
      id="tabs-tabFill"
      role="tablist"
    >
      {tabs.map((tab) => {
        return <TabItem {...tab} data-id={tab.id} key={tab.id} onClick={toggleTab}></TabItem>;
      })}
    </ul>
  );
}
