import { useMutation, useQueryClient } from 'react-query';
import { postWithHeaders } from 'Core/utils/platformApi';
import cacheKeys from 'Core/hooks/queries/cacheKeys';
import useAlertStore, { clearAlertSelector, setErrorAlertSelector } from 'Core/stores/alert';
import { useTranslation } from 'react-i18next';
import { features, postLogMessage } from 'Core/utils/logger';
import { getUrlParam } from 'Core/utils/utils';
import { useGetPaymentInfo } from 'Core/hooks/platform/useGetPaymentInfo';

function transformPayload({
  firstName,
  lastName,
  address,
  city,
  state,
  zip,
  email,
  phone: number,
  socialSecurityNumber,
  dateOfBirth,
  annualIncome,
  customer_id,
  external_reference_id,
  purpose,
  provider,
  payment_option,
  relationship,
}) {
  return {
    payment_id: getUrlParam('payment-id'),
    external_reference_id,
    purpose,
    payment_option,
    provider,
    payload: {
      customer_id,
      first_name: firstName,
      middle_name: '',
      last_name: lastName,
      email,
      date_of_birth: dateOfBirth,
      customer_identity_number: socialSecurityNumber,
      monthly_income: Math.floor(annualIncome / 12),
      relationship,
      address_line: address,
      city,
      state,
      zip,
      phone_number: number,
      phone_type: 'mobile',
      agree_to_terms: true,
      authorized_representative: '',
    },
  };
}

export default function useCreateApplication() {
  const { t } = useTranslation();
  const setErrorAlert = useAlertStore(setErrorAlertSelector);
  const clearAlert = useAlertStore(clearAlertSelector);
  const { data: paymentInfo } = useGetPaymentInfo();
  const queryClient = useQueryClient();
  return useMutation(
    (payload) =>
      postWithHeaders(
        `/financing/application`,
        transformPayload({
          ...payload,
          customer_id: paymentInfo.customer_id,
          external_reference_id: paymentInfo.external_reference_id,
          purpose: paymentInfo.purpose,
          provider: paymentInfo.provider,
          payment_option: paymentInfo.payment_option,
        })
      ),
    {
      throwOnError: true,
      retry: 2,
      enabled: Boolean(paymentInfo),
      onSuccess(data) {
        postLogMessage({
          feature: features.createAllegroApp,
          status: 'success',
        });

        clearAlert();
        queryClient.invalidateQueries(cacheKeys.financingPlatformApplication);

        return data;
      },
      onError(error) {
        setErrorAlert(
          t('status.error.createApplicationRequestFailed') + `(${error?.response?.data?.message})`
        );
      },
    }
  );
}
