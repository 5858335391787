import { useMutation } from 'react-query';

export default function useCreatePaymentMethod() {
  return useMutation(
    async ({ stripe, stripeCardElement, name, postalCode }) => {
      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: stripeCardElement,
        billing_details: {
          name,
          address: {
            postal_code: postalCode,
          },
        },
      });

      return result.paymentMethod;
    },
    {
      throwOnError: true,
    }
  );
}
