import { Alert } from 'Core/components/Alert';
import IndicatorCheckmark from 'Modules/creditDebit/components/Checklist/IndicatorCheckmark';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function AutoCloseNotifier({ duration, children, className }) {
  const [secondsLeft, setSecondsLeft] = React.useState(duration / 1000);
  const { t } = useTranslation();
  React.useEffect(() => {
    const interval = setInterval(() => {
      if (secondsLeft > 0) {
        setSecondsLeft((seconds) => seconds - 1);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  });

  return (
    <>
      <Alert
        type="successLight"
        data-testid="auto-close-notification"
        className={className ? className : ''}
      >
        <div className="m-auto p-2 flex items-center">
          <IndicatorCheckmark color="green" className="" />
          <div className="ml-4">
            {children}
            {duration && (
              <>
                &nbsp;
                <span className="font-extrabold text-green-800" data-testid="seconds-left">
                  {secondsLeft}
                </span>
                &nbsp;
                {t('common.seconds')}
              </>
            )}
          </div>
        </div>
      </Alert>
    </>
  );
}
