import React from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { format, isValid, parse } from 'date-fns';

export const validateDownPaymentAmount = (minimumDownPayment, maximumDownPayment) => (value) => {
  return value !== '' && value >= minimumDownPayment && value <= maximumDownPayment;
};

export const validateCustomDateFormat = (dateFormat) => (val) => {
  return val ? isValid(parse(val, dateFormat, new Date())) : true;
};

const useSelectOfferForm = ({ minimumDownPayment, maximumDownPayment, dateFormat }) => {
  const { t } = useTranslation();

  const schema = React.useMemo(() => {
    return z.object({
      deliveryDate: z
        .any()
        .refine(validateCustomDateFormat(dateFormat), { message: t('common.invalidDateFormat') }),
      downPayment: z
        .any()
        .transform(parseFloat)
        .refine(validateDownPaymentAmount(minimumDownPayment, maximumDownPayment), {
          message: t('common.invalidValueRange', {
            min: minimumDownPayment,
            max: maximumDownPayment,
          }),
        }),
    });
  }, [t, minimumDownPayment, maximumDownPayment, dateFormat]);

  return useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      downPayment: minimumDownPayment,
      deliveryDate: format(new Date(), dateFormat),
    },
  });
};

export default useSelectOfferForm;
