import React from 'react';

export default function TabItem({ label, isActive, isDisabled, ...props }) {
  let tabStyle =
    'transition-all duration-500 w-full block font-medium leading-tight border-b-3 px-6 py-3 my-2 pointer-events-cursor rounded-t-lg ';
  if (isActive) {
    tabStyle += 'text-blue-600 border-blue-600 ';
  } else {
    tabStyle += 'border-slate-00 ';
  }

  if (!isDisabled) {
    tabStyle += 'hover:bg-gray-200 ';
  }
  if (isDisabled) {
    tabStyle += 'pointer-events-none  text-gray-300 ';
  }
  return (
    <li className="nav-item flex-auto text-center" role="presentation" key="tab.label">
      <button
        type="button"
        disabled={isDisabled}
        {...props}
        className={tabStyle}
        id="tabs-home-tabFill"
      >
        <span>{label}</span>
      </button>
    </li>
  );
}
