import React from 'react';
import PropTypes from 'prop-types';

const FooterItem = ({ label, amount }) => {
  return (
    <div className="flex flex-wrap text-sm text-right">
      <div className="w-3/4 px-2 ">{label}</div>
      <div className="w-1/4 px-2 ">{amount}</div>
    </div>
  );
};

FooterItem.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
};

export default FooterItem;
