import { useMutation, useQueryClient } from 'react-query';
import cacheKeys from 'Core/hooks/queries/cacheKeys';
import { put } from 'Core/utils/platformApi';

export default function usePutUpdateCard() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ externalReferenceId, paymentMethodId, preferred }) => {
      return put(
        `/cards/${paymentMethodId}`,
        { preferred },
        {
          headers: { external_reference_id: externalReferenceId },
        }
      );
    },
    {
      onSettled() {
        return queryClient.invalidateQueries(cacheKeys.savedCards);
      },
    }
  );
}
