import { useMutation, useQueryClient } from 'react-query';
import cacheKeys from 'Core/hooks/queries/cacheKeys';
import { put } from 'Core/utils/api';

export default function useUpdatePaymentMethod() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ paymentMethodId, preferred }) => {
      return put(`/api/payment-methods/${paymentMethodId}`, { preferred });
    },
    {
      async onMutate({ paymentMethodId, preferred }) {
        await queryClient.cancelQueries(cacheKeys.savedPaymentMethods);

        const previousPaymentMethods = queryClient.getQueryData(cacheKeys.savedPaymentMethods);

        queryClient.setQueryData(cacheKeys.savedPaymentMethods, (paymentMethods = []) =>
          paymentMethods.map((pm) => {
            if (pm.paymentMethodId === paymentMethodId) {
              return {
                ...pm,
                preferred,
              };
            }
            return {
              ...pm,
              preferred: false,
            };
          })
        );

        return { previousPaymentMethods };
      },
      onError(e) {
        queryClient.setQueryData(cacheKeys.savedPaymentMethods, context.previousPaymentMethods);
        throw e;
      },
      onSettled() {
        return queryClient.invalidateQueries(cacheKeys.savedPaymentMethods);
      },
    }
  );
}
