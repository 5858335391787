import React from 'react';
import PropTypes from 'prop-types';
import { useGetPaymentReceiptUrls } from 'Core/hooks/queries/payment/useGetPaymentReceiptUrls';
import Receipt from './Receipt';

const PaymentReceipts = ({ title = '', paymentRequestId }) => {
  const queryResponse = useGetPaymentReceiptUrls(paymentRequestId);

  return <Receipt title={title} {...queryResponse} />;
};

PaymentReceipts.propTypes = {
  title: PropTypes.string,
};

export default PaymentReceipts;
