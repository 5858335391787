import React from 'react';
import { useTranslation } from 'react-i18next';
import CreateApplication from 'Modules/financing/routes/CreateApplication';
import FinancingPreparation from 'Modules/financing/components/FinancingPreparation';
import { useGetNextStep } from 'Modules/financing/hooks/useGetNextStep';
import { useGetPaymentInfo } from 'Core/hooks/platform/useGetPaymentInfo';
import Loader from 'Core/components/Loader';
import FinancingSuccess from 'Modules/financing/routes/FinancingSuccess';
import FinancingApproved from 'Modules/financing/routes/FinancingApproved';
import ReturnTracking from 'Modules/financing/components/ReturnTracking';
import FinancingStatusMessage from 'Modules/financing/components/FinancingStatusMessage';
import FinancingDeclined from 'Modules/financing/routes/FinancingDeclined';

import FinancingCorrectionRequired from 'Modules/financing/components/FinaningCorrectionRequired';
import { cardPreferenceCenterPath, paymentPath } from 'Modules/cardPayment/routes';

import {
  financingPath,
  financingApprovedPath,
  financingSuccessPath,
  financingErrorPath,
  mixedDownPaymentCheckoutPath,
} from 'Modules/financing/routes';
import { errorPath } from 'Core/routes';
import useErrorStore, { setErrorActionSelector } from 'Core/stores/error';
import { useNavigate } from 'react-location';

export default function FinancingRedirect() {
  const routes = {
    paymentOptions: cardPreferenceCenterPath,
    financing: financingPath,
    mixed: mixedDownPaymentCheckoutPath,
    approved: financingApprovedPath,
    financingSuccessPath,
    financingErrorPath,
    payment: paymentPath,
  };
  const navigate = useNavigate();
  const setError = useErrorStore(setErrorActionSelector);
  const { t } = useTranslation();
  const cachedApp = JSON.parse(sessionStorage.getItem('application'));
  const { data: newApp, isLoading, isError, error } = useGetNextStep(false, !cachedApp);
  const application = cachedApp || newApp;
  sessionStorage.removeItem('application');
  const { next_action = 'CREATE_APPLICATION' } = application || {};
  const {
    data: { amount_to_pay, customer_id = '' } = {},
    isLoading: isPaymentInfoLoading,
    isError: isPaymentInfoError,
  } = useGetPaymentInfo();

  if (isLoading) {
    return <Loader message={t('status.loading.financingApplication')} />;
  }

  // No application found? -> Show form to create one
  else if (isError || error?.response?.status === 404 || !application?.next_action) {
    return <CreateApplication customer_id={customer_id} />;
  } else if (application?.next_action) {
    switch (next_action) {
      case 'CREATE_APPLICATION':
        return <CreateApplication type="primary" customer_id={customer_id} />;
      case 'SELECT_OFFER':
      case 'UPDATE_OFFER':
      case 'SIGN_CONTRACT':
        return <FinancingPreparation application={application} routes={routes} />;
      case 'CORRECTION_REQUIRED':
        return <FinancingCorrectionRequired windowUrl={application?.payload?.links?.app} />;
      case 'UPDATE_AMOUNT':
        return <FinancingApproved />;
      case 'CANCELLED':
      case 'DECLINED':
        return <FinancingDeclined allowCreateApplication application={application} />;
      case 'DONE':
      case 'FUND_APPLICATION':
      case 'PENDING_DOWN_PAYMENT':
        return <FinancingSuccess />;

      case 'RETURN_APPROVED':
      case 'RETURN_REQUESTED':
      case 'RETURN_PROCESSING':
      case 'RETURN_COMPLETE':
      case 'RETURN_CANCELED':
        return <ReturnTracking nextAction={next_action} />;
      case 'DECISION_PENDING':
      case 'NO_DECISION':
      case 'APPLICATION_CLOSED':
        return <FinancingStatusMessage nextAction={next_action} />;

      default:
        setError(t('status.error.getFinancingApplicationFailed'));
        navigate({ to: errorPath, search: true });
    }
  }

  if (!isPaymentInfoError && !isPaymentInfoLoading && !amount_to_pay) {
    return <FinancingApproved />;
  }

  return <Loader message={t('status.loading.financingApplication')} />;
}
