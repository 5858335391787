import useGetSavedPaymentMethods from 'Core/hooks/queries/payment/useGetSavedPaymentMethods';
import { useGetDepositOptions } from 'Core/hooks/queries/deposit/useGetDepositOptions';

export default function useDepositPageData() {
  const paymentMethodsQuery = useGetSavedPaymentMethods();
  const depositOptionsQuery = useGetDepositOptions();

  return {
    data: {
      paymentMethods: paymentMethodsQuery.data,
      depositOptions: depositOptionsQuery.data,
    },
    isSuccess: paymentMethodsQuery.isSuccess && depositOptionsQuery.isSuccess,
  };
}
