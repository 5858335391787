import { useTranslation } from 'react-i18next';
import useErrorStore, { setErrorActionSelector } from 'Core/stores/error';
import { useQuery } from 'react-query';
import cacheKeys from 'Core/hooks/queries/cacheKeys';
import { get } from 'Core/utils/platformApi';

export function useGetNextStep(refetchInterval, enabled = true) {
  const { t } = useTranslation();
  const setError = useErrorStore(setErrorActionSelector);
  const validator = (data) => Boolean(data) && Boolean(data?.next_action);
  return useQuery(cacheKeys.financingNextStep, () => get('/application/next-step', {}, validator), {
    refetchInterval,
    enabled: Boolean(enabled),
    onError(e) {
      if (e) {
        setError(t('status.error.getPaymentInfoFailed') + ` - (${e})`);
      }
    },
  });
}
