import { useTranslation } from 'react-i18next';
import { useScrollTop } from 'Core/hooks/useScrollTop';
import React from 'react';
import Page from 'Core/components/Page';
import Card from 'Core/components/FormElements/Card';
import { PrimaryButton } from 'Core/components/Button';
import { Alert } from 'Core/components/Alert';
// import InfoBox from 'Core/components/InfoBox';
import Row from 'Core/components/Layout/Row';
import Column from 'Core/components/Layout/Column';

export default function FinaningCorrectionRequired({ windowUrl }) {
  document.title = 'Payment Portal - Correction Required';
  useScrollTop();

  const { t } = useTranslation();
  const navigateToVerificationLink = (e) => {
    e.preventDefault();
    if (windowUrl) {
      window.open(windowUrl, '_blank');
    }
  };

  return (
    <Page title={t('page.financing.header')} withStepProgress={false} stickyFooter>
      <Card className="justify-center text-center">
        <Row className="justify-center">
          <h1 className="font-regular" data-testid="">
            {t('common.newApplication')}
          </h1>
        </Row>
        <Column>
          <hr></hr>
        </Column>
        <Row className="justify-center flex-col items-center">
          <Column className="p-7 pb-2 flex-col-8 font-regular text-large w-2/3">
            <Alert
              showIcon
              type="warningLight"
              title="correction required"
              data-testid="correction-required-message"
            >
              <span>{t('financing.correctionRequired')}</span>
            </Alert>
          </Column>
          <Column className="text-center text-gray-400 w-1/3">
            <PrimaryButton data-testid="btn-verify" block onClick={navigateToVerificationLink}>
              {t('common.verify')}
            </PrimaryButton>
          </Column>
        </Row>
      </Card>
    </Page>
  );
}
