import React from 'react';

export default function Option({
  className,
  name,
  value,
  defaultChecked,
  disabled,
  testid,
  onChange,
  children,
  register,
}) {
  const id = `${name}-${value}`;

  return (
    <label className={className} htmlFor={id} data-testid={testid || id}>
      <input
        className={'w-0 h-0'}
        type="radio"
        {...register(name)}
        value={value}
        id={id}
        test-id={id}
        defaultChecked={defaultChecked}
        disabled={disabled}
        onChange={onChange}
      />
      {children}
    </label>
  );
}
