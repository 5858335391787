import React from 'react';
import { useTranslation } from 'react-i18next';
import { useScrollTop } from 'Core/hooks/useScrollTop';
import { Alert } from 'Core/components/Alert';
import ShoppingCart from 'Core/components/ShoppingCart';
import CollectPaymentPanel from 'Core/components/Panels/CollectPaymentPanel';
import SendPaymentLinkPanel from 'Core/components/Panels/SendPaymentLinkPanel';
import Page from 'Core/components/Page';
import useNavigationStore from 'Core/stores/page';
import { Column, Row } from 'Core/components/Layout';
import { useNavigate } from 'react-location';
import { paymentOptionsPath } from 'Modules/creditDebit/routes';

export default function Dashboard() {
  useScrollTop();
  const { t } = useTranslation();
  const setIsOfficeUser = useNavigationStore((state) => state.setIsOfficeUser);
  const navigate = useNavigate();

  React.useEffect(() => {
    setIsOfficeUser(true);
  }, [setIsOfficeUser]);

  function gotoNext() {
    navigate({ to: paymentOptionsPath, search: true });
  }

  return (
    <Page title={t('page.dashboard.header')} withStepProgress={false}>
      <Alert data-testid="infobox">{t('page.dashboard.infobox')}</Alert>
      <ShoppingCart withTitle />
      <Row>
        <Column className="!flex md:!flex-col-6">
          <SendPaymentLinkPanel />
        </Column>
        <Column className="!flex md:!flex-col-6">
          <CollectPaymentPanel gotoNext={gotoNext} />
        </Column>
      </Row>
    </Page>
  );
}
