import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import useAlertStore, { setErrorAlertSelector } from 'Core/stores/alert';
import cacheKeys from 'Core/hooks/queries/cacheKeys';
import useOrder from 'Core/hooks/queries/order/useOrder';
import { get } from 'Core/utils/api';

export function useGetOrderReceiptUrls() {
  const { t } = useTranslation();
  const { data: order } = useOrder();
  const setErrorAlert = useAlertStore(setErrorAlertSelector);

  return useQuery(
    cacheKeys.orderReceipts,
    () => get(`/api/orders/${order.orderId}/payments/receipts`),
    {
      enabled: Boolean(order),
      onError(e) {
        setErrorAlert(t('status.error.getPaymentReceiptUrlQueryFailed'));
        throw e;
      },
    }
  );
}
