import React from 'react';
import { useTranslation } from 'react-i18next';
import { useScrollTop } from 'Core/hooks/useScrollTop';
import { AlertSuccess } from 'Core/components/Alert';
import Card from 'Core/components/FormElements/Card';
import PaymentReceipts from 'Core/components/Receipts/PaymentReceipts';
import Page from 'Core/components/Page';

export default function Success() {
  useScrollTop();
  const { t } = useTranslation();

  return (
    <Page
      title={t('page.deposit.success.header')}
      stepProgressProps={{ type: 'deposit' }}
      withCardPrefCenterLink
    >
      <AlertSuccess>{t('page.deposit.success.infobox')}</AlertSuccess>

      <Card className="justify-center">
        <p className="text-center">{t('page.deposit.success.intro')}</p>

        <PaymentReceipts title={t('page.creditDebit.success.clickToViewReceipt')} />

        <hr />
      </Card>
    </Page>
  );
}
