import React from 'react';
import { formatCurrency } from 'Core/utils/numberFormat';
import VerticalRadioOption from 'Core/components/VerticalOption/VerticalRadioOption';
import { OutlineButton, TextButton } from 'Core/components/Button';
import { FaCheckCircle } from 'react-icons/fa/index';
import { ImBlocked } from 'react-icons/im';
import offerTagConfig from 'Modules/financing/config/offerTagConfig';
import { useTranslation } from 'react-i18next';
export default function OfferOptions({
  offers,
  selectedOffer,
  setSelectedOffer,
  amount_to_pay,
  already_paid_amount,
  showOfferDetails,
}) {
  const { t } = useTranslation();
  const getEstimatedMonthlyPayment = (principle, num_of_months, rate) => {
    if (rate && principle && num_of_months) {
      const monthly_rate = rate / 100 / 12;
      const monthly_rate_inc = monthly_rate + 1;
      const est_monthly_payment_min =
        (principle * monthly_rate * Math.pow(monthly_rate_inc, num_of_months)) /
        (Math.pow(monthly_rate_inc, num_of_months) - 1);
      return formatCurrency(est_monthly_payment_min, { withDecimals: false });
    }
    return '';
  };
  return offers.installment.map(({ program, terms, description, type }, idx) => {
    const [{ id, amount_min, require_downpayment_percent, apr, apr_max, term }] = terms;
    const checked = selectedOffer.id === id;
    const isDisabled = amount_to_pay - already_paid_amount < amount_min;
    const emp_min = getEstimatedMonthlyPayment(amount_to_pay, term, apr);
    const emp_max = getEstimatedMonthlyPayment(amount_to_pay, term, apr_max);
    const getTag = () => {
      if (offerTagConfig?.length > 0) {
        const tagObject = offerTagConfig.find((tagObj) => {
          return tagObj.offerIds.some((itemId) => id === itemId);
        });
        return tagObject?.tagLabel ? t(tagObject.tagLabel) : null;
      }
      return null;
    };

    return (
      <div className="!flex-col-4 min-h-[15em] first:pl-0 last:pr-0 px-2" key={id}>
        <VerticalRadioOption
          key={id}
          className="mb-3"
          name="offer"
          value={id}
          checked={checked}
          disabled={isDisabled}
          data-testid={'select-offer-radio-' + idx}
          tag={getTag()}
          onChange={(e) => {
            setSelectedOffer({
              id: e.target.value,
              amount_min,
              require_downpayment_percent,
              type,
            });
          }}
        >
          <div className="text-center flex flex-col py-2 justify-around radio-header">
            <h5 className="text-center font-semibold text-sm px-1">{program}</h5>
            <div className="font-extrabold text-2xl mb-2 py-2">{emp_min}</div>
            <div className="font-light text-sm mb-2">Estimated Monthly Payment</div>
            <div className="font-light text-sm mb-2">{` ${emp_max} without Autopay`}</div>
            {!checked ? (
              !isDisabled ? (
                <OutlineButton rounded className="pointer-events-none mt-3 mb-2 w-1/2 m-auto !py-0">
                  Select
                </OutlineButton>
              ) : (
                <div className="mb-4 mt-6 text-gray-400 flex items-center justify-center">
                  <span className="inline-block">
                    <ImBlocked />
                  </span>
                  <span className="ml-2">Not Eligible</span>
                </div>
              )
            ) : (
              <TextButton className="pointer-events-none mt-2 mb-3 flex items-center justify-center">
                <FaCheckCircle />
                <div className="ml-2 text-base">Selected</div>
              </TextButton>
            )}
          </div>

          {showOfferDetails && (
            <div className="h-full px-4 radio-description text-sm flex flex-col justify-start">
              <ul>
                {description
                  ?.replaceAll('<br />', ' ')
                  .split('. ')
                  .map((item, ind) => {
                    return (
                      <li key={ind} className="my-4 text-justify text-xs">
                        {item}
                      </li>
                    );
                  })}
              </ul>
            </div>
          )}
        </VerticalRadioOption>
      </div>
    );
  });
}
