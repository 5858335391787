import React from 'react';
import Overlay from './Overlay';
import classnames from 'classnames';

const Modal = ({ isOpen, className, children }) => {
  return isOpen ? (
    <Overlay>
      <div className={classnames(className, 'shadow-lg bg-white border rounded p-4')}>
        {children}
      </div>
    </Overlay>
  ) : null;
};

export default Modal;
