import React from 'react';
import { AlertError } from 'Core/components/Alert';
import Loader from 'Core/components/Loader';

export default function Status({ type, text }) {
  if (!text) return null;

  return type === 'error' ? (
    <AlertError>{text}</AlertError>
  ) : type === 'loading' ? (
    <Loader message={text} />
  ) : null;
}
