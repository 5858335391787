import { useQueries } from 'react-query';
import useOrder from 'Core/hooks/queries/order/useOrder';
import cacheKeys from 'Core/hooks/queries/cacheKeys';
import { queryFn as paymentSummaryQueryFn } from 'Core/hooks/queries/payment/useGetPaymentSummary';
import { queryFn as paymentOptionsQueryFn } from 'Core/hooks/queries/payment/useGetPaymentOptions';
import { queryFn as savedPaymentMethodsQueryFn } from 'Core/hooks/queries/payment/useGetSavedPaymentMethods';
import { useNavigate } from 'react-location';
import { errorPath } from 'Core/routes';

export default function usePaymentInitializer() {
  const { data: order } = useOrder();
  const navigate = useNavigate();
  const requests = useQueries([
    {
      queryKey: cacheKeys.paymentSummary,
      queryFn: () => paymentSummaryQueryFn(order.orderId),
      enabled: Boolean(order?.orderId),
    },
    {
      queryKey: cacheKeys.paymentOptions,
      queryFn: () => paymentOptionsQueryFn(order.orderId),
      enabled: Boolean(order?.orderId),
    },
    {
      queryKey: cacheKeys.savedPaymentMethods,
      queryFn: savedPaymentMethodsQueryFn,
      enabled: Boolean(order?.orderId),
    },
  ]);

  const isLoading = requests.some((req) => req.isLoading);
  const isError = requests.some((req) => req.isError);

  if (isError) {
    return navigate({ to: errorPath, search: true });
  }

  return {
    isLoading,
    isError,
    data: order,
  };
}
