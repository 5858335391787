import React from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'Core/components/Loader';
import { AlertError } from 'Core/components/Alert';
import { PrimaryButton } from 'Core/components/Button';

const showPaymentReceipt = (url, target) => {
  const width = 660;
  const height = window.innerHeight < 820 ? window.innerHeight - 20 : 820;
  const left = window.innerWidth / 2 - width / 2;
  const top = 40;
  const features = `left=${left},top=${top},width=${width},height=${height}`;

  window.open(url, target, features);
};

const Receipt = ({ title, isLoading, isError, data: paymentReceipts }) => {
  const { t } = useTranslation();
  if (isLoading) {
    return <Loader message={t('status.loading.paymentReceiptsUrl')} type="inline" />;
  }

  if (isError) {
    return <AlertError>{t('status.error.failedToLoadPaymentReceipt')}</AlertError>;
  }

  if (!paymentReceipts || !paymentReceipts?.receipts.length) {
    return null;
  }

  const { receipts = [] } = paymentReceipts;

  return (
    <div className="text-center">
      <div className="font-bold mb-2">{title}</div>

      <div className="flex items-center justify-center">
        {receipts.map((item) => {
          const { card, financing, type, url } = item;

          let label = '';
          let target = '_blank';

          switch (type) {
            case 'card':
              label = `${card.brand.toUpperCase()} **** ${card.last4}`;
              break;

            case 'financing':
              label = `${financing.brand} ${t('common.financingContract')}`;
              target = '_self';
              break;

            default:
              label = t('common.showPaymentReceipt');
          }

          return (
            <PrimaryButton
              key={url}
              className="!mx-1"
              size="small"
              onClick={() => showPaymentReceipt(url, target)}
              disabled={isLoading}
              data-testid={`receiptUrlBtn`}
            >
              {label}
            </PrimaryButton>
          );
        })}
      </div>
    </div>
  );
};

export default Receipt;
