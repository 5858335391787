import useOrder from 'Core/hooks/queries/order/useOrder';
import { useMutation, useQueryClient } from 'react-query';
import { put } from 'Core/utils/api';
import cacheKeys from 'Core/hooks/queries/cacheKeys';

export default function useUpdateOffer() {
  const { data: order } = useOrder();
  const queryClient = useQueryClient();

  return useMutation(
    (payload) => put(`/api/orders/${order.orderId}/financing/application`, payload),
    {
      throwOnError: true,
      retry: 3,
      onSuccess: () => {
        queryClient.removeQueries(cacheKeys.financingApplicationDetails);
        return queryClient.invalidateQueries(cacheKeys.financingApplication);
      },
    }
  );
}
