import React from 'react';
import { getPreferredPaymentMethod } from 'Core/utils/utils';
import useDepositForm from 'Modules/deposit/hooks/useDepositForm';
import CardSelector from 'Core/components/CardSelector';
import StyledFormGroup from 'Core/components/FormElements/StyledFormGroup';
import { PrimaryButton } from 'Core/components/Button';
import { useTranslation } from 'react-i18next';
import { Column } from 'Core/components/Layout';
import Row from 'Core/components/Layout/Row';
import Card from 'Core/components/FormElements/Card';
import { formatCurrency } from 'Core/utils/numberFormat';
import Option from 'Core/components/Option/Option';
import TermsInput from 'Core/components/FormElements/TermsInput';
import Checkbox from 'Core/components/FormElements/Checkbox';
import InputField from 'Core/components/FormElements/InputField';

function StyledOptionItem({ children, isFirst, isLast }) {
  let borderRadiusClasses = 'border rounded-lg';
  if (isFirst) {
    borderRadiusClasses = 'rounded-l-lg rounded-r-lg border-y border-l';
  }
  if (isLast) {
    borderRadiusClasses = 'rounded-l-lg rounded-r-lg border-y border-r';
  }

  return (
    <div
      className={`shadow-md flex flex-col items-center justify-between bg-white border-gray-300 ${borderRadiusClasses} p-2 mb-4 w-full transition-colors hover:bg-gray-100 hover:text-primary hover:cursor-pointer`}
    >
      {children}
    </div>
  );
}

function AuthorizeOptions({ options, form }) {
  const { register, unregister, getValues, setValue } = form;

  React.useEffect(() => {
    setValue('amount', '98');
    return () => unregister('amount');
  }, [setValue, unregister]);

  return (
    <Row data-testid="deposit-card-option-container">
      {options.map(({ option, amount }, idx, arr) => (
        <div className="md:flex-col-4" key={option}>
          <Option
            register={register}
            className="styled-deposit-option !flex"
            name="amount"
            value={amount}
            defaultChecked={getValues().amount === amount && getValues().type === 'authorize'}
          >
            <StyledOptionItem isFirst={idx === 0} isLast={idx === arr.length - 1}>
              <span className="text-2xl font-bold">
                {formatCurrency(amount, { withDecimals: false })}
              </span>
            </StyledOptionItem>
          </Option>
        </div>
      ))}
    </Row>
  );
}

function CaptureOption({ form }) {
  const { register, unregister, setValue } = form;
  React.useEffect(() => {
    setValue('amount', '');
    return () => unregister('amount');
  }, [setValue, unregister]);

  return (
    <Row>
      <Column className="md:flex-col-8">
        <InputField
          register={register}
          type="number"
          name={`amount`}
          error={form.formState.errors?.amount?.message}
          allowDecimals
          prependText={'$'}
        />
      </Column>
    </Row>
  );
}

const DepositOptions = ({ options, form }) => {
  const { t } = useTranslation();
  const { register, watch } = form;
  const type = watch('type');

  const cardMethods = [
    { id: 'HOLD', title: t('common.hold') },
    { id: 'CAPTURE', title: t('common.capture') },
  ];

  return (
    <StyledFormGroup>
      <Row className="items-end">
        <Column className="md:!flex-col-2 mr-4">
          <h4 className="font-semibold">{t('common:depositAmount')}</h4>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
            {cardMethods.map((notificationMethod) => (
              <div key={notificationMethod.id} className="flex items-center">
                <input
                  id={notificationMethod.id}
                  type="radio"
                  {...register('type')}
                  value={notificationMethod.id}
                  defaultChecked={type === notificationMethod.id}
                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                />
                <label
                  htmlFor={notificationMethod.id}
                  className="ml-3 block text-base font-medium text-gray-700"
                >
                  {notificationMethod.title}
                </label>
              </div>
            ))}
          </div>
        </Column>
        <Column className="md:!flex-col-6 mb-0">
          {type === 'HOLD' ? <AuthorizeOptions options={options} form={form} /> : null}
          {type === 'CAPTURE' ? <CaptureOption form={form} /> : null}
        </Column>
      </Row>
    </StyledFormGroup>
  );
};

export default function DepositCardForm({ paymentMethods, depositOptions, onSubmit, cardErrors }) {
  const { t } = useTranslation();
  const { paymentMethodId } = getPreferredPaymentMethod(paymentMethods);
  const [{ type, amount }] = depositOptions;

  const defaultValues = paymentMethodId
    ? { paymentMethodId, type, amount, terms: false }
    : {
        firstName: '',
        lastName: '',
        postalCode: '',
        cardNumber: '',
        cardExpiry: '',
        cardCvc: '',
        preferred: true,
        type,
        amount: 1,
        terms: false,
      };

  const form = useDepositForm(defaultValues);
  const {
    watch,
    handleSubmit,
    register,
    formState: { errors },
  } = form;

  const cardError = cardErrors?.[0]?.message;

  const termsTextId = watch('type') === 'authorize' ? 'terms.card.hold' : 'terms.card.charge';

  return (
    <form name="depositCardForm" onSubmit={handleSubmit(onSubmit)}>
      <StyledFormGroup error={cardError}>
        <CardSelector cardError={cardError} form={form} paymentMethods={paymentMethods}>
          <div className="flex mb-2 mt-2 justify-end">
            <Checkbox
              className="!mb-0"
              label={t('common.markAsPreferredCard')}
              name="preferred"
              register={register}
            />
          </div>
        </CardSelector>
      </StyledFormGroup>

      <DepositOptions options={depositOptions} form={form} />
      <Card>
        <TermsInput
          name="terms"
          register={register}
          errors={errors}
          label={t('common.termsAndConditions')}
        >
          <p>{t(termsTextId)}</p>
        </TermsInput>
      </Card>

      <Card>
        <Row className="flex content-center justify-between">
          <Column className="md:!flex-col-6">
            <InputField
              register={register}
              label={t('common.receiptEmail')}
              name={`email`}
              error={form.formState.errors?.email?.message}
            />
          </Column>
          <Column className="!flex-col-12 md:!flex-col-4 lg:!flex-col-3 pt-4">
            <PrimaryButton block={true} type="submit" data-testid="checkout" size="tall">
              {t('common.checkout')}
            </PrimaryButton>
          </Column>
        </Row>
      </Card>
    </form>
  );
}
