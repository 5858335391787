import React from 'react';
import Page from 'Core/components/Page';
import { useTranslation } from 'react-i18next';
import { useGetTransactionHistory } from 'Core/hooks/platform/useGetTransactionHistory';
import Loader from 'Core/components/Loader';
import { TransactionHistory } from 'Modules/cardPayment/components/TransactionHistory';
import { useGetSavedCards } from 'Core/hooks/platform/useGetSavedCards';
import DepositCardForm from 'Modules/cardPayment/components/DepositCardForm';
import Status from 'Core/components/Status';
import usePostCreatePaymentMethods from 'Core/hooks/queries/stripe/usePostCreatePaymentMethods';
import usePostCharge from 'Core/hooks/platform/usePostCharge';
import { toast } from 'react-toastify';
import { useScrollTop } from 'Core/hooks/useScrollTop';
import { useGetLatestTransaction } from 'Core/hooks/platform/useGetLatestTransaction';
import { useQueryClient } from 'react-query';
import cacheKeys from 'Core/hooks/queries/cacheKeys';
import { features, postLogMessage } from 'Core/utils/logger';
import { getUrlParam } from 'Core/utils/utils';

const depositOptions = [{ option: '98usd', type: 'HOLD', amount: 98 }];

export function Deposit() {
  const scroll = useScrollTop();
  const { t } = useTranslation();
  const [requestId, setRequestId] = React.useState('');
  const { data: transactionHistory } = useGetTransactionHistory();
  const { data: latestTransaction } = useGetLatestTransaction(requestId);
  const { data: savedCards } = useGetSavedCards();
  const { mutateAsync: createPaymentMethods } = usePostCreatePaymentMethods();
  const { mutateAsync: chargeCard } = usePostCharge();
  const [cardErrors, setCardErrors] = React.useState([]);
  const [status, setStatus] = React.useState({ type: '', text: '' });
  const queryClient = useQueryClient();
  const paymentId = getUrlParam('payment-id');

  React.useEffect(() => {
    if (latestTransaction?.length) {
      const [{ status: txnStatus, error_message, payment_method_id, amount }] = latestTransaction;

      if (['AUTHORIZED', 'PAID'].includes(txnStatus)) {
        setStatus({ type: 'success' });
        toast.success(t('status.success.paymentProcessed'));
        queryClient.invalidateQueries(cacheKeys.transactionHistory);
        postLogMessage({
          amount,
          feature: features.platformDeposit,
          status: 'success',
        });
      } else {
        setStatus({ type: 'error', text: t('status.error.failedToChargeCard') });
        setCardErrors([{ message: error_message, paymentMethodId: payment_method_id }]);
      }
      setRequestId('');
    }
  }, [latestTransaction, queryClient, t]);

  async function onSubmit(formData) {
    scroll.top();
    let paymentMethods;
    setStatus({ type: 'loading', text: t('status.loading.submitPaymentInformation') });
    setCardErrors([]);

    if (!formData.paymentMethodId) {
      const paymentMethodData = [
        {
          cardId: 'card0',
          amount: formData.amount,
          stripeElement: formData.stripeElement,
          billingDetails: {
            name: `${formData.firstName} ${formData.lastName}`,
            address: { postal_code: formData.postalCode },
          },
        },
      ];

      if (!formData.stripe) {
        return setStatus({ type: 'error', text: t('status.error.paymentGatewayInitialize') });
      }

      try {
        paymentMethods = await createPaymentMethods({
          stripe: formData.stripe,
          data: paymentMethodData,
        });
      } catch {
        setStatus({ type: 'error', text: t('status.error.failedToCreatePaymentMethods') });
        return;
      }
    }

    const paymentMethodId = formData.paymentMethodId || paymentMethods[0].paymentMethodId;

    try {
      const { request_id } = await chargeCard({
        receipt_email: formData.email,
        payments: {
          card_payment: [
            {
              amount: formData.amount,
              payment_method_id: paymentMethodId,
              card_action: formData.type,
            },
          ],
        },
      });

      setRequestId(request_id);
    } catch (error) {
      const detailedErrorMessage = error?.message || t('status.error.failedToChargeCard');
      setStatus({ type: 'error', text: detailedErrorMessage });
    }
  }

  if (!transactionHistory || !savedCards) {
    return <Loader message={t('status.loading')} />;
  }

  const shouldShowForm = !transactionHistory.find(({ payment_id }) => payment_id === paymentId);

  return (
    <Page title={t('page.deposit.checkout.header')} withStepProgress={false}>
      <Status {...status} />
      <TransactionHistory transactions={transactionHistory} />
      {shouldShowForm && (
        <DepositCardForm
          paymentMethods={savedCards}
          depositOptions={depositOptions}
          onSubmit={onSubmit}
          cardErrors={cardErrors}
        />
      )}
    </Page>
  );
}
