import React from 'react';
import { useTranslation } from 'react-i18next';
import { useShowSidebar } from 'Core/hooks/useShowSidebar';
import Header from 'Core/components/Header';
import Main from 'Core/components/Main';
import { GlobalPageAlert } from 'Core/components/Alert';
import HeaderBanner from 'Core/components/HeaderBanner';
import FooterBanner from 'Core/components/FooterBanner';
import StickyFooter from 'Core/components/StickyFooter';
import Loader from 'Core/components/Loader';
import { useSearch } from 'react-location';

const ShoppingCart = React.lazy(() => import('Core/components/ShoppingCart'));
const StepProgress = React.lazy(() => import('Core/components/StepProgress'));

const Page = ({
  children,
  title,
  subtitle,
  stepProgressProps = {},
  withStepProgress = true,
  withSidebar = false,
  withCardPrefCenterLink = false,
  stickyFooter = false,
}) => {
  const { t } = useTranslation();
  const shouldShowSidebar = useShowSidebar();
  const fallback = <Loader message={t('status.loading.component')} />;
  const { embed: isEmbedded = false } = useSearch();

  if (withSidebar && shouldShowSidebar) {
    return (
      <React.Suspense fallback={fallback}>
        {!isEmbedded && <HeaderBanner />}
        <Header title={title} withCardPrefCenterLink={withCardPrefCenterLink} />
        <Main>
          {withStepProgress && <StepProgress {...stepProgressProps} />}
          <div className="grid gap-4 [grid-template-columns:1fr_min-content]">
            <div className="grow-[2]">
              <GlobalPageAlert />
              {children}
            </div>
            <div className="flex-grow relative [min-width:380px] [max-width:440px]">
              <div className="sticky top-4">
                <ShoppingCart withElevation />
              </div>
            </div>
          </div>
        </Main>
        {!isEmbedded && <FooterBanner />}
      </React.Suspense>
    );
  }

  return (
    <>
      {!isEmbedded && <HeaderBanner />}
      <Header title={title} subtitle={subtitle} withCardPrefCenterLink={withCardPrefCenterLink} />
      <Main className={stickyFooter ? 'mb-16' : ''}>
        {withStepProgress && <StepProgress {...stepProgressProps} />}
        <GlobalPageAlert />
        {children}
      </Main>
      {!isEmbedded && stickyFooter ? <StickyFooter /> : <FooterBanner />}
    </>
  );
};

export default Page;
