import { usStates } from 'Modules/creditDebit/config/usStates';
export default function getStateAbbreviation(input) {
  if (!input || !input.toLowerCase()) return input;
  const stateByLabel = usStates.find((state) => input.toLowerCase() === state.label.toLowerCase());
  const stateByValue = usStates.find((state) => input.toLowerCase() === state.value.toLowerCase());
  if (stateByValue) {
    return input;
  } else if (stateByLabel) {
    return stateByLabel.value;
  }
  return input;
}
