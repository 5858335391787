import { Alert } from 'Core/components/Alert';
import { OutlineButton, PrimaryButton } from 'Core/components/Button';
import { Modal } from 'Core/components/Layout';
import React from 'react';

export default function UpdateAchModal({ isModalOpen, onModalClose, onModalConfirm }) {
  const handleConfirm = (e) => {
    if (onModalConfirm) {
      onModalConfirm(e);
    }
  };
  return (
    <Modal isOpen={isModalOpen}>
      <h3 className="font-bold text-center">Add ACH?</h3>
      <div className="p-6 text-center" data-testid="title-autopay-redirect">
        You can update the payment method to potentially lower your APR. Do you want to navigate to
        the Allegro portal to update it?
      </div>

      <div className="">
        <Alert type="shade" data-testid="note-autopay-redirect">
          <b>Note: </b>
          <span>
            If you click on <em className="font-bold">Add AutoPay</em> you will be redirected to the
            Allegro portal. Please add ACH information and return to this page thereafter.
          </span>
        </Alert>
      </div>
      <hr></hr>
      <div className="flex items-end justify-end gap-4">
        <OutlineButton type="button" onClick={onModalClose} data-testid="btn-skip-autopay">
          Skip Autopay and Finalize Contract
        </OutlineButton>
        <PrimaryButton type="button" onClick={handleConfirm} data-testid="btn-add-autopay">
          Add AutoPay
        </PrimaryButton>
      </div>
    </Modal>
  );
}
