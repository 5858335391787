import classnames from 'classnames';
import React from 'react';
import paperworkIllustration from 'Images/illustrations/paperwork.svg';
import { useTranslation } from 'react-i18next';
import { useScrollTop } from 'Core/hooks/useScrollTop';
import Page from 'Core/components/Page';
import Card from 'Core/components/FormElements/Card';
import { FaCheck, FaExclamation as CloseIcon } from 'react-icons/fa';
export default function ReturnTracking({ nextAction }) {
  document.title = 'Payment Portal - Financing';
  const { t } = useTranslation();
  useScrollTop();
  const [steps, setSteps] = React.useState([
    { id: 'RETURN_REQUESTED', isHidden: false, isActive: false, isComplete: false },
    { id: 'RETURN_PROCESSING', isHidden: false, isActive: false, isComplete: false },
    { id: 'RETURN_APPROVED', isHidden: false, isActive: false, isComplete: false },
    { id: 'RETURN_COMPLETE', isHidden: false, isActive: false, isComplete: false },
    { id: 'RETURN_CANCELED', isHidden: true, isActive: false, isComplete: false },
  ]);
  const [activeStep, setActiveStep] = React.useState({});
  React.useEffect(() => {
    setSteps((currentSteps) => {
      const tempSteps = [...currentSteps];
      const activeStepIndex = tempSteps.findIndex((step) => step.id === nextAction);
      tempSteps.forEach((step, idx) => {
        if (step.id === 'RETURN_COMPLETE' || step.id === 'RETURN_APPROVED') {
          step.isHidden = nextAction === 'RETURN_CANCELED';
        }

        if (step.id === 'RETURN_CANCELED') {
          step.isHidden = nextAction !== 'RETURN_CANCELED';
        }
        if (idx === activeStepIndex) {
          step.isActive = true;
          step.isHidden = false;
          step.isComplete = false;
        } else if (idx < activeStepIndex) {
          step.isActive = false;
          step.isComplete = true;
        } else {
          step.isActive = false;
          step.isComplete = false;
        }
      });
      return tempSteps;
    });
  }, [nextAction, setActiveStep]);

  React.useEffect(() => {
    setActiveStep(steps.find((step) => step.isActive));
  }, [steps]);

  function StepIcon({ id, isActive, isComplete }) {
    if (isComplete) {
      return <FaCheck color="white" />;
    } else if (isActive) {
      if (id === 'RETURN_CANCELED') {
        return <CloseIcon color="white" />;
      }
      return <FaCheck color="white" />;
    }
  }
  return (
    <Page title="Financing" withStepProgress={false} stickyFooter>
      <Card className="justify-center mt-8">
        <div className="p-8">
          <div
            data-testid="step-progress-container"
            className="flex flex-nowrap justify-between mb-4 p-0"
          >
            {steps
              .filter((s) => !s.isHidden)
              .map(({ id, isActive, isComplete }) => {
                const testid = isActive ? `step-progress-active` : `step-progress`;

                return (
                  <div
                    className={classnames(
                      "flex flex-col items-center justify-center relative w-full text-sm whitespace-nowrap before:content-[''] before:border-t-2 before:border-gray-300 before:absolute before:[right:calc(50%_+_15px)] before:[top:15px] before:[width:calc(100%_-_30px)] first-of-type:before:border-0 before:border-solid"
                    )}
                    key={id}
                    data-testid={`return-step-${id}`}
                  >
                    <div
                      className={classnames(
                        'border-2 border-solid border-gray-300 rounded-full flex justify-center items-center font-bold [height:30px] [width:30px] [line-height:26px]',
                        {
                          'bg-green-700 text-white': id !== 'RETURN_CANCELED' && isActive,
                          'bg-gray-200': !isActive && !isComplete,
                          'bg-green-400': isComplete,
                          'bg-red-500': id === 'RETURN_CANCELED',
                        }
                      )}
                      data-testid={testid}
                    >
                      <StepIcon id={id} isActive={isActive} isComplete={isComplete} />
                    </div>
                    <div
                      data-testid="step-label"
                      className={classnames('hidden sm:flex mt-4 text-sm md:text-lg', {
                        'font-semibold': isActive,
                        ' font-extralight': !isActive,
                      })}
                    >
                      {t(`page.financing.${id}.title`)}
                    </div>
                  </div>
                );
              })}
          </div>
          {activeStep && (
            <div className="flex flex-col items-center w-100">
              <img
                className="mt-6"
                alt="application-declined"
                width="150em"
                src={paperworkIllustration}
              ></img>
              <h3 className="mt-8 text-2xl">{t(`page.financing.${nextAction}.title`)}</h3>
              <p className="mt-4 text-regular text-gray-500">
                {t(`page.financing.${nextAction}.description`)}
              </p>
            </div>
          )}
        </div>
      </Card>
    </Page>
  );
}
