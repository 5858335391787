import React from 'react';
import { useTranslation } from 'react-i18next';
import { useScrollTop } from 'Core/hooks/useScrollTop';
import { useNavigate, Navigate } from 'react-location';
import { Alert, AlertError } from 'Core/components/Alert';
import Card from 'Core/components/FormElements/Card';
import Footer from 'Core/components/Footer';
import { PrimaryButton } from 'Core/components/Button';
import Checklist from 'Modules/creditDebit/components/Checklist';

import Page from 'Core/components/Page';
import Loader from 'Core/components/Loader';
import { createFinancingPath, paymentOptionsPath } from 'Modules/financing/routes';

import { useGetNextStep } from 'Modules/financing/hooks/useGetNextStep';

const Financing = ({ nextRoutes }) => {
  useScrollTop();
  const navigate = useNavigate();
  const { t } = useTranslation();
  // const shouldShowSidebar = useShowSidebar();
  const [state, setState] = React.useState({
    refetchInterval: false,
    financingWindow: null,
    approved: 'done',
    approvedSigned: 'idle',
    funded: 'idle',
    isError: false,
  });
  const {
    data: {
      next_action,
      payload: {
        links: { cart: redirection_link },
      },
      isError,
    },
  } = useGetNextStep(state.refetchInterval);

  React.useEffect(() => {
    if (!next_action) {
      return;
    }

    if (isError) {
      setState({
        refetchInterval: 5000,
        approvedSigned: 'error',
        funded: 'error',
      });
      return;
    } else if (next_action === 'FUND_APPLICATION') {
      setState({
        ...state,
        approvedSigned: 'done',
      });
    } else if (next_action === 'DONE') {
      setState({
        ...state,
        approvedSigned: 'done',
        funded: 'done',
      });
      return;
    } else if (next_action === 'PENDING_DOWN_PAYMENT') {
      setState({
        ...state,
        approvedSigned: 'done',
        funded: 'done',
      });
      return;
    }

    if (next_action === 'FUNDED') {
      navigate({ to: nextRoutes.success, search: true });
    } else if (next_action === 'DECLINED') {
      navigate({ to: nextRoutes.error, search: true });
    }
  }, [isError, state, next_action, nextRoutes.success, nextRoutes.error, navigate]);

  if (!next_action) {
    return null;
  }

  const openFinancingWindow = (e) => {
    e.preventDefault();
    const financingWindow = window.open(redirection_link, '_blank');

    setState({
      ...state,
      refetchInterval: 5000,
      financingWindow,
      approvedSigned: 'loading',
      funded: 'loading',
    });
  };

  const checklistItems = [
    { label: t('page.financing.checklist.approved'), status: state.approved },
    { label: t('page.financing.checklist.approvedSigned'), status: state.approvedSigned },
    { label: t('page.financing.checklist.funded'), status: state.funded },
  ];

  // TODO find a better solution for this ...
  const infobox = (
    <Alert type="infoLight" data-testid="infobox" showIcon>
      {t('page.financing.financingApproved.infobox')}
    </Alert>
  );
  const mixedQuestion = '';

  return (
    <Page title={t('page.financing.header')} withStepProgress={false} stickyFooter>
      {state.isError && (
        <AlertError>{t('status.error.failedToRequestFinancingApplicationState')}</AlertError>
      )}
      {infobox}

      <Card>
        <p>{t('page.financing.financingApproved.intro')}</p>

        <div className={`text-center`}>
          <Checklist items={checklistItems} />
        </div>

        {next_action !== 'DONE' && next_action !== 'PENDING_DOWN_PAYMENT' && (
          <div className="py-2 text-center w-1/3 m-auto mt-4">
            <PrimaryButton
              block
              tall
              onClick={openFinancingWindow}
              data-testid="finalize-financing"
            >
              {t('page.financing.openFinancingPartnerWindow')}
            </PrimaryButton>
          </div>
        )}

        {next_action === 'PENDING_DOWN_PAYMENT' && (
          <Card type="warning" className=" bg-yellow-200 mt-8 mb-8">
            <div className="flex justify-between items-center">
              You have a pending down payment of $xxx. Please collect this as soon as possible.
              <PrimaryButton onClick={openFinancingWindow} data-testid="collect-payment">
                Collect Payment
              </PrimaryButton>
            </div>
          </Card>
        )}

        <hr />

        <p>
          <b>{t('page.financing.whereIsTheTab.question')}</b>
          <br />
          {t('page.financing.whereIsTheTab.answer')}
        </p>
        <p>
          <b>{t('page.financing.troubleFinalizing.question')}</b>
          <br />
          {t('page.financing.troubleFinalizing.answer')}
        </p>
        {mixedQuestion}
      </Card>
      <Footer
        hasPrev={false}
        labelPrev={t('common.back')}
        onPrev={() => navigate({ to: paymentOptionsPath, search: true })}
      />
    </Page>
  );
};

// TODO solve this hack!
const FinancingApplicationCheck = (props) => {
  const { t } = useTranslation();

  const { data, isLoading, isError } = useGetNextStep();

  if (isLoading) {
    return <Loader message={t('status.loading.financingApplication')} />;
  }

  if (isError) {
    return <Navigate to={createFinancingPath} search={true} />;
  }

  if (data?.next_action !== 'UPDATE_OFFER' && data?.next_action !== 'SIGN_CONTRACT') {
    return <Navigate to={createFinancingPath} search={true} />;
  }

  return <Financing {...props} />;
};

export default FinancingApplicationCheck;
