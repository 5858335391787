import React from 'react';
import classnames from 'classnames';

export const StyledInputGroup = ({ children, block }) => (
  <div className={`relative flex flex-nowrap ${block ? 'w-full' : ''}`}>{children}</div>
);

export const StyledInputGroupText = ({ className, children }) => (
  <span
    className={classnames(
      'flex h-9 p-1.5 py-1.5 px-3 items-center mb-0 text-base font-normal text-gray-600 text-center whitespace-nowrap bg-gray-200 border border-solid rounded border-gray-300',
      className
    )}
  >
    {children}
  </span>
);

export const StyledFormSection = ({ children }) => (
  <div className="bg-gray-100 border border-solid border-gray-300 rounded mb-4 p-4 transition-colors">
    {children}
  </div>
);

export const StyledFormElement = ({ className, children }) => (
  <div className={classnames('styled-form-element w-full', className)}>{children}</div>
);
