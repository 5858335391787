import { useMutation, useQueryClient } from 'react-query';
import cacheKeys from 'Core/hooks/queries/cacheKeys';
import { post } from 'Core/utils/api';

export default function usePostChargeCard() {
  const queryClient = useQueryClient();

  return useMutation((payload) => post(`/api/pay`, payload), {
    throwOnError: true,
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries(cacheKeys.directSalesPaymentOptions),
        queryClient.invalidateQueries(cacheKeys.paymentHistory),
      ]);
    },
  });
}
