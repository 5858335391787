import React from 'react';
import { useTranslation } from 'react-i18next';
import { useClipboard } from 'use-clipboard-copy';
import { PrimaryButton } from 'Core/components/Button';
import { StyledFormElement } from 'Core/components/FormElements/formElements.styled';

const CopyField = ({ value, testid }) => {
  const { t } = useTranslation();
  const clipboard = useClipboard({
    copiedTimeout: 3000,
  });

  return (
    <div className="flex">
      <StyledFormElement className="!mr-1" size="small">
        <input
          aria-label={t('aria.paymentUrl')}
          data-testid={`${testid}Field`}
          value={value}
          ref={clipboard.target}
          readOnly
        />
      </StyledFormElement>
      <PrimaryButton
        size="small"
        data-testid={`${testid}Button`}
        data-toggle="button"
        aria-pressed={clipboard.copied ? 'true' : 'false'}
        onClick={clipboard.copy}
      >
        {clipboard.copied ? t('common.copied') : t('common.copy')}
      </PrimaryButton>
    </div>
  );
};

export default CopyField;
