import React from 'react';
import { Link } from 'react-location';
import { Container } from 'Core/components/Layout';
import { cardPreferenceCenterPath } from 'Modules/deposit/routes';

export default function Header({ title, subtitle, withCardPrefCenterLink }) {
  return (
    <header className="bg-primary text-white px-4 mb-4 h-12">
      <Container className={`!mb-0 !h-full !px-4 !flex !items-center !justify-between`}>
        <div className="flex h-full flex-col">
          <h2 className="m-0" data-testid="header-headline">
            {title}
          </h2>
          <div className="text-gray-300 text-sm">{subtitle}</div>
        </div>
        {withCardPrefCenterLink && (
          <Link data-testid="card-pref-center-link" to={cardPreferenceCenterPath} search={true}>
            Card Preference Center
          </Link>
        )}
      </Container>
    </header>
  );
}
