import React from 'react';
import classnames from 'classnames';

export default function Column({ className, noFlex, children }) {
  const flexStyles = 'flex-col-12';
  return (
    <div className={classnames('relative px-4 mb-4', { [flexStyles]: !noFlex }, className)}>
      {children}
    </div>
  );
}
