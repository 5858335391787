import React from 'react';
import PropTypes from 'prop-types';
import Item from './Item';

const CartItems = ({ items }) => {
  return (
    <div>
      {items.map((item, index) => (
        <Item
          key={`cart-item-${index}`}
          label={item.description}
          quantity={item.quantity}
          amount={item.amount}
        />
      ))}
    </div>
  );
};

CartItems.propTypes = {
  items: PropTypes.array,
};

export default CartItems;
