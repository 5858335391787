import React from 'react';
import Page from 'Core/components/Page';
import { useTranslation } from 'react-i18next';
import { useGetTransactionHistory } from 'Core/hooks/platform/useGetTransactionHistory';
import Loader from 'Core/components/Loader';
import { TransactionHistory } from 'Modules/cardPayment/components/TransactionHistory';
import { useGetSavedCards } from 'Core/hooks/platform/useGetSavedCards';
import Status from 'Core/components/Status';
import usePostCreatePaymentMethods from 'Core/hooks/queries/stripe/usePostCreatePaymentMethods';
import usePostCharge from 'Core/hooks/platform/usePostCharge';
import { toast } from 'react-toastify';
import { useScrollTop } from 'Core/hooks/useScrollTop';
import { useGetLatestTransaction } from 'Core/hooks/platform/useGetLatestTransaction';
import { useGetNextStep } from 'Modules/financing/hooks/useGetNextStep';
import { useQueryClient } from 'react-query';
import cacheKeys from 'Core/hooks/queries/cacheKeys';
import { useGetPaymentInfo } from 'Core/hooks/platform/useGetPaymentInfo';
import PaymentCardForm from 'Modules/cardPayment/components/PaymentCardForm';
import { useStripe } from '@stripe/react-stripe-js';
import { features, postLogMessage } from 'Core/utils/logger';
import { getUrlParam } from 'Core/utils/utils';
import AutoCloseNotifier from 'Modules/financing/components/AutoCloseNotifier';

export function Payment({ paymentType }) {
  document.title = 'Payment Portal - Payment';
  const AUTO_CLOSE_TIME_MS = 8000;
  const scroll = useScrollTop();
  const stripe = useStripe();
  const { t } = useTranslation();
  const [requestId, setRequestId] = React.useState('');
  const { data: paymentInfo } = useGetPaymentInfo();
  const { data: transactionHistory } = useGetTransactionHistory();
  const { data: latestTransaction } = useGetLatestTransaction(requestId);
  const { data: savedCards } = useGetSavedCards();
  const { mutateAsync: createPaymentMethods } = usePostCreatePaymentMethods();
  const { mutateAsync: chargeCard } = usePostCharge();
  const [cardErrors, setCardErrors] = React.useState([]);
  const [status, setStatus] = React.useState({ type: '', text: '' });
  const queryClient = useQueryClient();
  const paymentId = getUrlParam('payment-id');
  const [cardIds, setCardIds] = React.useState([]);
  const { data: { payload: { financing_down_payment = null } = {} } = {} } = useGetNextStep();
  const [isDownPaymentSuccess, setIsDownPaymentSuccess] = React.useState(false);

  React.useEffect(() => {
    if (latestTransaction?.length) {
      let totalAmount = 0;
      const errors = [];

      //loop through card ids, as latest transactions doesn't maintain the same order of cards in the UI.
      cardIds.forEach((id) => {
        const {
          status: txnStatus,
          error_message,
          payment_method_id,
          amount,
        } = latestTransaction.find((txn) => txn.payment_method_id === id);

        totalAmount += amount;
        if (['AUTHORIZED', 'PAID'].includes(txnStatus)) {
          errors.push({});
        }
        errors.push({ message: error_message, paymentMethodId: payment_method_id });
      });

      const isSuccess = latestTransaction.every(({ status: txnStatus }) =>
        ['AUTHORIZED', 'PAID'].includes(txnStatus)
      );

      if (isSuccess) {
        if (paymentType === 'downPayment') {
          // setIsDownPaymentSuccess(true);
          setTimeout(() => {
            window.close();
          }, AUTO_CLOSE_TIME_MS);
        }
        toast.success(t('status.success.paymentProcessed'));
        setStatus({ type: 'success' });
        postLogMessage({
          amount: totalAmount,
          feature: features.platformPayment,
          status: 'success',
        });
      } else {
        setStatus({ type: 'error', text: t('status.error.failedToChargeCard') });
        setCardErrors(errors);
      }
      queryClient.invalidateQueries(cacheKeys.transactionHistory);
      setRequestId('');
    }
  }, [cardIds, latestTransaction, queryClient, t, paymentType]);

  React.useEffect(() => {
    if (paymentInfo?.purpose === 'FINANCING') {
      if (transactionHistory && transactionHistory.length) {
        const totalTransactionAmount = transactionHistory.reduce(
          (acc, { amount }) => acc + amount,
          0
        );
        if (totalTransactionAmount >= financing_down_payment) {
          setIsDownPaymentSuccess(true);
          setTimeout(() => {
            window.close();
          }, AUTO_CLOSE_TIME_MS);
        }
      }
    }
  }, [financing_down_payment, paymentType, transactionHistory, paymentInfo?.purpose]);

  async function onSubmit(formData) {
    scroll.top();
    let paymentMethods;
    setStatus({ type: 'loading', text: t('status.loading.submitPaymentInformation') });
    setCardErrors([]);

    const paymentMethodData = formData.cards.map((card, index) => {
      const amount = index === 0 ? +card.amount + formData.decimals : +card.amount;
      if (card.paymentMethodId) {
        return {
          amount,
          paymentMethodId: card.paymentMethodId,
        };
      }

      return {
        amount,
        stripeElement: card.stripeElement,
        billingDetails: {
          name: `${card.firstName} ${card.lastName}`,
          address: { postal_code: card.postalCode },
        },
      };
    });

    try {
      paymentMethods = await createPaymentMethods({
        stripe,
        data: paymentMethodData,
      });
    } catch {
      return setStatus({ type: 'error', text: t('status.error.failedToCreatePaymentMethods') });
    }

    setCardIds(paymentMethods.map(({ paymentMethodId }) => paymentMethodId));

    try {
      const { request_id } = await chargeCard({
        receipt_email: formData.email,
        payments: {
          card_payment: paymentMethods.map((method) => ({
            amount: method.amount,
            payment_method_id: method.paymentMethodId,
            card_action: 'CAPTURE',
          })),
        },
      });

      setRequestId(request_id);
    } catch (error) {
      const detailedErrorMessage = error?.message || t('status.error.failedToChargeCard');
      setStatus({ type: 'error', text: detailedErrorMessage });
    }
  }

  if (!transactionHistory || !savedCards || !paymentInfo) {
    return <Loader message={t('status.loading')} />;
  }

  const isPaymentAlreadyMade = !transactionHistory.find(
    ({ payment_id }) => payment_id === paymentId
  );

  let amountToPay = paymentInfo.amount_to_pay - paymentInfo.already_paid_amount;

  const isFinalAmountToPayZero = amountToPay === 0;
  const shouldShowForm = isPaymentAlreadyMade && !isFinalAmountToPayZero;

  if (paymentType === 'downPayment') {
    amountToPay = financing_down_payment;
  }

  const transactionHistoryWithoutHolds = transactionHistory.filter(
    (item) => item.card_action !== 'HOLD'
  );

  return (
    <Page title={t(`common.${paymentType}`)} withStepProgress={false} stickyFooter>
      <Status {...status} />
      <TransactionHistory transactions={transactionHistoryWithoutHolds} />
      {paymentType === 'downPayment' && isDownPaymentSuccess && (
        <AutoCloseNotifier duration={AUTO_CLOSE_TIME_MS}>
          {t('downpayment.redirecting')}
        </AutoCloseNotifier>
      )}
      {((paymentType === 'downPayment' && !isDownPaymentSuccess) ||
        (paymentInfo?.purpose !== 'FINANCING' && shouldShowForm) ||
        (paymentInfo?.purpose === 'FINANCING' && !isDownPaymentSuccess)) && (
        <PaymentCardForm
          paymentMethods={savedCards}
          onSubmit={onSubmit}
          cardErrors={cardErrors}
          totalAmount={amountToPay}
        />
      )}
    </Page>
  );
}
