import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CloseIcon from 'Modules/deposit/components/CloseIcon';

const styles = {
  base: 'whitespace-pre-line border rounded-md px-4 py-3 mb-4 flex fill-slate-400 relative',
  default: 'bg-white',
  success: 'bg-success text-white ',
  successLight: 'bg-green-100 text-green-800 fill-green-800',
  warning: 'bg-yellow-300',
  warningLight: 'bg-[#FEF9E9] fill-[#F9C12D]',
  error: 'bg-red-100 text-red-800 border-red-400',
  info: 'bg-blue-400 text-white',
  infoLight: 'bg-blue-100 text-blue-800 fill-blue-500',
  shade: 'bg-gray-300',
};

export function Alert({
  type = 'default',
  className,
  showIcon = false,
  icon,
  children,
  dismissable,
  ...rest
}) {
  const [hideAlert, setHideAlert] = React.useState(false);
  const iconElement = () => {
    if (icon) {
      return icon;
    }
    return (
      <div className="pt-0.5">
        <svg className="mr-4 h-5 w-5 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
          <path d="M31.97,64.02C14.33,64-.04,49.6,0,31.97,.04,14.31,14.44-.05,32.06,0c17.63,.05,31.95,14.4,31.94,32.02,0,17.65-14.4,32.03-32.03,32Zm-.02-23.58c1,0,2,0,3.01,0,.92,0,1.05-.14,1.1-1.03,.05-.86,.11-1.72,.18-2.58,.31-4.2,.63-8.4,.94-12.61,.21-2.83,.42-5.66,.65-8.5,.2-2.46-.61-4.48-2.72-5.81-2-1.26-4.12-1.28-6.14-.05-2.13,1.3-2.99,3.3-2.81,5.76,.26,3.39,.53,6.78,.78,10.17,.34,4.6,.67,9.2,1,13.79,.04,.61,.32,.87,.93,.86,1.02-.02,2.05,0,3.08,0Zm.04,4.96c-2.68,0-4.85,2.17-4.85,4.83,0,2.61,2.2,4.81,4.81,4.83,2.68,.02,4.89-2.21,4.87-4.9-.03-2.65-2.17-4.76-4.83-4.76Z" />
        </svg>
      </div>
    );
  };

  const dismissAlert = () => {
    setHideAlert(true);
  };

  return (
    <div
      className={classnames(styles.base, styles[type], className, { hidden: hideAlert })}
      {...rest}
    >
      {showIcon && iconElement}
      <span className="mb-0 mt-0 w-full"> {children}</span>
      {dismissable && (
        <button type="button" className="absolute right-2 top-2 left-auto" onClick={dismissAlert}>
          <CloseIcon />
        </button>
      )}
    </div>
  );
}

Alert.propTypes = {
  'data-testid': PropTypes.string,
  children: PropTypes.any.isRequired,
  type: PropTypes.oneOf(['success', 'warning', 'error', 'info', 'default']),
};

export const AlertSuccess = ({ children, ...rest }) => (
  <Alert data-testid="alert-success" type="success" {...rest}>
    {children}
  </Alert>
);
export const AlertWarning = ({ children, ...rest }) => (
  <Alert data-testid="alert-warning" type="warning" {...rest}>
    {children}
  </Alert>
);
export const AlertError = ({ children, ...rest }) => (
  <Alert data-testid="alert-error" type="error" {...rest}>
    {children}
  </Alert>
);
export const AlertInfo = ({ children, ...rest }) => (
  <Alert data-testid="alert-info" type="info" {...rest}>
    {children}
  </Alert>
);
