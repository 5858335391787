import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import useAlertStore, { setErrorAlertSelector } from 'Core/stores/alert';
import cacheKeys from 'Core/hooks/queries/cacheKeys';
import { put } from 'Core/utils/api';

export default function usePutPaymentOption() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const setErrorAlert = useAlertStore(setErrorAlertSelector);

  return useMutation(
    (option) => {
      const { orderId } = queryClient.getQueryData('order');
      return put(`/api/payment-options`, { orderId, option });
    },
    {
      onSuccess() {
        return Promise.all([
          queryClient.invalidateQueries(cacheKeys.paymentSummary),
          queryClient.invalidateQueries(cacheKeys.order),
        ]);
      },
      onError() {
        setErrorAlert(t('status.error.putPaymentOptionQueryFailed'));
      },
    }
  );
}
