import React from 'react';
import { useTranslation } from 'react-i18next';
import { useScrollTop } from 'Core/hooks/useScrollTop';
import { Alert, AlertError } from 'Core/components/Alert';
import Page from 'Core/components/Page';
import OrderData from 'Core/components/Data/OrderData';

const FinancingError = () => {
  useScrollTop();
  const { t } = useTranslation();

  return (
    <OrderData>
      <Page title={t('page.financing.header')}>
        <AlertError>{t('page.financing.financingDeclined.infobox')}</AlertError>

        <Alert>
          <p>{t('page.financing.financingDeclined.intro')}</p>

          <hr />
        </Alert>
      </Page>
    </OrderData>
  );
};

export default FinancingError;
