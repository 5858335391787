import React from 'react';
import PropTypes from 'prop-types';
import { StyledIndicator } from 'Modules/creditDebit/components/Checklist/Checklist.styled';
import classnames from 'classnames';

const backgroundStyles = (color) => {
  if (color === 'green') return `bg-success`;
  if (color === 'gray') return `bg-gray-300`;
  return `bg-primary`;
};
const IndicatorCheckmark = ({ color, className = '' }) => {
  return (
    <StyledIndicator color={color}>
      <div
        className={
          className +
          ' h-7 w-7 inline-block origin-center relative [transform:rotate(45deg)] [top:5px] [left:-4px]'
        }
      >
        <div
          className={classnames(
            'absolute [height:14px] [width:2px] [top:0] [left:11px]',
            backgroundStyles(color)
          )}
        />
        <div
          className={classnames(
            'absolute [height:2px] [width:5px] [top:12px] [left:6px]',
            backgroundStyles(color)
          )}
        />
      </div>
    </StyledIndicator>
  );
};

IndicatorCheckmark.propTypes = {
  color: PropTypes.oneOf(['gray', 'green', 'orange', 'red']),
};

export default IndicatorCheckmark;
