import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { get } from 'Core/utils/platformApi';

import cacheKeys from 'Core/hooks/queries/cacheKeys';
import useErrorStore, { setErrorActionSelector } from 'Core/stores/error';
import { errorPath } from 'Core/routes';
import { useNavigate } from 'react-location';
import { actions, features, postLogMessage } from 'Core/utils/logger';

export default function useGetApplication(requestId, refetchInterval = false) {
  const { t } = useTranslation();
  const setError = useErrorStore(setErrorActionSelector);

  const navigate = useNavigate();
  const validator = (data) => Boolean(data) && Boolean(data?.next_action);
  return useQuery(
    [cacheKeys.financingPlatformApplication],
    () => get(`/application`, { headers: { request_id: requestId } }, validator),
    {
      enabled: Boolean(requestId),
      retry: 30,
      retryDelay: 3000,
      refetchInterval,
      onError(e) {
        if (e?.response?.status !== 404) {
          postLogMessage({
            feature: features.createAllegroApp,
            action: actions.createApplicationTimeout,
          });
          const message = e?.message ? ` - ${e?.message}` : '';
          setError(t('status.error.getFinancingApplicationFailed') + message);
          navigate({ to: errorPath, search: true });
        }
      },
    }
  );
}
