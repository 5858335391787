import React from 'react';
import Error from 'Core/components/FormElements/Error';
import {
  StyledFormElement,
  StyledInputGroup,
  StyledInputGroupText,
} from 'Core/components/FormElements/formElements.styled';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const InputField = (props) => {
  const {
    block,
    prependText,
    appendText,
    onAppendClick = null,
    className,
    error = '',
    errors = {},
    register = () => {},
    name = '',
    id = name,
    label,
    type = 'text',
    appendTestId = '',
    maxLength = 64,
    onPaste,
    allowDecimals,
    ...rest
  } = props;

  const disableScroll = (e) => {
    if (e.currentTarget.type === 'number') {
      e.target.blur();
    }
  };
  const errorMessage = error || errors[name]?.message;
  const testid = props['data-testid'] || id;

  return (
    <StyledFormElement className={className}>
      {label && <label htmlFor={id}>{label}</label>}
      <StyledInputGroup block={block}>
        {prependText ? (
          <div className={`-mr-px`}>
            <StyledInputGroupText className={`rounded-r-none`}>{prependText}</StyledInputGroupText>
          </div>
        ) : null}
        <input
          onWheel={disableScroll}
          onPaste={onPaste}
          id={id}
          name={name}
          type={type}
          step={allowDecimals ? 'any' : null}
          aria-label={label}
          data-testid={testid}
          maxLength={maxLength}
          {...register(name)}
          {...rest}
          className={classnames({
            '!rounded-l-none': Boolean(prependText) === true,
            '!rounded-l': Boolean(prependText) === false,
            '!rounded-r-none': Boolean(appendText) === true,
            '!rounded-r': Boolean(appendText) === false,
          })}
        />
        {appendText && onAppendClick && (
          <button type="button" className={`-ml-px`} onClick={onAppendClick}>
            <StyledInputGroupText
              className={classnames({
                'rounded-l-none': true,
                'cursor-pointer': Boolean(onAppendClick),
              })}
              data-testid={appendTestId}
            >
              {appendText}
            </StyledInputGroupText>
          </button>
        )}

        {appendText && !onAppendClick && (
          <div className={`-ml-px`}>
            <StyledInputGroupText
              className={classnames({
                'rounded-l-none': true,
                'cursor-pointer': Boolean(onAppendClick),
              })}
              data-testid={appendTestId}
            >
              {appendText}
            </StyledInputGroupText>
          </div>
        )}
      </StyledInputGroup>
      <Error message={errorMessage} show={Boolean(errorMessage)} />
    </StyledFormElement>
  );
};

InputField.propTypes = {
  prependText: PropTypes.string,
  appendText: PropTypes.string,
  errors: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  type: PropTypes.string,
};

export default InputField;
