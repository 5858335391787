import React from 'react';
import useAlertStore, { alertSelector } from 'Core/stores/alert';
import { Alert } from 'Core/components/Alert/index';
import { useScrollTop } from 'Core/hooks/useScrollTop';

export default function GlobalPageAlert() {
  useScrollTop();
  const alert = useAlertStore(alertSelector);

  if (!alert) return null;

  return (
    <Alert data-testid={`alert-${alert.type}`} type={alert.type}>
      {alert.text}
    </Alert>
  );
}
