import React from 'react';
// import RadioOption from 'Core/components/Option/RadioOption';
import FinancingNotEligible from 'Modules/financing/components/FinancingNotEligible';
import OfferOptions from '../components/OfferOptions';
import { PrimaryButton, LinkButton } from 'Core/components/Button';
import { financingTabNames } from '../config/financingTabNames';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

export default function SelectOffer({
  offers,
  selectedOffer,
  setSelectedOffer,
  enableSignContract,
  handleTabChange,
  amount_to_pay,
  already_paid_amount,
  routes,
  selected_offer_id,
}) {
  const [isAnyOfferValid, setIsAnyOfferValid] = React.useState(true);
  const [showOfferDetails, setShowOfferDetails] = React.useState(false);
  React.useEffect(() => {
    if (offers?.installment) {
      if (selected_offer_id) {
        const { type = '', terms = [] } =
          offers.installment.find((item) => item.terms[0].id === selected_offer_id) || {};
        setSelectedOffer({
          id: selected_offer_id,
          amount_min: terms[0]?.amount_min,
          require_downpayment_percent: terms[0]?.require_downpayment_percent,
          type,
        });
      } else {
        if (offers.installment.length > 0) {
          const anyValid = offers.installment.some(({ terms }) => {
            const [{ amount_min, require_downpayment_percent }] = terms;
            const finalAmountMin = Math.max(
              amount_min,
              amount_to_pay * require_downpayment_percent
            );
            return amount_to_pay - already_paid_amount > finalAmountMin;
          });
          setIsAnyOfferValid(anyValid);
        }
      }
    }
  }, [already_paid_amount, amount_to_pay, offers.installment, selected_offer_id, setSelectedOffer]);

  const toggleOfferDetails = () => {
    setShowOfferDetails((prev) => !prev);
  };
  return (
    <>
      <div className="flex justify-center">
        {isAnyOfferValid ? (
          <OfferOptions
            offers={offers}
            selectedOffer={selectedOffer}
            setSelectedOffer={setSelectedOffer}
            amount_to_pay={amount_to_pay}
            already_paid_amount={already_paid_amount}
            showOfferDetails={showOfferDetails}
          ></OfferOptions>
        ) : (
          <FinancingNotEligible
            already_paid_amount={already_paid_amount}
            amount_to_pay={amount_to_pay}
            routes={routes}
          />
        )}
      </div>
      {isAnyOfferValid && (
        <>
          <div className="flex justify-center pt-4">
            <LinkButton onClick={toggleOfferDetails}>
              {showOfferDetails ? (
                <div className="flex items-center gap-2">
                  <FaChevronUp /> Hide Offer Details
                </div>
              ) : (
                <div className="flex items-center gap-2">
                  <FaChevronDown /> Show Offer Details
                </div>
              )}
            </LinkButton>
          </div>
          <div className="flex justify-end">
            <PrimaryButton
              data-testid="btn-next"
              disabled={!enableSignContract && !selectedOffer.id}
              className="w-48 mt-4"
              onClick={() => handleTabChange(financingTabNames.DOWN_PAYMENT_AND_DELIVERY)}
            >
              Next
            </PrimaryButton>
          </div>
        </>
      )}
    </>
  );
}
