import { useMutation } from 'react-query';
import { postWithHeaders } from 'Core/utils/platformApi';

import useAlertStore, { clearAlertSelector, setErrorAlertSelector } from 'Core/stores/alert';
import { useTranslation } from 'react-i18next';
import { features, postLogMessage } from 'Core/utils/logger';
import { useGetPaymentInfo } from 'Core/hooks/platform/useGetPaymentInfo';
import { v4 as uuidv4 } from 'uuid';

function transformPayload({ source, method, email, phone }) {
  return {
    event_id: uuidv4(),
    event_type: 'create',
    object_type: 'platform-link',
    country: 'US',
    event_dispatched_at: Date.now(),
    source,
    payload: {
      method,
      email,
      phone,
    },
  };
}

export default function useSendLink() {
  const { t } = useTranslation();
  const setErrorAlert = useAlertStore(setErrorAlertSelector);
  const clearAlert = useAlertStore(clearAlertSelector);
  const { data: paymentInfo } = useGetPaymentInfo();

  return useMutation(
    (payload) =>
      postWithHeaders(
        `/send-link`,

        transformPayload({
          ...payload,
          source: paymentInfo.source,
        }),
        {
          headers: {
            external_reference_id: paymentInfo?.external_reference_id,
          },
        }
      ),
    {
      throwOnError: true,
      enabled: false,
      onSuccess(data) {
        postLogMessage({
          feature: features.sendContractLink,
          status: 'success',
        });
        clearAlert();
        return data;
      },
      onError(error) {
        setErrorAlert(
          t('status.error.sendLinkRequestFailed') + `(${error?.response?.data?.message})`
        );
      },
    }
  );
}
