import create from 'zustand';

export default create((set) => ({
  alert: null,
  clearAlert: () => set({ alert: null }),
  setAlert: (text) => set({ alert: { text, type: '' } }),
  setSuccessAlert: (text) => set({ alert: { text, type: 'success' } }),
  setWarningAlert: (text) => set({ alert: { text, type: 'warning' } }),
  setErrorAlert: (text) => set({ alert: { text, type: 'error' } }),
  setBlankAlert: (text) => set({ alert: { text, type: 'blank' } }),
}));

export const alertSelector = (state) => state.alert;
export const clearAlertSelector = (state) => state.clearAlert;
export const setAlertSelector = (state) => state.setAlert;
export const setSuccessAlertSelector = (state) => state.setSuccessAlert;
export const setWarningAlertSelector = (state) => state.setWarningAlert;
export const setErrorAlertSelector = (state) => state.setErrorAlert;
export const setBlankAlertSelector = (state) => state.setBlankAlert;
