export default {
  order: 'order',
  orderReceipts: 'orderReceipts',
  financingApplicationDetails: 'financingApplicationDetails',
  paymentOptions: 'paymentOptions',
  savedPaymentMethods: 'savedPaymentMethods',
  paymentSummary: 'paymentSummary',
  paymentReceipts: 'paymentReceipts',
  deposit: 'deposit',
  depositOptions: 'depositOptions',
  financingApplication: 'financingApplication',
  financingUserData: 'financingUserData',
  nextSteps: 'nextSteps',
  tokenInfo: 'tokenInfo',
  paymentHistory: 'paymentHistory',
  directSalesPaymentOptions: 'directSalesPaymentOptions',

  paymentInfo: 'paymentInfo',
  transactionHistory: 'transactionHistory',
  savedCards: 'savedCards',
  latestTransaction: 'latestTransaction',
  financingNextStep: 'financingNextStep',
  financingPlatformApplication: 'financingPlatformApplication',
  financingCustomerInfo: 'financingCustomerInfo',
};
