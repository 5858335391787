import React from 'react';
import PropTypes from 'prop-types';

const PaymentOption = ({ option, label, description, message, image, isSelected }) => (
  <>
    <div
      className={
        'payment-option flex items-start flex-col md:flex-row justify-between md:items-center mb-2 font-bold'
      }
    >
      <div data-testid={`payment-option-${option}`} is-selected={`${isSelected}`}>
        {label}
      </div>
      {image}
    </div>
    <div>{description}</div>
    {message ? (
      <div className={`text-sm text-error`} data-testid="payment-option-message">
        {message}
      </div>
    ) : null}
  </>
);

PaymentOption.propTypes = {
  option: PropTypes.oneOf(['credit-debit', 'financing', 'mixed']).isRequired,
  image: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isSelected: PropTypes.bool.isRequired,
};

export default PaymentOption;
