import { useMutation, useQueryClient } from 'react-query';
import { putWithHeaders } from 'Core/utils/platformApi';
import cacheKeys from 'Core/hooks/queries/cacheKeys';

export default function useUpdateOffer() {
  const queryClient = useQueryClient();

  return useMutation((payload) => putWithHeaders(`/financing/update-offer`, payload), {
    throwOnError: true,
    retry: 3,
    onSuccess: () => {
      queryClient.removeQueries(cacheKeys.financingNextStep);
      return queryClient.invalidateQueries(cacheKeys.financingNextStep);
    },
  });
}
