import React from 'react';
import { useTranslation } from 'react-i18next';
import { useScrollTop } from 'Core/hooks/useScrollTop';
import { Alert, AlertError } from 'Core/components/Alert';
import Checklist from 'Modules/creditDebit/components/Checklist';
import usePostCancelCardPayment from 'Core/hooks/queries/payment/usePostCancelCardPayment';
import Page from 'Core/components/Page';
import OrderData from 'Core/components/Data/OrderData';

export default function Error() {
  useScrollTop();
  const { t } = useTranslation();
  const { mutateAsync: cancelCardPayment } = usePostCancelCardPayment();

  React.useEffect(() => {
    cancelCardPayment();
  }, [cancelCardPayment]);

  const checklistItems = [
    { label: t('status.error.paymentAuthorizationsCanceled'), status: 'done' },
    { label: t('page.creditDebit.error.intro'), status: 'warn' },
  ];

  return (
    <OrderData>
      <Page title={t(`page.creditDebit.error.header`)}>
        <AlertError>{t('page.creditDebit.error.infobox')}</AlertError>

        <Alert>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: '200px',
            }}
          >
            <Checklist items={checklistItems} />
          </div>
        </Alert>
      </Page>
    </OrderData>
  );
}
