const offerTags = {
  testing: [
    {
      tagLabel: 'common.offers.tags.mostPopular',
      offerIds: ['A7C3BF93151598A812CC2637588F806C'],
    },
  ],
  staging: [
    {
      tagLabel: 'common.offers.tags.mostPopular',
      offerIds: ['A7C3BF93151598A812CC2637588F806C'],
    },
  ],
  production: [
    {
      tagLabel: 'Most Popular',
      offerIds: ['7E11457D690BB8C220C3E73EBD556B33'],
    },
    {
      tagLabel: 'Require Down Payment & Auto Pay',
      offerIds: [
        'FD4335F897B9E77528E05556D64E7F1C',
        '1953DCCC54F3865A0E73DB5A587551A5',
        '91E55B12BB27CA08EC71F0EBC65BC954',
        '9F131E08DFBB2BCC43D2C04311C90AE5',
        'E015FAE5B70DF95FED5F9A275139CDE4',
      ],
    },
  ],
};

export default offerTags[process.env.NODE_ENV] || [];
