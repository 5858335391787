import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import useAlertStore, { setErrorAlertSelector } from 'Core/stores/alert';
import { post } from 'Core/utils/api';

export default function usePostCancelCardPayment() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const setErrorAlert = useAlertStore(setErrorAlertSelector);

  return useMutation(
    () => {
      const { orderId } = queryClient.getQueryData('order');
      return post(`/api/orders/${orderId}/payments/cancel`);
    },
    {
      onSuccess() {
        return Promise.resolve();
      },
      onError(e) {
        setErrorAlert(t('status.error.failedToCancelCardPayment'));
        throw e;
      },
    }
  );
}
