import React from 'react';
import { useTranslation } from 'react-i18next';
import { useShowSidebar } from 'Core/hooks/useShowSidebar';
import { Alert } from 'Core/components/Alert';
import ShoppingCart from 'Core/components/ShoppingCart';
import DownPayment from 'Core/components/ShoppingCart/DownPayment';
import Footer from 'Core/components/Footer';
import useDialogStore, { showDialogSelector } from 'Core/stores/dialog';
import Page from 'Core/components/Page';
import { useNavigate } from 'react-location';
import { mixedFinancingPath, paymentOptionsPath } from 'Modules/creditDebit/routes';
import OrderData from 'Core/components/Data/OrderData';

const AvailableCredit = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const shouldShowSidebar = useShowSidebar();
  const showDialog = useDialogStore(showDialogSelector);

  function onCancel() {
    showDialog({
      title: t('common.cancelPaymentProcess'),
      description: t('common.confirmDownPaymentCancellation'),
      confirmButtonLabel: t('common.yes'),
      cancelButtonLabel: t('common.no'),
      confirmCallback: () => navigate({ to: paymentOptionsPath, search: true }),
    });
  }

  return (
    <OrderData>
      <Page title={t('page.availableCredit.header')} withSidebar>
        <Alert data-testid="infobox">{t('page.availableCredit.infobox')}</Alert>
        <DownPayment />
        {!shouldShowSidebar && <ShoppingCart withTitle />}

        <Footer
          labelPrev={t('common.cancel')}
          labelNext={t('common.continue')}
          onPrev={onCancel}
          onNext={() => navigate({ to: mixedFinancingPath, search: true })}
        />
      </Page>
    </OrderData>
  );
};

export default AvailableCredit;
