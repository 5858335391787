import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const defaultProps = {
  type: 'button',
  size: '',
  block: false,
};

const propTypes = {
  'data-testid': PropTypes.string,
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  size: PropTypes.oneOf(['', 'small', 'tall']),
  block: PropTypes.bool,
};

const baseStyles = `
  inline-block py-1.5 px-3 h-9
  text-base leading-6 font-normal whitespace-nowrap border-transparent text-center select-none no-underline hover:no-underline
  rounded border border-solid
  transition-colors
  disabled:opacity-60 disabled:cursor-not-allowed
`;

const buttonStyles = {
  primary: `
      bg-primary hover:bg-blue-700
      text-white hover:text-white
      disabled:hover:bg-primary shadow-md
    `,
  secondary: `
      bg-gray-500 hover:bg-gray-600
      text-white
      disabled:hover:bg-gray-500
    `,
  secondaryOutline: `
      bg-transparent
      border-gray-500
      text-gray-500
      hover:text-white hover:bg-gray-600 hover:border-gray-600
      disabled:hover:bg-transparent
    `,
  destructive: `
      bg-white
      border-red-500
      text-red-500
      hover:text-white hover:bg-red-800 hover:border-gray-600
      disabled:hover:bg-transparent disabled:hover:text-red-500
    `,
  outline: `
    bg-transparent
    border-primary
    text-primary
    hover:bg-gray-200 hover:border-gray-600
    disabled:text-gray-300
    disabled:hover:bg-transparent
  `,
  text: `
    bg-transparent
    text-primary
    hover:bg-gray-100
    disabled:pointer-events-none
    text-sm
  `,
  link: `
  !px-1 !pb-0
  bg-transparent
  text-primary
  hover:bg-gray-100
  disabled:pointer-events-none
  text-sm
  rounded-none
  border-b-primary
`,
};

const blockButtonStyles = `block w-full`;

const roundedButtonStyles = 'rounded-2xl';

const smallButtonStyles = `
  py-1 px-2 h-auto
  text-sm
`;

const tallButtonStyles = `
  py-2 px-2 !h-12 
`;

function Button({ className, design, block, rounded, size, ...props }) {
  const styles = classnames(
    baseStyles,
    buttonStyles[design],
    {
      [blockButtonStyles]: !!block,
      [roundedButtonStyles]: !!rounded,
      [smallButtonStyles]: size === 'small',
      [tallButtonStyles]: size === 'tall',
    },

    className
  );
  return <button className={styles} {...props} />;
}

export const PrimaryButton = (props) => <Button design="primary" {...props} />;
PrimaryButton.propTypes = propTypes;
PrimaryButton.defaultProps = defaultProps;

export const SecondaryButton = (props) => <Button design="secondary" {...props} />;
SecondaryButton.propTypes = propTypes;
SecondaryButton.defaultProps = defaultProps;

export const SecondaryOutlineButton = (props) => <Button design={'secondaryOutline'} {...props} />;
SecondaryOutlineButton.propTypes = propTypes;
SecondaryOutlineButton.defaultProps = defaultProps;

export const DestructiveButton = (props) => <Button design={'destructive'} {...props} />;
DestructiveButton.propTypes = propTypes;
DestructiveButton.defaultProps = defaultProps;

export const OutlineButton = (props) => <Button design={'outline'} {...props} />;
OutlineButton.propTypes = propTypes;
OutlineButton.defaultProps = defaultProps;

export const TextButton = (props) => <Button design={'text'} {...props} />;
TextButton.propTypes = propTypes;
TextButton.defaultProps = defaultProps;

export const LinkButton = (props) => <Button design={'link'} {...props} />;
LinkButton.propTypes = propTypes;
LinkButton.defaultProps = defaultProps;
