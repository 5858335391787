import useOutsideAlerter from 'Modules/core/hooks/useOutsideAlerter';
import React from 'react';

export default function Dropdown({ label, list }) {
  const [isDropdownVisible, setIsDropdownVisible] = React.useState(false);
  const callback = () => {
    setIsDropdownVisible(false);
  };
  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef, callback);
  return (
    <div className="relative inline-block text-left" ref={wrapperRef}>
      <div>
        <button
          onClick={() => setIsDropdownVisible((val) => !val)}
          type="button"
          className="text-primary inline-flex w-full justify-center rounded-md px-4 py-2 text-sm font-medium hover:bg-gray-50 focus:outline-none"
        >
          <span className="hidden md:inline-block">{label}</span>
          <svg
            className="-mr-1 ml-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      {isDropdownVisible && (
        <div
          className="absolute right-0 z-10 mt-2 w-full min-w-[128px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
        >
          <div className="py-1" role="none">
            {list.map((item) => {
              return (
                <a
                  href={item.url}
                  key={item.url}
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-700 block px-4 py-2 text-sm hover:bg-gray-100 underline underline-offset-4"
                  role="menuitem"
                  tabIndex="-1"
                  id="menu-item-0"
                >
                  {item.label}
                </a>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
