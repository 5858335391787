import React from 'react';
import { useTranslation } from 'react-i18next';
import { useScrollTop } from 'Core/hooks/useScrollTop';
import Page from 'Core/components/Page';
import Card from 'Core/components/FormElements/Card';
import Row from 'Core/components/Layout/Row';
import Column from 'Core/components/Layout/Column';
import declinedIllustration from 'Images/illustrations/declined.svg';
import { createSecondaryFinancingPath } from 'Modules/financing/routes';
import { BiError } from 'react-icons/bi';

import { PrimaryButton } from 'Core/components/Button';
import { useNavigate } from 'react-location';
import classNames from 'classnames';

export default function FinancingDeclined({ allowCreateApplication = false, application = {} }) {
  document.title = 'Payment Portal - Application Declined';
  const { t } = useTranslation();
  useScrollTop();

  const navigate = useNavigate();
  const { customer_id = '', payload: { relationship = '' } = {} } = application;
  const handleCreateApplication = () => {
    const relationshipObj = JSON.stringify({ relationship, customer_id });
    sessionStorage.setItem('relationship', relationshipObj);
    navigate({
      to: createSecondaryFinancingPath,
      search: true,
    });
  };
  const descriptionLabel = allowCreateApplication
    ? 'financing.nextgen.declined.introWithNewApp'
    : 'financing.nextgen.declined.intro';

  return (
    <Page title={t('page.financing.header')} withStepProgress={false} stickyFooter>
      <Card className="justify-center !p-4 !pb-4 mt-8">
        <Row>
          <Column className="!flex-col-3"></Column>
          <h1
            className="font-light text-red-700 flex gap-2 items-center"
            data-testid="title-finance-declined"
          >
            <BiError />
            {t('common.nextgen.financingDeclined.title')}
          </h1>
        </Row>
        <Column>
          <hr></hr>
        </Column>
        <Row className="mt-4 items-center justify-center">
          <Column className="!flex-col-3 flex justify-end">
            <img
              className="relative top-2"
              alt="application-declined"
              width="100em"
              src={declinedIllustration}
            ></img>
          </Column>
          <Column className="!flex-col-9 !mb-0">
            <div
              className={classNames(
                'mb-0 w-full whitespace-pre-line text-lg leading-loose font-light'
              )}
            >
              {t(descriptionLabel)}
            </div>
          </Column>
        </Row>
        <Row className="flex">
          <Column className="!flex-col-3"></Column>
          <Column className="!flex-col-3">
            {allowCreateApplication && (
              <PrimaryButton
                block
                data-testid="btn-create-secondary-application"
                className="mt-2"
                onClick={handleCreateApplication}
              >
                {t('common.reapply')}
              </PrimaryButton>
            )}
          </Column>
        </Row>
      </Card>
    </Page>
  );
}
