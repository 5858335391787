import React, { useEffect } from 'react';
import { useGetPaymentInfo } from 'Core/hooks/platform/useGetPaymentInfo';
import Loader from 'Core/components/Loader';
import { useTranslation } from 'react-i18next';
import { platformPaths } from 'Core/config/platformConfig';
import { useNavigate } from 'react-location';

export function Platform() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: { purpose } = {} } = useGetPaymentInfo();

  useEffect(() => {
    if (purpose) {
      const to = platformPaths[purpose];
      navigate({ to, search: true });
    }
  }, [navigate, purpose]);

  return <Loader message={t('status.loading')} />;
}
