import useGetSavedPaymentMethods from 'Core/hooks/queries/payment/useGetSavedPaymentMethods';
import useOrder from 'Core/hooks/queries/order/useOrder';
import useGetPaymentSummary from 'Core/hooks/queries/payment/useGetPaymentSummary';

export default function useCardCheckoutPageData() {
  const orderQuery = useOrder();
  const paymentSummaryQuery = useGetPaymentSummary();
  const paymentMethodsQuery = useGetSavedPaymentMethods();

  return {
    data: {
      order: orderQuery.data,
      paymentSummary: paymentSummaryQuery.data,
      paymentMethods: paymentMethodsQuery.data,
    },
    isSuccess:
      orderQuery.isSuccess && paymentSummaryQuery.isSuccess && paymentMethodsQuery.isSuccess,
  };
}
