import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import useAlertStore, { setErrorAlertSelector } from 'Core/stores/alert';
import cacheKeys from 'Core/hooks/queries/cacheKeys';
import { get } from 'Core/utils/api';

export function useGetPaymentReceiptUrls(paymentRequestId) {
  const { t } = useTranslation();
  const setErrorAlert = useAlertStore(setErrorAlertSelector);

  const url = paymentRequestId
    ? `/api/payments/${paymentRequestId}/receipts`
    : `/api/payments/receipts`;

  return useQuery([cacheKeys.paymentReceipts, { paymentRequestId }], () => get(url), {
    onError(e) {
      setErrorAlert(t('status.error.getPaymentReceiptUrlQueryFailed'));
      throw e;
    },
  });
}
