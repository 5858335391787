import React from 'react';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';

export default function useMultiCardForm(initialCard, defaultValues = {}) {
  const { t } = useTranslation();

  const schema = React.useMemo(() => {
    return z.object({
      cards: z.array(
        z.union([
          z.object({
            firstName: z.string().nonempty({ message: t('common.requiredFieldFirstName') }),
            lastName: z.string().nonempty({ message: t('common.requiredFieldLastName') }),
            postalCode: z.string().nonempty({ message: t('common.requiredFieldPostalCode') }),
            cardNumber: z
              .any()
              .refine((value) => Boolean(value), { message: t('common.requiredFieldCardNumber') }),
            cardExpiry: z
              .any()
              .refine((value) => Boolean(value), { message: t('common.requiredFieldCardExpiry') }),
            cardCvc: z
              .any()
              .refine((value) => Boolean(value), { message: t('common.requiredFieldCardCode') }),
            amount: z
              .string()
              .nonempty({ message: t('common.requiredFieldAmount') })
              .refine((val) => parseInt(val, 10) >= 1, {
                message: t('common.requiredFieldSplitAmount'),
              }),
            stripeElement: z.any(),
            stripe: z.any(),
          }),
          z.object({
            paymentMethodId: z.string().nonempty({ message: t('common.requiredFieldFirstName') }),
            amount: z
              .string()
              .nonempty({ message: t('common.requiredFieldAmount') })
              .refine((val) => parseInt(val, 10) >= 1, {
                message: t('common.requiredFieldSplitAmount'),
              }),
          }),
        ])
      ),
      decimals: z.number(),
      terms: z.boolean().refine((val) => val, { message: t('common.requiredFieldTerms') }),
      email: z
        .string()
        .email({ message: t('common.invalidFormatEmail') })
        .optional()
        .or(z.literal('')),
      remaining: z.literal(0),
    });
  }, [t]);

  return useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      ...defaultValues,
      cards: [initialCard], //forces to render at least 1 CardSelector
    },
    shouldUnregister: true,
  });
}
