import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import useErrorStore, { setErrorActionSelector } from 'Core/stores/error';
import cacheKeys from 'Core/hooks/queries/cacheKeys';
import { get } from 'Core/utils/api';
import { useNavigate } from 'react-location';
import { errorPath } from 'Core/routes';

async function getDeposit() {
  try {
    return await get(`/api/deposit`);
  } catch (error) {
    if (error?.response?.status === 404) {
      return '';
    }

    throw new Error(` - ${error?.response?.data?.message}` || '');
  }
}

export function useGetDeposit() {
  const { t } = useTranslation();
  const setError = useErrorStore(setErrorActionSelector);
  const navigate = useNavigate();

  return useQuery(cacheKeys.deposit, getDeposit, {
    onError(e) {
      if (e) {
        setError(t('status.error.getDepositFailed') + e.message);
        navigate({ to: errorPath, search: true });
      }
    },
  });
}
