import currencyJS from 'currency.js';
import { getAppConfig } from 'Core/config/appConfig';

export const formatCurrency = (
  value,
  { currency = getAppConfig().currency, currencyDisplay = 'symbol', withDecimals = true } = {}
) => {
  const decimalOptions = withDecimals
    ? {
        minimumFractionDigits: 2,
      }
    : {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      };

  return new Intl.NumberFormat(getAppConfig().locale, {
    style: 'currency',
    currency,
    currencyDisplay,
    ...decimalOptions,
  }).format(value);
};

export function toFloat(value) {
  return currencyJS(value).value;
}
