import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import useAlertStore, { setErrorAlertSelector } from 'Core/stores/alert';
import cacheKeys from 'Core/hooks/queries/cacheKeys';
import useOrder from 'Core/hooks/queries/order/useOrder';
import { get } from 'Core/utils/api';

export function useGetNextSteps() {
  const { t } = useTranslation();
  const { data: order } = useOrder();
  const setErrorAlert = useAlertStore(setErrorAlertSelector);

  return useQuery(cacheKeys.nextSteps, () => get(`/api/orders/${order.orderId}/next-steps`), {
    enabled: Boolean(order),
    onError(e) {
      setErrorAlert(t('status.error.getNextStepsFailed'));
      throw e;
    },
  });
}
