import React from 'react';
import PropTypes from 'prop-types';
import { useWindowSize } from 'Core/hooks/useWindowSize';
import questionMarkIcon from 'Images/icons/question-circle.svg';
import classnames from 'classnames';
import './QuestonMark.css';

function StyledTooltip({ position, children }) {
  return (
    <div
      className={classnames(
        'styled-tooltip rounded text-white text-xxs text-left p-2 absolute z-50 hidden [min-width:200px] [background:#333]',
        position
      )}
    >
      {children}
    </div>
  );
}

function StyledQuestionMark({ children }) {
  return <div className={'styled-tooltip-question-mark inline-block relative'}>{children}</div>;
}

const QuestionMark = ({ text, position = 'top' }) => {
  const windowSize = useWindowSize();
  const iconRef = React.useRef(null);
  const [displayPosition, setDisplayPosition] = React.useState(position);

  React.useEffect(() => {
    const rect = iconRef.current.getBoundingClientRect();
    const elemPos = {
      x: rect.left,
      y: rect.top,
    };

    if (elemPos.x + 100 > windowSize.width) {
      setDisplayPosition('left');
    } else if (elemPos.x - 100 < 0) {
      setDisplayPosition('right');
    } else {
      setDisplayPosition(position);
    }
  }, [windowSize.width, position]);

  return (
    <StyledQuestionMark>
      <StyledTooltip position={displayPosition}>{text}</StyledTooltip>
      <img
        className={'cursor-pointer relative [top:2px] [width:14px]'}
        ref={iconRef}
        src={questionMarkIcon}
        alt="(?)"
      />
    </StyledQuestionMark>
  );
};

QuestionMark.propTypes = {
  text: PropTypes.string.isRequired,
  position: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
};

export default QuestionMark;
