import React, { useCallback, useEffect } from 'react';
import Loader from 'Core/components/Loader';
import { useTranslation } from 'react-i18next';
import usePutPaymentOption from 'Core/hooks/queries/payment/usePutPaymentOption';
import {
  creditDebitCheckoutPath,
  financingPath,
  mixedDownPaymentCheckoutPath,
  paymentOptionsPath,
} from 'Modules/creditDebit/routes';
import { useNavigate, useSearch } from 'react-location';
import { errorPath } from 'Core/routes';
import useGetPaymentSummary from 'Core/hooks/queries/payment/useGetPaymentSummary';

const routes = {
  'credit-debit': creditDebitCheckoutPath,
  mixed: mixedDownPaymentCheckoutPath,
  financing: financingPath,
};

export default function Collect() {
  const { paymentOption } = useSearch();
  const { t } = useTranslation();
  const { mutateAsync: setPaymentOption } = usePutPaymentOption();
  const navigate = useNavigate();
  const { data: paymentSummary } = useGetPaymentSummary();

  const updateAndRoute = useCallback(async () => {
    let option = paymentOption;
    if (!paymentSummary) return;

    if (!option) return navigate({ to: paymentOptionsPath, search: true });

    try {
      //if there downpayment then redirect financing to mixed.
      if (paymentSummary.downPayment > 0 && option === 'financing') {
        option = 'mixed';
      }
      await setPaymentOption(option);
      return navigate({ to: routes[option], search: true });
    } catch (e) {
      return navigate({ to: errorPath, search: true });
    }
  }, [navigate, paymentOption, setPaymentOption, paymentSummary]);

  useEffect(() => {
    updateAndRoute().then();
  }, [updateAndRoute]);

  return <Loader message={t('status.loading')} />;
}
