import React from 'react';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';

export default function useAddCardForm() {
  const { t } = useTranslation();

  const schema = React.useMemo(() => {
    return z.object({
      firstName: z.string().nonempty({ message: t('common.requiredFieldFirstName') }),
      lastName: z.string().nonempty({ message: t('common.requiredFieldLastName') }),
      postalCode: z.string().nonempty({ message: t('common.requiredFieldPostalCode') }),
      cardNumber: z
        .any()
        .refine((value) => Boolean(value), { message: t('common.requiredFieldCardNumber') }),
      cardExpiry: z
        .any()
        .refine((value) => Boolean(value), { message: t('common.requiredFieldCardExpiry') }),
      cardCvc: z
        .any()
        .refine((value) => Boolean(value), { message: t('common.requiredFieldCardCode') }),
      terms: z.boolean().refine((val) => val, { message: t('common.requiredFieldTerms') }),
      preferred: z.boolean(),
    });
  }, [t]);

  return useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      firstName: '',
      lastName: '',
      postalCode: '',
      cardNumber: '',
      cardExpiry: '',
      cardCvc: '',
      preferred: false,
      terms: false,
    },
    shouldUnregister: true,
  });
}
