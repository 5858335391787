import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-location';
import Loader from 'Core/components/Loader';
import Page from 'Core/components/Page';
import DepositCardForm from 'Modules/deposit/components/DepositCardForm';
import useDepositPageData from 'Modules/deposit/hooks/useDepositPageData';
import usePostCreatePaymentMethods from 'Core/hooks/queries/stripe/usePostCreatePaymentMethods';
import usePostChargeDeposit from 'Core/hooks/queries/deposit/usePostChargeDeposit';
import { useScrollTop } from 'Core/hooks/useScrollTop';
import useUpdatePaymentMethod from 'Core/hooks/queries/payment/useUpdatePaymentMethod';
import Status from 'Core/components/Status';
import { depositSuccessPath } from 'Modules/deposit/routes';

export default function Checkout() {
  const scroll = useScrollTop();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, isSuccess } = useDepositPageData();
  const [status, setStatus] = React.useState({ type: '', text: '' });
  const [cardErrors, setCardErrors] = React.useState();
  const { mutateAsync: createPaymentMethods } = usePostCreatePaymentMethods();
  const { mutateAsync: chargeDeposit } = usePostChargeDeposit();
  const { mutateAsync: updateCard } = useUpdatePaymentMethod();

  if (!isSuccess) return <Loader message={'Loading saved cards'} />;

  async function onSubmit(formData) {
    scroll.top();
    let paymentMethods;
    setStatus({ type: 'loading', text: t('status.loading.submitPaymentInformation') });

    if (!formData.paymentMethodId) {
      const paymentMethodData = [
        {
          cardId: 'card0',
          amount: formData.amount,
          stripeElement: formData.stripeElement,
          billingDetails: {
            name: `${formData.firstName} ${formData.lastName}`,
            address: { postal_code: formData.postalCode },
          },
        },
      ];

      if (!formData.stripe) {
        return setStatus({ type: 'error', text: t('status.error.paymentGatewayInitialize') });
      }

      try {
        paymentMethods = await createPaymentMethods({
          stripe: formData.stripe,
          data: paymentMethodData,
        });
      } catch {
        setStatus({ type: 'error', text: t('status.error.failedToCreatePaymentMethods') });
        return;
      }
    }

    const paymentMethodId = formData.paymentMethodId || paymentMethods[0].paymentMethodId;

    try {
      await chargeDeposit({
        amount: formData.amount,
        paymentMethodId,
        type: formData.type,
      });

      formData.preferred && (await updateCard({ paymentMethodId, preferred: true }));
      navigate({
        to: depositSuccessPath,
        search: true,
      });
    } catch (error) {
      const detailedErrorMessage =
        error?.response?.data?.message || t('status.error.failedToChargeCard');
      setStatus({ type: 'error', text: detailedErrorMessage });

      if (error?.response?.data?.hasOwnProperty('errors')) {
        setCardErrors(error.response.data.errors);
      }
    }
  }

  return (
    <Page
      title={t('page.deposit.checkout.header')}
      stepProgressProps={{ type: 'deposit' }}
      withCardPrefCenterLink
    >
      <Status {...status} />
      <DepositCardForm {...data} onSubmit={onSubmit} cardErrors={cardErrors} />
    </Page>
  );
}
