import React from 'react';
import PropTypes from 'prop-types';
import { StyledIndicator } from 'Modules/creditDebit/components/Checklist/Checklist.styled';

const IndicatorError = ({ color }) => {
  return (
    <StyledIndicator color={color}>
      <div className="inline-block relative origin-center [top:3px] [left:-3px] [transform:rotate(45deg)] [width:26px] [height:26px]">
        <div className={'bg-error absolute [height:16px] [width:2px] [top:0] [left:10px]'} />
        <div className={'bg-error absolute [height:2px] [width:16px] [top:7px] [left:3px]'} />
      </div>
    </StyledIndicator>
  );
};

IndicatorError.propTypes = {
  color: PropTypes.oneOf(['gray', 'green', 'orange', 'red']),
};

export default IndicatorError;
