import React, { useEffect } from 'react';
import { useNavigate } from 'react-location';
import { useTranslation } from 'react-i18next';
import { useGetTokenInfo } from 'Core/hooks/useGetTokenInfo';
import useErrorStore, { setErrorActionSelector } from 'Core/stores/error';
import Loader from 'Core/components/Loader';
import { errorPath } from 'Core/routes';
import { journeyPaths } from 'Core/config/journeyConfig';

export function CustomerJourney() {
  const { t } = useTranslation();
  const { data: tokenInfo } = useGetTokenInfo();
  const setError = useErrorStore(setErrorActionSelector);
  const navigate = useNavigate();

  useEffect(() => {
    if (tokenInfo) {
      const { customerJourney } = tokenInfo;

      const path = journeyPaths[customerJourney];
      if (path) {
        return navigate({ to: path, search: true });
      }
      setError(t('status.error.invalidCustomerJourney'));
      navigate({ to: errorPath, search: true });
    }
  }, [navigate, setError, t, tokenInfo]);

  return <Loader message={t('status.loading')} />;
}
