import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { format as dateFnsFormat } from 'date-fns';
import { getAppConfig } from 'Core/config/appConfig';
import { StyledFormElement } from 'Core/components/FormElements/formElements.styled';
import './CalendarInput.css';
import Error from 'Core/components/FormElements/Error';

function formatDate(date, format, locale) {
  return dateFnsFormat(date, format, { locale });
}

const CalendarInputField = (props) => {
  const { dateFormat } = getAppConfig();
  const {
    errors = null,
    className = 'form-control',
    name,
    id = name,
    testid = id,
    label,
    format = dateFormat,
    onChange,
    ...rest
  } = props;

  const error = errors[name]?.message;

  return (
    <StyledFormElement className={className}>
      <label htmlFor={id}>{label}</label>
      <div className={className}>
        <DayPickerInput
          onDayChange={(dateObj, modifiers, dayPickerInput) =>
            onChange(dayPickerInput.getInput().value)
          }
          formatDate={formatDate}
          format={format}
          placeholder=""
          inputProps={{ 'data-testid': testid }}
          {...rest}
        />
      </div>
      <Error message={error} show={Boolean(error)} />
    </StyledFormElement>
  );
};

export default CalendarInputField;
