import React from 'react';
import { StyledFormElement } from 'Core/components/FormElements/formElements.styled';
import Error from 'Core/components/FormElements/Error';

const SelectMenu = (props) => {
  const {
    className,
    name,
    id = name,
    register,
    errors = {},
    error = '',
    label,
    testid = id,
    items = [],
    onChange,
    ...rest
  } = props;

  const errorMessage = error || errors[name]?.message;

  return (
    <StyledFormElement className={className}>
      <label htmlFor={id}>{label}</label>
      <select
        id={id}
        name={name}
        aria-label={label}
        data-testid={testid}
        onChange={onChange}
        {...register(name)}
        {...rest}
      >
        {items.map(({ label: itemLabel, value: itemValue, disabled: disabledValue }) => (
          <option key={itemValue} value={itemValue} disabled={disabledValue}>
            {itemLabel}
          </option>
        ))}
      </select>
      <Error message={errorMessage} show={Boolean(errorMessage)} />
    </StyledFormElement>
  );
};

export default SelectMenu;
