import React from 'react';
import { useTranslation } from 'react-i18next';
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import Page from 'Core/components/Page';
import { Column, Row, Modal } from 'Core/components/Layout';
import { PrimaryButton } from 'Core/components/Button';
import PaymentMethod from 'Modules/deposit/components/PaymentMethod';
import Loader from 'Core/components/Loader';
import useDialogStore, { showDialogSelector } from 'Core/stores/dialog';
import useAddPaymentMethod from 'Core/hooks/queries/payment/useAddPaymentMethod';
import useUpdatePaymentMethod from 'Core/hooks/queries/payment/useUpdatePaymentMethod';
import useDeletePaymentMethod from 'Core/hooks/queries/payment/useDeletePaymentMethod';
import useCreatePaymentMethod from 'Core/hooks/queries/stripe/useCreatePaymentMethod';
import useGetSavedPaymentMethods from 'Core/hooks/queries/payment/useGetSavedPaymentMethods';
import AddCardForm from 'Modules/deposit/components/AddCardForm';
import Footer from 'Core/components/Footer';
import { useNavigate } from 'react-location';
import { depositCheckoutPath } from 'Modules/deposit/routes';

const CardPreferenceCenter = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const stripe = useStripe();
  const stripeElements = useElements();
  const showDialog = useDialogStore(showDialogSelector);
  const { data: savedPaymentMethods = [], isLoading: isLoadingSavedPaymentMethods } =
    useGetSavedPaymentMethods();
  const { mutateAsync: createPaymentMethod, isLoading: isLoadingCreatePaymentMethod } =
    useCreatePaymentMethod();
  const { mutateAsync: addCard, isLoading: isLoadingAddCard } = useAddPaymentMethod(true);
  const { mutateAsync: updateCard } = useUpdatePaymentMethod();
  const { mutateAsync: deleteCard } = useDeletePaymentMethod();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [cardError, setCardError] = React.useState('');

  const isLoading = isLoadingCreatePaymentMethod || isLoadingAddCard;
  const visibilityState = isLoading ? `hidden` : `block`;

  const showAddCardForm = () => {
    setCardError('');
    setIsOpen(!modalIsOpen);
  };

  const onSubmit = async ({ firstName, lastName, postalCode, preferred }) => {
    let paymentMethodId;

    try {
      const paymentMethod = await createPaymentMethod({
        stripe,
        stripeCardElement: stripeElements.getElement(CardNumberElement),
        name: firstName + ' ' + lastName,
        postalCode,
      });
      paymentMethodId = paymentMethod.id;
    } catch (err) {
      setCardError(t('status.error.failedToSaveCardOnStripe'));
      return;
    }

    try {
      await addCard({
        paymentMethodId,
        preferred,
      });
      toast.success(t('status.success.cardAdded'));
      setIsOpen(false);
    } catch (err) {
      setCardError(t('status.error.failedToAddCard'));
    }
  };

  const handleUpdateCard = async (paymentMethodId) => {
    try {
      await updateCard(paymentMethodId);
      toast.success(t('status.success.cardUpdated'));
    } catch (err) {
      toast.error(t('status.error.failedToUpdateCard'));
    }
  };

  const handleDeleteCard = (paymentMethodId) => {
    showDialog({
      title: t('common.deleteCard'),
      description: t('common.confirmDeleteCard'),
      confirmButtonLabel: t('common.yes'),
      cancelButtonLabel: t('common.no'),
      confirmCallback: async () => {
        try {
          await deleteCard(paymentMethodId);
          toast.success('status.success.cardDeleted');
        } catch (err) {
          toast.error(t('status.error.failedToDeleteCard'));
        }
      },
    });
  };

  if (isLoadingSavedPaymentMethods) {
    return <Loader type="overlay" message={t('status.loading.cardPreferenceCenter')} />;
  }

  return (
    <>
      <Page title={t('common.manageCards')} withStepProgress={false}>
        <Row>
          <Column className="md:flex-col-9 flex items-center">
            <h3 className="mb-0">{t('common.savedCards')}</h3>
          </Column>
          <Column className="md:flex-col-3 flex  justify-end">
            <PrimaryButton data-testid="add-card" onClick={showAddCardForm}>
              {t('common.addCard')}
            </PrimaryButton>
          </Column>
        </Row>

        {savedPaymentMethods.map((paymentMethod) => {
          return (
            <PaymentMethod
              key={paymentMethod.paymentMethodId}
              paymentMethod={paymentMethod}
              onMarkPreferred={handleUpdateCard}
              onDelete={handleDeleteCard}
            />
          );
        })}

        {!savedPaymentMethods.length && (
          <div className="bg-white shadow rounded mb-4 p-3 items-center text-center">
            <p>{t('common.cardPreferenceCenter.getStarted')}</p>
            <PrimaryButton data-testid="add-card" size="small" onClick={showAddCardForm}>
              {t('common.addCard')}
            </PrimaryButton>
          </div>
        )}

        <Footer
          labelPrev={t('common.back')}
          onPrev={() => navigate({ to: depositCheckoutPath, search: true })}
        />
      </Page>

      <Modal className="w-10/12" isOpen={modalIsOpen}>
        <AddCardForm
          onSubmit={onSubmit}
          className={visibilityState}
          onCancel={() => setIsOpen(false)}
          cardError={cardError}
        />
        {isLoading && (
          <Loader
            data-testid="add-card-loader"
            className="p-8"
            message={t('status.loading.savingCard')}
            type="inline"
          />
        )}
      </Modal>
    </>
  );
};

export default CardPreferenceCenter;
