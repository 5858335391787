import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { formatCurrency } from 'Core/utils/numberFormat';
import CreditCardLogos from 'Core/components/CreditCardLogos';

export function TransactionHistory({ transactions }) {
  const { t } = useTranslation();

  return transactions.length === 0 ? null : (
    <div className="mb-12">
      <h2>{t('directSales.history.header')}</h2>
      {transactions.map((transaction) => {
        const {
          created_at,
          card_action,
          amount,
          transactionDate = '',
          brand,
          last4digits,
          receipt: { url: receiptUrl },
        } = transaction;

        const hasDecimals = !Number.isSafeInteger(amount);

        return (
          <div
            className="bg-white border border-solid border-gray-300 rounded mb-2 p-3 shadow gap-y-3 flex flex-col"
            key={created_at}
          >
            <div className="flex items-center  gap-x-3">
              <div className="w-32 flex">
                <div
                  className={classnames(
                    'border border-solid rounded-xl px-4 py-1 text-white font-bold text-lg',
                    {
                      'bg-green-300 border-green-300': card_action.toLowerCase() === 'capture',
                      'bg-yellow-300 border-yellow-300': card_action.toLowerCase() !== 'capture',
                    }
                  )}
                >
                  {t(`common.${card_action.toLowerCase()}`)}
                </div>
              </div>
              <div className="text-2xl">
                {formatCurrency(amount, { withDecimals: hasDecimals })}
              </div>
              <div className="flex-1" />
              <div className="flex items-center ml-4 font-bold">
                <CreditCardLogos brand={brand} size="medium" className="mr-2" />
                <div className="flex-1">**** {last4digits}</div>
              </div>
            </div>
            <div className="flex">
              <div className="ml-2">{transactionDate}</div>
              <div className="flex-1" />
              <a
                href={receiptUrl}
                className="text-lg underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('common.viewReceipt')}
              </a>
            </div>
          </div>
        );
      })}
    </div>
  );
}
