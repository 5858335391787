import React, { useCallback, useEffect } from 'react';
import { useGetNextSteps } from 'Core/hooks/queries/order/useGetNextSteps';
import Loader from 'Core/components/Loader';
import { useTranslation } from 'react-i18next';
import usePutPaymentOption from 'Core/hooks/queries/payment/usePutPaymentOption';
import {
  creditDebitCheckoutPath,
  financingPath,
  mixedDownPaymentCheckoutPath,
  mixedFinancingPath,
  paymentOptionsPath,
} from 'Modules/creditDebit/routes';
import { useNavigate } from 'react-location';

const routes = {
  'credit-debit': {
    creditDebit: creditDebitCheckoutPath,
  },
  mixed: {
    downPayment: mixedDownPaymentCheckoutPath,
    signFinancingApplication: mixedFinancingPath,
  },
  financing: {
    signFinancingApplication: financingPath,
  },
};

export default function Teleaudiology() {
  const { t } = useTranslation();
  const { mutateAsync: setPaymentOption } = usePutPaymentOption();
  const { data: nextSteps, isLoading } = useGetNextSteps();
  const navigate = useNavigate();

  const updateAndRoute = useCallback(async () => {
    const { paymentOption, steps } = nextSteps;

    if (paymentOption && paymentOption !== 'N/A') {
      await setPaymentOption(paymentOption);
    }

    if (steps.length === 0) return navigate({ to: paymentOptionsPath, search: true });

    const [{ id }] = steps;

    return navigate({ to: routes[paymentOption][id], search: true });
  }, [navigate, nextSteps, setPaymentOption]);

  useEffect(() => {
    if (nextSteps) updateAndRoute().then();
  }, [nextSteps, updateAndRoute]);

  if (isLoading) return <Loader message={t('status.loading.nextSteps')} />;

  return null;
}
