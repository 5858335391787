import React from 'react';
import { Column, Row } from 'Core/components/Layout';
import { PrimaryButton, TextButton } from 'Core/components/Button';
import { useTranslation } from 'react-i18next';

import { Alert } from 'Core/components/Alert';
import { useGetNextStep } from 'Modules/financing/hooks/useGetNextStep';

import Spinner from 'Core/components/Spinner';
import FinancingSummary from './FinancingSummary';
import { useGetPaymentInfo } from 'Core/hooks/platform/useGetPaymentInfo';
import sendLinkSources from 'Modules/financing/config/sendLinkSources';
import SendLink from 'Modules/financing/FinalizeOffer/SendLink';
import { postLogMessage, features, actions } from 'Core/utils/logger';

export default function FinalizerOffer({
  application,
  enableSignContract,
  watch,
  setRefetchInterval,
  routes,
}) {
  const [state, setState] = React.useState({
    refetchInterval: false,
    financingWindow: null,
    isSignContractLoading: false,
    isError: false,
  });
  const SEND_LINK_SOURCES = sendLinkSources;

  const { t } = useTranslation();
  const [showSendLinkFeature, setShowSendLinkFeature] = React.useState(false);
  const { data: nextStepApp } = useGetNextStep(state.refetchInterval);
  const { data: { source = '' } = {} } = useGetPaymentInfo() || {};
  const finalApp = nextStepApp || application || {};
  const {
    external_reference_id = '',
    payload: {
      links: { cart: redirection_link },
      application_id = '',
      payment_id = '',
    },
  } = finalApp;

  React.useEffect(() => {
    if (source) {
      if (SEND_LINK_SOURCES.includes(source)) {
        setShowSendLinkFeature(true);
      } else {
        setShowSendLinkFeature(false);
      }
    }
  }, [SEND_LINK_SOURCES, source]);
  const openFinancingWindow = (e) => {
    postLogMessage({
      feature: features.financingSignContract,
      action: actions.signContractButtonClicked,
      status: 'success',
      payment_id,
      external_reference_id,
      application_id,
    });

    e.preventDefault();
    if (redirection_link) {
      const financingWindow = window.open(redirection_link, '_blank');
      setState({
        ...state,
        refetchInterval: 5000,
        financingWindow,
        isSignContractLoading: true,
      });
    }
  };

  return (
    <>
      <div className="w-full m-auto">
        <div className="">
          <Alert type="shade" data-testid="notification-financing-intro">
            <div className="text-sm w-full">{t('financing.nextgen.financingApproved.intro')}</div>
          </Alert>
          <FinancingSummary
            watch={watch}
            setRefetchInterval={setRefetchInterval}
            routes={routes}
            application={application}
          ></FinancingSummary>

          <Row className="px-2 mt-4 items-center justify-center">
            {state.isSignContractLoading ? (
              <Row className="flex justify-center items-center w-full mx-0">
                <Column className="">
                  <hr></hr>
                </Column>
                <Column className="!flex-col-12 text-center mb-2">
                  <Spinner small className="!w-6 !h-6 !text-primary" />
                  <span className="pl-4 text-lg font-semibold">
                    {t('financing.nextgen.signContract.loadingText')}
                  </span>
                </Column>
                <Column className="!flex-col-12">
                  <TextButton
                    block
                    data-testid="set-offer"
                    onClick={openFinancingWindow}
                    disabled={!enableSignContract}
                  >
                    <span className="text-sm">{t('financing.openLinkAgain')}</span>
                  </TextButton>
                </Column>
              </Row>
            ) : (
              <Row className="flex justify-center items-center w-full px-4">
                <Column className="!flex-col-1 lg:!flex-col-3 px-0"></Column>
                <Column className="lg:!flex-col-6 px-0">
                  <PrimaryButton
                    block
                    data-testid="set-offer"
                    onClick={openFinancingWindow}
                    disabled={!enableSignContract}
                  >
                    {t('common.signContract')}
                  </PrimaryButton>
                </Column>
                <Column className="!flex-col-3 text-right mb-2"></Column>
                {showSendLinkFeature && (
                  <Column className="lg:!flex-col-6 px-0">
                    <SendLink className="m-auto"></SendLink>
                  </Column>
                )}
              </Row>
            )}
          </Row>
        </div>
      </div>
    </>
  );
}
