import { Column } from 'Core/components/Layout';
import React from 'react';

export default function RadioTile({ item, register, defaultValue }) {
  return (
    <Column className="!flex-col-6 h-full" key={item.id}>
      <div className="items-center h-full">
        <input
          {...register('method')}
          id={item.id}
          type="radio"
          value={item.id}
          defaultChecked={defaultValue === item.id}
          className="hidden peer"
        />
        <label
          htmlFor={item.id}
          className="inline-flex items-center justify-between w-full h-full p-5 text-gray-500 bg-white border border-gray-200 rounded cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
        >
          <div className="flex flex-col items-center text-center">
            <div className="m-auto">{item.icon}</div>
            <div className="w-full text-lg font-semibold">{item.title}</div>
            <div className="w-full text-xs">{item.description}</div>
          </div>
        </label>
      </div>
    </Column>
  );
}
