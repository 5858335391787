// import StyledFormGroup from 'Core/components/FormElements/StyledFormGroup';
import { Column, Row } from 'Core/components/Layout';
import React from 'react';
import useSendLink from 'Modules/financing/FinalizeOffer/SendLink/hooks/useSendLink';
import useSendLinkForm from 'Modules/financing/FinalizeOffer/SendLink/hooks/useSendLinkForm';
import EmailOption from './EmailOption';
import SmsOption from './SmsOption';
import { OutlineButton, PrimaryButton } from 'Core/components/Button';
import { HiOutlineMail } from 'react-icons/hi';
import { FaSms } from 'react-icons/fa';
import classNames from 'classnames';
import Loader from 'Core/components/Loader';
import { useTranslation } from 'react-i18next';
import { AlertError } from 'Core/components/Alert';
import Error from 'Core/components/FormElements/Error';
import { toast } from 'react-toastify';
import RadioTile from './RadioTile';

export default function SendLinkForm({ setIsModalOpen, onLinkSent }) {
  const { t } = useTranslation();
  const Methods = [
    {
      id: 'EMAIL',
      title: t('financing.nextgen.sendlink.radioTitle.email'),
      description: t('financing.nextgen.sendlink.radioDescription.email'),
      icon: <HiOutlineMail size={40}></HiOutlineMail>,
    },
    {
      id: 'SMS',
      title: t('financing.nextgen.sendlink.radioTitle.sms'),
      description: t('financing.nextgen.sendlink.radioDescription.sms'),
      icon: <FaSms size={40}></FaSms>,
    },
  ];
  const { mutateAsync: sendLinkQuery, isLoading } = useSendLink();
  const defVals = { method: '', email: '', phone: '' };
  const form = useSendLinkForm(defVals);
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = form;
  const method = watch('method');

  const onModalClose = () => {
    setIsModalOpen(false);
  };
  const onModalConfirm = async (formData) => {
    const { method: formMethod, email, phone } = formData;
    if ((formMethod === 'EMAIL' && email) || formMethod === 'SMS' || phone) {
      const resp = await sendLinkQuery({ method: formMethod, email, phone });
      if (resp.status === 201) {
        let toastString = '';
        toastString = t('financing.nextgen.sendlink.toast.description');
        toast.success(toastString);
        setIsModalOpen(false);
      }
      onLinkSent(formData);
    }
  };
  if (isLoading) {
    return <Loader message={t('status.loading')} />;
  }
  const hasFormErrors = Object.keys(errors).length > 0;
  return (
    <form onSubmit={handleSubmit(onModalConfirm)} data-testid="sendlink-popup">
      {hasFormErrors && <AlertError type="error">{t('status.error.checkErrors')}</AlertError>}
      <Row className="flex my-8 justify-between !mx-auto w-2/3">
        {Methods.map((item) => (
          <RadioTile
            key={item.id}
            item={item}
            register={register}
            defaultValue={method}
          ></RadioTile>
        ))}
        <Column className="md:!flex-col-12">
          <Error message={errors?.method?.message} show={Boolean(errors?.method?.message)} />
        </Column>
        <Column className="md:!flex-col-12 mb-0 items-center">
          <div className={classNames({ hidden: method !== 'EMAIL' })}>
            <EmailOption form={form} errors={errors}></EmailOption>
          </div>
          <div className={classNames({ hidden: method !== 'SMS' })}>
            <SmsOption form={form} errors={errors}></SmsOption>
          </div>
        </Column>
      </Row>
      <hr></hr>
      <div className="flex items-center justify-center gap-8 w-2/3 !mx-auto px-4">
        <OutlineButton type="reset" onClick={onModalClose} data-testid="btn-cancel-sendlink" block>
          {t('common.cancel')}
        </OutlineButton>
        <PrimaryButton type="submit" data-testid="btn-confirm-sendlink" block>
          {t('common.sendLinkButtonLabel')}
        </PrimaryButton>
      </div>
    </form>
  );
}
