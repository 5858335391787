import { useMutation, useQueryClient } from 'react-query';
import { post } from 'Core/utils/platformApi';
import cacheKeys from 'Core/hooks/queries/cacheKeys';

export default function usePostCharge() {
  const queryClient = useQueryClient();

  return useMutation(
    (data) => {
      const { external_reference_id } = queryClient.getQueryData(cacheKeys.paymentInfo);
      return post(`/payment/charge`, data, {
        headers: { external_reference_id },
      });
    },
    {
      throwOnError: true,
      onMutate() {
        queryClient.setQueryData(cacheKeys.latestTransaction, []);
      },
      onSuccess() {
        queryClient.invalidateQueries(cacheKeys.latestTransaction);
      },
    }
  );
}
