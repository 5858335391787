import React from 'react';
import Icon from 'Core/components/Icon/Icon';
export default function Tooltip({ content }) {
  return (
    <div className="inline-block">
      <div className="relative flex items-center group fill-red">
        <Icon type="question" />
        <div className="absolute left-0 items-center hidden ml-6 group-hover:flex">
          <div className="w-3 h-3 -mr-2 rotate-45 bg-blue-700"></div>
          <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-blue-700 shadow-lg rounded-sm w-48">
            {content}
          </span>
        </div>
      </div>
    </div>
  );
}
