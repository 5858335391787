import React from 'react';
import PropTypes from 'prop-types';
import { PrimaryButton, SecondaryOutlineButton } from 'Core/components/Button';
import { Column, Row } from 'Core/components/Layout';

const Footer = (props) => {
  const { hasPrev = true, hasNext = true, labelPrev, labelNext, onNext, onPrev } = props;
  const backButton =
    hasPrev && onPrev ? (
      <SecondaryOutlineButton block onClick={onPrev} data-testid="back">
        {labelPrev}
      </SecondaryOutlineButton>
    ) : (
      ''
    );

  const nextButton =
    hasNext && onNext ? (
      <PrimaryButton block onClick={onNext} data-testid="continue">
        {labelNext}
      </PrimaryButton>
    ) : (
      ''
    );

  return (
    <footer className="pb-4">
      <Row className={backButton ? `!justify-between` : `!justify-end`}>
        {backButton && (
          <Column className="!flex-col-6 md:!flex-col-4 lg:!flex-col-3">{backButton}</Column>
        )}
        <Column className="!flex-col-6 md:!flex-col-4 lg:!flex-col-3">{nextButton}</Column>
      </Row>
    </footer>
  );
};

Footer.propTypes = {
  hasPrev: PropTypes.bool,
  hasNext: PropTypes.bool,
  labelPrev: PropTypes.string,
  labelNext: PropTypes.string,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
};

export default Footer;
