import React from 'react';
import { StyledIndicator } from 'Modules/creditDebit/components/Checklist/Checklist.styled';
import Spinner from 'Core/components/Spinner';

const IndicatorLoading = () => {
  return (
    <StyledIndicator>
      <Spinner small className="!w-7 !h-7 !text-primary" />
    </StyledIndicator>
  );
};

export default IndicatorLoading;
