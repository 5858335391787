import React from 'react';
import { PrimaryButton } from 'Core/components/Button';
import { useTranslation } from 'react-i18next';
import { Column } from 'Core/components/Layout';
import Row from 'Core/components/Layout/Row';
import CloseIcon from 'Modules/deposit/components/CloseIcon';
import useAddCardForm from 'Modules/deposit/hooks/useAddCardForm';
import NewCard from 'Core/components/NewCard';
import TermsInput from 'Core/components/FormElements/TermsInput';
import Checkbox from 'Core/components/FormElements/Checkbox';

export default function AddCardForm({ onSubmit, className, onCancel, cardError }) {
  const { t } = useTranslation();

  const form = useAddCardForm();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = form;

  return (
    <form
      name="addCardForm"
      onSubmit={handleSubmit(onSubmit)}
      className={className}
      data-testid="add-card-form"
    >
      <Row>
        <Column className="md:flex-col-9">
          <h3>{t('common.addCardDetails')}</h3>
        </Column>
        <Column className="md:flex-col-3 flex justify-end">
          <CloseIcon onClick={onCancel} />
        </Column>
      </Row>

      <NewCard form={form} cardError={cardError} />

      <TermsInput name="terms" register={register} errors={errors}>
        <p>{t('terms.card.hold')}</p>
      </TermsInput>

      <Checkbox label={t('common.markAsPreferredCard')} name="preferred" register={register} />

      <div className="text-right">
        <PrimaryButton type="submit" data-testid="save-card">
          {t('common.saveCard')}
        </PrimaryButton>
      </div>
    </form>
  );
}
