import amex from 'Images/credit-cards/amex.svg';
import diners from 'Images/credit-cards/diners.svg';
import discover from 'Images/credit-cards/discover.svg';
import jcb from 'Images/credit-cards/jcb.svg';
import mastercard from 'Images/credit-cards/mastercard.svg';
import visa from 'Images/credit-cards/visa.svg';

export const CARDS = [
  {
    name: 'American Express',
    brand: 'amex',
    img: amex,
  },
  {
    name: 'Diners Club',
    brand: 'diners',
    img: diners,
  },
  {
    name: 'Discover',
    brand: 'discover',
    img: discover,
  },
  {
    name: 'JCB',
    brand: 'jcb',
    img: jcb,
  },
  {
    name: 'Mastercard',
    brand: 'mastercard',
    img: mastercard,
  },
  {
    name: 'Visa',
    brand: 'visa',
    img: visa,
  },
];

export function getCard(brand) {
  return CARDS.find((card) => card.brand === brand);
}
