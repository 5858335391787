import { useTranslation } from 'react-i18next';
import { useScrollTop } from 'Core/hooks/useScrollTop';
import React from 'react';
import Page from 'Core/components/Page';
import Card from 'Core/components/FormElements/Card';

import Row from 'Core/components/Layout/Row';
import Column from 'Core/components/Layout/Column';
import paperworkIllustration from 'Images/illustrations/paperwork.svg';

export default function FinancingStatusMessage({ nextAction }) {
  document.title = 'Payment Portal - Financing';
  useScrollTop();

  const { t } = useTranslation();
  const text_pageHeader = 'Financing';
  const text_title = t(`page.financing.${nextAction}.title`);
  const text_description = t(`page.financing.${nextAction}.description`);
  return (
    <Page title={text_pageHeader} withStepProgress={false} stickyFooter>
      <Card className="justify-center mt-8">
        <Row className="justify-center items-center">
          <h1 className="font-regular" data-testid="title-finance-approved">
            {text_title}
          </h1>
        </Row>
        <Column>
          <hr></hr>
        </Column>

        <Row className="justify-center items-center my-8">
          <Column className="!flex-col-2 flex justify-end">
            <img
              className="relative top-2"
              alt="application-declined"
              width="150em"
              src={paperworkIllustration}
            ></img>
          </Column>
          <Column className="!flex-col-8 !mb-0">
            <div className="mb-0 w-full whitespace-pre-line text-xl leading-loose">
              {text_description}
            </div>
          </Column>
        </Row>
      </Card>
    </Page>
  );
}
