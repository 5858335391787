export default {
  style: {
    base: {
      color: '#495057',
      fontFamily: 'inherit',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
      lineHeight: '26px',
    },
    invalid: {
      color: '#dc3545',
      iconColor: '#dc3545',
      ':-webkit-autofill': {
        color: '#dc3545',
      },
    },
  },
};
