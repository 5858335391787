import React from 'react';
import { useTranslation } from 'react-i18next';
import usePaymentInitializer from 'Core/hooks/usePaymentInitializer';
import Loader from 'Core/components/Loader';
import constants from 'Core/config/constants';
import {
  creditDebitSuccessPath,
  financingSuccessPath,
  mixedSuccessPath,
} from 'Modules/creditDebit/routes';
import { useNavigate } from 'react-location';
import { errorPath } from 'Core/routes';

export default function OrderData({ children }) {
  const successRoutes = {
    [constants.paymentOptions.CREDIT_DEBIT]: creditDebitSuccessPath,
    [constants.paymentOptions.FINANCING]: financingSuccessPath,
    [constants.paymentOptions.MIXED]: mixedSuccessPath,
    [constants.paymentOptions.NA]: creditDebitSuccessPath,
  };

  const { t } = useTranslation();
  const { data: order, isLoading } = usePaymentInitializer();
  const navigate = useNavigate();

  const orderStatus = order?.status;
  const orderOptionSelected = order?.optionSelected;

  React.useEffect(() => {
    if (orderStatus && orderOptionSelected) {
      if (orderStatus === constants.orderStatus.PAID) {
        return navigate({ to: successRoutes[orderOptionSelected], search: true });
      }

      if (
        orderStatus === constants.orderStatus.CLOSED ||
        orderStatus === constants.orderStatus.FAILED
      ) {
        return navigate({ to: errorPath, search: true });
      }
    }
  }, [orderStatus, orderOptionSelected, navigate]);

  if (isLoading) {
    return <Loader message={t('status.loading.orderData')} />;
  }

  if (!order) {
    return null;
  }

  return children;
}
