import React from 'react';
import PropTypes from 'prop-types';
import { CARDS, getCard } from './cards';
import classnames from 'classnames';

const imgSizes = {
  small: `h-6`,
  medium: `h-8`,
  large: `h-10`,
};

const CreditCardLogos = ({ brand = '', children, size, className }) => {
  const card = getCard(brand.toLowerCase());

  return (
    <div className={classnames('flex flex-wrap justify-center', className)}>
      {children}
      {card ? (
        <img
          className={classnames('mx-0.5', imgSizes[size])}
          key={card.name}
          src={card.img}
          alt={card.name}
        />
      ) : (
        CARDS.map((item) => {
          return (
            <img
              className={classnames('mx-0.5', imgSizes[size])}
              key={item.name}
              src={item.img}
              alt={item.name}
            />
          );
        })
      )}
    </div>
  );
};

CreditCardLogos.propTypes = {
  brand: PropTypes.string,
  children: PropTypes.any,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default CreditCardLogos;
export { getCard };
