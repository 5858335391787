import { getUrlParam } from 'Core/utils/utils';

const configs = [
  {
    azureRedirectUrl: process.env.AZURE_REDIRECT_URL_DE,
    country: 'de',
    currency: 'eur',
    dateFormat: 'dd.MM.yyyy',
    hostnames: ['zahlungen.shared-testing.audibene.de', 'zahlungen.audibene.de'],
    locale: 'de-DE',
    stripeLocale: 'de',
    stripePublicKey: process.env.STRIPE_PUBLIC_KEY_DE,
  },
  {
    azureRedirectUrl: '',
    country: 'us',
    currency: 'usd',
    dateFormat: 'MM/dd/yyyy',
    hostnames: ['payments.shared-testing.hear.com', 'payments.hear.com'],
    locale: 'en-US',
    stripeLocale: 'en',
    stripePublicKey: process.env.STRIPE_PUBLIC_KEY_US,
  },
  {
    azureRedirectUrl: process.env.AZURE_REDIRECT_URL_CA,
    country: 'ca',
    currency: 'cad',
    dateFormat: 'MM/dd/yyyy',
    hostnames: ['payments-ca.shared-testing.hear.com', 'payments-ca.hear.com'],
    locale: 'en-CA',
    stripeLocale: 'en',
    stripePublicKey: process.env.STRIPE_PUBLIC_KEY_CA,
  },
  {
    azureRedirectUrl: process.env.AZURE_REDIRECT_URL_NL,
    country: 'nl',
    currency: 'eur',
    dateFormat: 'dd-MM-yyyy',
    hostnames: ['betalingen.shared-testing.audibene.nl', 'betalingen.audibene.nl'],
    locale: 'nl-NL',
    stripeLocale: 'nl',
    stripePublicKey: process.env.STRIPE_PUBLIC_KEY_NL,
  },
  {
    azureRedirectUrl: process.env.AZURE_REDIRECT_URL_FR,
    country: 'fr',
    currency: 'eur',
    dateFormat: 'dd-MM-yyyy',
    hostnames: ['facturation.shared-testing.audibene.fr', 'facturation.audibene.fr'],
    locale: 'fr-FR',
    stripeLocale: 'fr',
    stripePublicKey: process.env.STRIPE_PUBLIC_KEY_FR,
  },
];

const normalizeLocale = (str) => str.replace('_', '-');

const getLocaleFromHostname = () => {
  const { locale } =
    configs.find((config) => config.hostnames.includes(window.location.hostname)) || {};
  return locale;
};

export const getAppConfig = () => {
  const locale =
    getLocaleFromHostname() ||
    localStorage.getItem('locale') ||
    getUrlParam('locale', '') ||
    'en-US';
  return configs.find((config) => config.locale === normalizeLocale(locale));
};
