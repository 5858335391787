import React from 'react';

function useScrollTop() {
  const scroll = (x = 0, y = 0) => {
    window.scrollTo({
      left: x,
      top: y,
      behavior: 'smooth',
    });
  };

  React.useEffect(() => {
    scroll();
  }, []);

  return {
    top: scroll,
  };
}

export { useScrollTop };
