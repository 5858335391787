import React from 'react';
import { useTranslation } from 'react-i18next';
import useErrorStore, { errorSelector } from 'Core/stores/error';
import Page from 'Core/components/Page';
import { Alert, AlertError } from 'Core/components/Alert';

export function Error() {
  const { t } = useTranslation();
  const error = useErrorStore(errorSelector);

  return (
    <Page title={t('page.error.header')} withStepProgress={false}>
      <h1 className="text-2xl">{t('common.errorHeadline')}</h1>
      {error ? <AlertError>{error}</AlertError> : null}
      <Alert>
        <p>
          {t('common.contactRecovery')}
          <br />
          {t('common.feedbackRecovery')}
        </p>
      </Alert>
    </Page>
  );
}
