import React from 'react';
import classnames from 'classnames';

const styles = `
  bg-gray-500 fixed z-50 inset-0 bg-opacity-50 flex items-center justify-center
  [backdrop-filter:blur(2px)] inset-0
`;

export default function Overlay({ className, children }) {
  return <div className={classnames(styles, className)}>{children}</div>;
}
