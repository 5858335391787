import axios from 'axios';
import { getUrlParam } from 'Core/utils/utils';

const axiosInstance = axios.create({
  baseURL: process.env.API_PLATFORM_URL,
  headers: {
    web_code: getUrlParam('web-code'),
    payment_id: getUrlParam('payment-id'),
  },
});

export function get(url, config, validatorFunction) {
  return axiosInstance.get(url, config).then(({ data }) => {
    if (!validatorFunction || validatorFunction(data)) {
      return data;
    }
    throw new Error('empty data');
  });
}

export function post(url, payload, config) {
  return axiosInstance.post(url, payload, config).then(({ data }) => data);
}
export function postWithHeaders(url, payload, config) {
  return axiosInstance.post(url, payload, config).then((resp) => resp);
}

export function put(url, payload, config) {
  return axiosInstance.put(url, payload, config).then(({ data }) => data);
}

export function putWithHeaders(url, payload, config) {
  return axiosInstance.put(url, payload, config).then((resp) => resp);
}
//
// export function del(url, payload, config) {
//   return axiosInstance.delete(url, payload, config).then(({ data }) => data);
// }
