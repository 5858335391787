import React from 'react';
import { useTranslation } from 'react-i18next';
import { Column, Row } from 'Core/components/Layout';
import InputField from 'Core/components/FormElements/InputField';
import { formatCurrency } from 'Core/utils/numberFormat';

const AmountSplitter = ({ decimals, form, cardCount, index }) => {
  const { t } = useTranslation();
  const {
    register,
    watch,
    formState: { errors },
  } = form;
  const amount = watch(`cards[${index}].amount`);
  const paymentMethodId = watch(`cards[${index}].paymentMethodId`);

  if (cardCount > 1) {
    const decimalsInteger = decimals * 100;
    const hasDecimal = decimalsInteger * 100 > 0;
    let splitAmountDecimal = '.00';

    if (hasDecimal && index === 0) {
      splitAmountDecimal = decimalsInteger < 10 ? `.0${decimalsInteger}` : `.${decimalsInteger}`;
    }

    return (
      <>
        {!paymentMethodId && <hr></hr>}
        <Row
          className={`flex pr-4 items-center justify-between ${
            paymentMethodId ? 'flex-col !items-end' : ''
          }`}
        >
          <Column className="!flex-col-12 md:!flex-col-6 mb-0 !pr-0">
            {t('common.amountToPayWithCard')}
          </Column>
          <Column
            className={`!flex-col-12 ${
              paymentMethodId ? 'md:!flex-col-5 pr-4' : 'md:!flex-col-3 pr-4'
            } mb-0 pr-0 flex justify-end`}
          >
            <InputField
              block
              className="items-center max-w-[270px]"
              data-testid={`splitAmount-card${index}`}
              register={register}
              name={`cards[${index}].amount`}
              type="number"
              value={amount}
              maxLength={6}
              appendText={splitAmountDecimal}
              prependText={'$'}
              error={errors?.cards?.[index]?.amount?.message}
            />
          </Column>
        </Row>
      </>
    );
  }

  return (
    <div className="flex flex-wrap -mx-4 px-4 items-center mb-0 mt-2 justify-between">
      <span>{!paymentMethodId && t('common.amountToPayWithCard')}</span>
      <b className="ml-4" data-testid="split-amount" style={{ fontSize: '1.25rem' }}>
        {formatCurrency(+amount + decimals)}
      </b>
    </div>
  );
};

export default AmountSplitter;
