import { Trans, useTranslation } from 'react-i18next';
import { getPreferredPaymentMethod } from 'Core/utils/utils';
import StyledFormGroup from 'Core/components/FormElements/StyledFormGroup';
import CardSelector from 'Core/components/CardSelector';
import TermsInput from 'Core/components/FormElements/TermsInput';
import Row from 'Core/components/Layout/Row';
import { Column } from 'Core/components/Layout';
import { PrimaryButton } from 'Core/components/Button';
import React from 'react';
import { Alert } from 'Core/components/Alert';
import { formatCurrency } from 'Core/utils/numberFormat';
import Option from 'Core/components/Option/Option';
import useDirectSalesForm from 'Modules/directSales/hooks/useDirectSalesForm';

const Options = ({ options, form }) => {
  const { t } = useTranslation();
  const { register, getValues, setValue } = form;
  const { type } = form.getValues();

  // Manually set value in case there is only 1 option
  React.useEffect(() => {
    if (options.length === 1) {
      const [{ amount }] = options;
      setValue('amount', amount);
    }

    //Need to set manually for initial render to register the field
    setValue('type', type);
  }, [type, options, setValue]);

  if (options.length === 1) {
    const [{ type, amount }] = options;
    return (
      <Alert className={`text-base font-bold`}>
        <Trans
          i18nKey={`deposit.${type}`}
          values={{ amount: formatCurrency(amount, { withDecimals: false }) }}
        />
      </Alert>
    );
  }

  return (
    <div className="mt-8">
      <h2>{t('common:depositAmount')}</h2>
      <Row data-testid="deposit-card-option-container">
        {options.map(({ option, amount, type }) => (
          <Column className="md:flex-col-4" key={option}>
            <Option
              className="deposit-option"
              register={register}
              name="amount"
              value={amount}
              defaultChecked={getValues().amount === amount}
              onChange={() => setValue('type', type)}
            >
              <div>
                <span className="text-4xl font-bold">
                  {formatCurrency(amount, { withDecimals: false })}
                </span>
                <span className="text-xl font-bold">{t(`common.${type}`)}</span>
              </div>
            </Option>
          </Column>
        ))}
      </Row>
    </div>
  );
};

export function DirectSalesForm({ paymentMethods, paymentOptions, onSubmit, cardErrors }) {
  const { t } = useTranslation();
  const { paymentMethodId } = getPreferredPaymentMethod(paymentMethods);
  const [{ type, amount }] = paymentOptions;

  const defaultValues = React.useMemo(
    () =>
      paymentMethodId
        ? { paymentMethodId, type, amount, terms: false }
        : {
            firstName: '',
            lastName: '',
            postalCode: '',
            cardNumber: '',
            cardExpiry: '',
            cardCvc: '',
            type,
            amount,
            terms: false,
          },
    [amount, paymentMethodId, type]
  );
  const form = useDirectSalesForm(defaultValues);
  const {
    watch,
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = form;

  React.useEffect(() => {
    if (isSubmitSuccessful) {
      reset(defaultValues);
    }
  }, [defaultValues, isSubmitSuccessful, reset]);

  const cardError = cardErrors?.[0].message;

  const termsTextId = watch('type') === 'authorize' ? 'terms.card.hold' : 'terms.card.charge';

  return (
    <form name="directSales" onSubmit={handleSubmit(onSubmit)}>
      <StyledFormGroup error={cardError}>
        <CardSelector cardError={cardError} form={form} paymentMethods={paymentMethods} />
      </StyledFormGroup>

      <Options options={paymentOptions} form={form} />

      <TermsInput
        name="terms"
        register={register}
        errors={errors}
        label={t('common.termsAndConditions')}
      >
        <p>{t(termsTextId)}</p>
      </TermsInput>

      <Row className="justify-end">
        <Column className="flex-col-6 md:flex-col-4 lg:flex-col-3">
          <PrimaryButton block={true} type="submit" data-testid="checkout">
            {t('common.checkout')}
          </PrimaryButton>
        </Column>
      </Row>
    </form>
  );
}
