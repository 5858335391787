import { useTranslation } from 'react-i18next';
import useErrorStore, { setErrorActionSelector } from 'Core/stores/error';
import { useQuery } from 'react-query';
import cacheKeys from 'Core/hooks/queries/cacheKeys';
import { get } from 'Core/utils/platformApi';
import { useGetPaymentInfo } from 'Core/hooks/platform/useGetPaymentInfo';

export function useGetSavedCards() {
  const { t } = useTranslation();
  const setError = useErrorStore(setErrorActionSelector);
  const { data: paymentInfo } = useGetPaymentInfo();

  return useQuery(
    cacheKeys.savedCards,
    () =>
      get('/cards', {
        headers: { external_reference_id: paymentInfo.external_reference_id },
      }),
    {
      onError(e) {
        if (e) {
          setError(t('status.error.getSavedCardsFailed') + ` - (${e.message})`);
        }
      },
      enabled: Boolean(paymentInfo),
      select(data) {
        return data.map((method) => ({
          card: {
            brand: method.brand.toLowerCase(),
            last4: method.last4digit,
          },
          paymentMethodId: method.payment_method_id,
          preferred: method.preferred,
          type: 'card',
        }));
      },
    }
  );
}
