import React from 'react';
import { useTranslation } from 'react-i18next';
import { useScrollTop } from 'Core/hooks/useScrollTop';
import { Alert, AlertSuccess } from 'Core/components/Alert';
import Checklist from 'Modules/creditDebit/components/Checklist';
import OrderReceipts from 'Core/components/Receipts/OrderReceipts';
import Page from 'Core/components/Page';
import OrderData from 'Core/components/Data/OrderData';

const FinancingSuccess = () => {
  useScrollTop();
  const { t } = useTranslation();

  const checklistItems = [
    { label: t('page.financing.checklist.approved'), status: 'done' },
    { label: t('page.financing.checklist.approvedSigned'), status: 'done' },
    { label: t('page.financing.checklist.funded'), status: 'done' },
  ];

  return (
    <OrderData>
      <Page title={t('page.financing.header')}>
        <AlertSuccess>{t('page.financing.financingFunded.infobox')}</AlertSuccess>

        <Alert>
          <p>{t('page.financing.financingFunded.intro')}</p>

          <div className={`text-center`}>
            <Checklist items={checklistItems} />
          </div>

          <OrderReceipts title={t('page.creditDebit.success.clickToViewReceipt')} />

          <hr />
        </Alert>
      </Page>
    </OrderData>
  );
};

export default FinancingSuccess;
