import React from 'react';
import { useTranslation } from 'react-i18next';

export default function FooterBanner() {
  const { t } = useTranslation();

  return (
    <div className="border-t border-gray-300 text-center mx-4 py-8">
      <p className="font-bold">{t('common.footer.questions')}</p>
      <div className="text-xl">{t('common.footer.phone-number')}</div>
      {t('common.footer.availablity-times')}
    </div>
  );
}
