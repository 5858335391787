import React from 'react';
import { Controller } from 'react-hook-form';
import { add, formatISO, parse } from 'date-fns';
import RadioOption from 'Core/components/Option/RadioOption';
import InputField from 'Core/components/FormElements/InputField';
import Page from 'Core/components/Page';
import { PrimaryButton, SecondaryOutlineButton } from 'Core/components/Button';
import { useTranslation } from 'react-i18next';
import { StyledFormSection } from 'Core/components/FormElements/formElements.styled';
import { Column, Row } from 'Core/components/Layout';
import useSelectOfferForm from 'Modules/creditDebit/components/Financing/allegro/useSelectOfferForm';
import usePutPaymentOption from 'Core/hooks/queries/payment/usePutPaymentOption';
import useGetPaymentSummary from 'Core/hooks/queries/payment/useGetPaymentSummary';
import { useScrollTop } from 'Core/hooks/useScrollTop';
import useUpdateOffer from 'Modules/creditDebit/components/Financing/allegro/useUpdateOffer';
import Loader from 'Core/components/Loader';
import CalendarInputField from 'Core/components/FormElements/CalendarInputField';
import useAlertStore, { clearAlertSelector, setErrorAlertSelector } from 'Core/stores/alert';
import { AlertWarning } from 'Core/components/Alert';
import { formatCurrency } from 'Core/utils/numberFormat';
import { getPropValue } from 'Core/utils/utils';
import { getAppConfig } from 'Core/config/appConfig';
import constants from 'Core/config/constants';
import { queryFn as getPaymentOptions } from 'Core/hooks/queries/payment/useGetPaymentOptions';
import useOrder from 'Core/hooks/queries/order/useOrder';
import { useNavigate } from 'react-location';

const SelectOffer = ({ application, routes }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const scroll = useScrollTop();
  const setErrorAlert = useAlertStore(setErrorAlertSelector);
  const clearAlert = useAlertStore(clearAlertSelector);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedOffer, setSelectedOffer] = React.useState({
    id: '',
    minimumFinancedAmount: 0,
    type: '',
  });
  const [offer, setOffer] = React.useState(null);
  const { offers, minimumDownPayment = 0 } = application;
  const { data: { totalAmount = 0 } = {} } = useGetPaymentSummary();
  let maximumDownPayment = totalAmount - selectedOffer.minimumFinancedAmount;
  maximumDownPayment =
    maximumDownPayment < minimumDownPayment ? minimumDownPayment : maximumDownPayment;

  const { dateFormat } = getAppConfig();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
  } = useSelectOfferForm({
    minimumDownPayment,
    maximumDownPayment,
    dateFormat,
  });
  const { mutateAsync: updateOffer, isLoading: isLoadingUpdateOffer } = useUpdateOffer();
  const { mutateAsync: setPaymentOption, isLoading: isSetPaymentOptionLoading } =
    usePutPaymentOption();
  const { data: order } = useOrder();
  const fundingAmount = totalAmount - (watch('downPayment') || 0);
  const downPaymentLabel =
    'Down Payment' +
    (selectedOffer.id
      ? ` (${formatCurrency(minimumDownPayment, { withDecimals: false })} - ${formatCurrency(
          maximumDownPayment,
          { withDecimals: false }
        )})`
      : '');
  const isContingentFunding = selectedOffer.type.toLowerCase() === 'contingent funding';

  const onFocus = (event) => event.target.select();

  const onSubmit = async ({ downPayment, deliveryDate }) => {
    scroll.top();

    try {
      const result = await updateOffer({
        offerId: selectedOffer.id,
        downPayment: parseInt(downPayment, 10),
        autoPay: false,
        deliveryDate: formatISO(parse(deliveryDate, dateFormat, new Date()), {
          representation: 'date',
        }),
      });

      setOffer(result);
      clearAlert();
    } catch (err) {
      setErrorAlert(err.message);
    }
  };

  const onCancel = () => {
    clearAlert();
    navigate({ to: routes.paymentOptions, replace: true, search: true });
  };

  const onContinue = async () => {
    const downPayment = getPropValue(offer, 'price.downPayment');
    setIsLoading(true);
    try {
      // hacky hack to make the next call work ¯\_(ツ)_/¯
      await getPaymentOptions(order.orderId);
      await setPaymentOption(
        downPayment > 0 ? constants.paymentOptions.MIXED : constants.paymentOptions.FINANCING
      );
      return navigate({
        to: downPayment > 0 ? routes.mixed : routes.financing,
        replace: true,
        search: true,
      });
    } catch (err) {
      setIsLoading(false);
      setErrorAlert(err);
    }
  };

  if (isLoading || isSetPaymentOptionLoading) {
    return <Loader message={t('status.loading.initializingFinancing')} />;
  }

  return (
    <Page title={t('page.selectFinancingOffer.header')} withStepProgress={false}>
      <h3>{t('allegro.setOffer.headline-1')}</h3>

      {isLoadingUpdateOffer && <Loader message={t('allegro.updateApplication.loaderText')} />}

      <form name="selectOfferForm" onSubmit={handleSubmit(onSubmit)}>
        {offers.installment.map(({ program, terms, description, type }) => {
          const [{ id, minimumFinancedAmount }] = terms;
          const checked = selectedOffer.id === id;

          return (
            <RadioOption
              key={id}
              className="mb-3"
              name="offer"
              value={id}
              checked={checked}
              onChange={(e) => {
                setSelectedOffer({
                  id: e.target.value,
                  minimumFinancedAmount,
                  type,
                });
              }}
            >
              <h3 className="mb-1">{program}</h3>
              <p className="text-sm" dangerouslySetInnerHTML={{ __html: description }} />
            </RadioOption>
          );
        })}

        <h3 className="mt-4">{t('allegro.setOffer.headline-2')}</h3>

        <StyledFormSection>
          <Row>
            <Column className="md:!flex-col-6">
              <InputField
                label={`Cash Price`}
                prependText="$"
                name="price"
                type="text"
                defaultValue={totalAmount}
                disabled
                errors={errors}
                onFocus={onFocus}
              />
            </Column>
            <Column className="md:!flex-col-6">
              <InputField
                label="Funding by Allegro"
                prependText="$"
                name="funding"
                type="text"
                value={fundingAmount % 1 > 0 ? fundingAmount.toFixed(2) : fundingAmount}
                disabled
                errors={{}}
              />
            </Column>
          </Row>

          {isContingentFunding ? (
            <AlertWarning className="!my-2">
              {t('allegro.contigentFunding.downPaymentNote')}
            </AlertWarning>
          ) : null}

          <Row>
            <Column className="md:!flex-col-6">
              <InputField
                register={register}
                label={downPaymentLabel}
                prependText="$"
                name="downPayment"
                type="number"
                disabled={!selectedOffer.id}
                errors={errors}
                onFocus={onFocus}
              />
            </Column>

            <Column className="md:!flex-col-3">
              <Controller
                name="deliveryDate"
                control={control}
                rules={{ required: t('common.requiredFieldDeliveryDate') }}
                render={({ field: { onChange, onBlur, name, value } }) => (
                  <CalendarInputField
                    label={t('common.deliveryDate')}
                    name={name}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    dayPickerProps={{
                      disabledDays: {
                        before: new Date(),
                        after: add(new Date(), { days: 60 }),
                      },
                    }}
                    errors={errors}
                  />
                )}
              />
            </Column>
            <Column className="md:!flex-col-3 !flex !items-end">
              <PrimaryButton type="submit" data-testid="update-offer" disabled={!selectedOffer.id}>
                {t('common.updateOffer')}
              </PrimaryButton>
            </Column>
          </Row>
        </StyledFormSection>

        <h3 className="!mt-4">{t('allegro.setOffer.headline-3')}</h3>

        <StyledFormSection>
          <Row>
            <Column className="md:!flex-col-6">
              <InputField
                label="Monthly Payment"
                prependText="$"
                name="monthlyPayment"
                type="text"
                value={getPropValue(offer, 'price.monthlyPayment', '')}
                disabled
                errors={{}}
              />
            </Column>
          </Row>
        </StyledFormSection>

        <Row>
          <Column className="md:!flex-col-6">
            <SecondaryOutlineButton data-testid="cancel" onClick={onCancel}>
              {t('common.cancel')}
            </SecondaryOutlineButton>
          </Column>

          <Column className="md:!flex-col-6 !flex !gap-1 !justify-end">
            <PrimaryButton data-testid="set-offer" disabled={!offer} onClick={onContinue}>
              {t('common.continue')}
            </PrimaryButton>
          </Column>
        </Row>
      </form>
    </Page>
  );
};

export default SelectOffer;
