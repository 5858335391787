import React from 'react';
import { useTranslation } from 'react-i18next';

export default function StickyFooter() {
  const { t } = useTranslation();

  return (
    <div className="border-t border-gray-300 text-center py-2 fixed bottom-0 w-full bg-white flex justify-center gap-12 items-center left-0 opacity-95 shadow-lg">
      <div className="font-semibold">{t('common.footer.questions')}</div>
      <div className="font-bold text-lg">{t('common.footer.phone-number')}</div>
      <div className="font-semibold">{t('common.footer.availablity-times')}</div>
    </div>
  );
}
