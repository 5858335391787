import React from 'react';
import PropTypes from 'prop-types';

const CheckListItem = ({ label, children, ...rest }) => {
  return (
    <div className="items-center flex flex-nowrap py-2 whitespace-pre-line" {...rest}>
      {children}
      <div className="ml-4">{label}</div>
    </div>
  );
};

CheckListItem.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.any,
};

export default CheckListItem;
