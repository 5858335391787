import React from 'react';
import { Container } from 'Core/components/Layout';

const Main = ({ className = '', children }) => (
  <Container className={className + ' [min-height: 400px]'} role="main">
    {children}
  </Container>
);

export default Main;
