import create from 'zustand';

export default create((set) => ({
  type: '',
  title: '',
  description: '',
  open: false,
  confirmButtonLabel: 'OK',
  cancelButtonLabel: 'Cancel',
  confirmCallback: () => {},
  cancelCallback: () => {},
  onConfirm: () => set({ open: false }),
  onCancel: () => set({ open: false }),
  onClose: () => set({ open: false, title: '', description: '' }),
  showDialog: ({
    type = 'confirm',
    title,
    description,
    confirmCallback,
    cancelCallback,
    confirmButtonLabel = 'OK',
    cancelButtonLabel = 'Cancel',
  }) =>
    set({
      type,
      title,
      description,
      confirmButtonLabel,
      cancelButtonLabel,
      open: true,
      confirmCallback,
      cancelCallback,
    }),
}));

export const showDialogSelector = (state) => state.showDialog;
