import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-location';
import useErrorStore, { setErrorActionSelector } from 'Core/stores/error';
import { useQuery } from 'react-query';
import cacheKeys from 'Core/hooks/queries/cacheKeys';
import { get } from 'Core/utils/api';
import { errorPath } from 'Core/routes';

export function useGetPaymentHistory() {
  const { t } = useTranslation();
  const setError = useErrorStore(setErrorActionSelector);
  const navigate = useNavigate();

  return useQuery(cacheKeys.paymentHistory, () => get('/api/payments/receipts'), {
    onError(e) {
      if (e) {
        setError(t('status.error.getPaymentHistoryFailed') + e.message);
        navigate({ to: errorPath, search: true });
      }
    },
  });
}
