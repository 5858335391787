import React from 'react';
import { useTranslation } from 'react-i18next';
const CartHeader = () => {
  const { t } = useTranslation();

  return (
    <div className={'flex flex-nowrap text-right text-sm py-0.5 px-1 font-bold'}>
      <div className="text-left px-1 [width:45%]">{t('common.item')}</div>
      <div className="px-1 [width:25%]">{t('common.quantity')}</div>
      <div className="px-1 [width:30%]">{t('common.total')}</div>
    </div>
  );
};

export default CartHeader;
