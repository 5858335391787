import React from 'react';

export const StyledCard = ({ children }) => (
  <div className="flex flex-col border border-solid rounded border-gray-300 break-words bg-white bg-clip-border w-full">
    {children}
  </div>
);

export const StyledCardHeader = ({ children }) => (
  <div className="font-bold p-2 bg-gray-100 border-0 border-b rounded-t border-solid border-gray-300">
    {children}
  </div>
);

export const StyledCardBody = ({ children }) => (
  <div className="flex flex-col flex-1 p-2">{children}</div>
);

export const StyledCardFooter = ({ children }) => <div className="p-2">{children}</div>;
