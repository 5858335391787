import React from 'react';
import PropTypes from 'prop-types';
import Error from './Error';
import { StyledFormElement } from 'Core/components/FormElements/formElements.styled';

const TextArea = (props) => {
  const {
    className,
    disabled = false,
    error = '',
    name = '',
    id = name,
    label,
    maxLength = 64,
    rows = 3,
    valid = true,
    value,
    register,
  } = props;

  return (
    <StyledFormElement className={className}>
      <label htmlFor={id}>{label}</label>
      <textarea
        id={id}
        name={name}
        value={value}
        maxLength={maxLength}
        rows={rows}
        disabled={disabled}
        style={{ resize: 'none' }}
        {...register(name)}
      />
      <Error message={error} show={!valid} />
    </StyledFormElement>
  );
};

TextArea.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  rows: PropTypes.number,
  valid: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default TextArea;
